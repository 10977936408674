import styled from '@emotion/styled';
import * as T from '../../../../../components/Typography';

export const Container = styled.div`
  padding: 24px;
  padding-top: 0;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  @media (max-width: 1090px) {
    grid-template-columns: 40px 1fr 40px;
  }
`;

export const Column1 = styled.div`
  grid-area: 1 / 1 / 1 / 2;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacings[2]};
  border-right: 1px solid ${({ theme }) => theme.colors.neutral40};
  @media (max-width: 1090px) {
    grid-area: 1 / 1 / 3 / 2;
  }
`;

export const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: 1 / 2 / 1 / 3;
  @media (max-width: 1090px) {
    grid-area: 1 / 2 / 1 / 4;
  }
`;

export const Column3 = styled.div`
  grid-area: 1 / 3 / 1 / 4;

  @media (max-width: 1090px) {
    grid-area: 2 / 2 / 2 / 4;
  }
`;

export const RotateP = styled(T.P)`
  writing-mode: vertical-rl; /* vertical text */
  transform: rotate(180deg);
`;

export const Cell = styled.button`
  border: none;
  background: none;
  width: 100%;
  cursor: ${({ cursor }) => cursor || 'auto'};
  height: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral40};
  display: flex;
  justify-content: space-between;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.neutral40 : 'transparent'};
  align-items: center;
  padding-left: ${({ theme }) => theme.spacings[2]};
  :disabled {
    cursor: auto;
  }
`;

export const SignWrapper = styled.div`
  width: 40px;
  border-left: 1px solid ${({ theme }) => theme.colors.neutral40};
  align-self: flex-end;
`;

export const RightSec = styled.div`
  min-width: 40px;
  max-width: 40px;
  border-left: 1px solid ${({ theme }) => theme.colors.neutral40};
  align-self: flex-end;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DetailsWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral40};
`;

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  ${({ theme }) => theme.media.tablet} {
    grid-template-columns: 1fr 40px 1fr;
  }
`;

export const DetailsCol1 = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral40};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral40};
  padding: 8px;
  grid-area: 1 / 1 /1 / 2;
  ${({ theme }) => theme.media.tablet} {
    grid-area: 1 / 1 / 2 / 4;
  }
`;
export const DetailsCol2 = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral40};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral40};
  ${({ theme }) => theme.media.tablet} {
    border-top: none;
  }

  grid-area: 1 / 2 / 1 / 4;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.media.tablet} {
    grid-area: 2 / 1 / 3 / 4;
  }
`;

export const DetailsCell = styled.div`
  width: 50%;
  border-left: 1px solid ${({ theme }) => theme.colors.neutral40};
  display: flex;
  flex-direction: column;
  align-items: stretsh;
  * {
    flex: 1;
  }
  ${({ theme }) => theme.media.tablet} {
    border: none;
  }
  padding: 8px;
  height: 100%;
`;

export const HeadingWrapper = styled.div`
  padding: 8px;
`;
