import styled from '@emotion/styled';
import { Row } from '../../../components/Grid';
import { setMargin } from '../../../helpers';

export const Wrapper = styled.div`
  max-width: 600px;
`;

export const NextButtonWrapper = styled(Row)`
  flex: 1;
  max-width: 600px;
`;

export const PlayButtonWrapper = styled.div`
  ${setMargin}
  display: flex;
  align-items: center;
`;
