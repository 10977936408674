import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

export const ComprehensionWrapper = styled.div`
  padding-bottom: ${({ theme, pb }) =>
    pb ? theme.spacings[pb] : theme.spacings[6]};
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.neutralLight};
  width: 100%;
`;
