import styled from '@emotion/styled';
import { Row } from '../../../components/Grid';

export const Circle = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ theme }) => `10px solid ${theme.colors.tertiaryMain}`};
  background-color: ${({ theme }) => theme.colors.neutralMain};
`;

export const Wrapper = styled.div`
  max-width: 600px;
`;

export const NextButtonWrapper = styled(Row)`
  flex: 1;
  max-width: 600px;
  align-content: flex-end;
`;
