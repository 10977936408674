import * as S from './style';
import * as T from '../../../../../components/Typography';
const Letter2 = () => {
  return (
    <S.Wrapper>
      <T.P fontFamily="garamond" mb="4">
        Please bring this letter with you and report to the reception desk in
        the
        <T.P weight="bold" ml="1" style={{ display: 'inline' }}>
          Main Outpatients Department.
        </T.P>{' '}
        Please bring with you a list of all your current medication.
      </T.P>

      <T.P mb="4" fontFamily="garamond">
        If you are unable to attend at this time or if you would like to change
        your appointment, please telephone the appointments line on: 0500 050505
        as soon as possible. You can also contact us through the CHOOSE AND BOOK
        service online{' '}
        <S.Link
          href="https://www.chooseandbook.nhs.uk"
          target="_blank"
          rel="noreferrer"
        >
          www.chooseandbook.nhs.uk
        </S.Link>
      </T.P>
      <T.P mb="4" fontFamily="garamond">
        Failure to attend this appointment without prior notification may result
        in your being removed from our waiting list.
      </T.P>
      <T.P fontFamily="garamond">Yours sincerely</T.P>
      <T.P mb="4" fontFamily="garamond">
        Booking Clerk
      </T.P>
    </S.Wrapper>
  );
};

export default Letter2;
