import * as S from './style.js';
import * as T from '../../../../../components/Typography';

import data from './meals-data';
import { exerciseTypes } from '../../../../../constants';

const MealsGrid = ({ userAnswers, handleSelect, type }) => {
  const handleClick = (item) => {
    handleSelect(item.id);
  };
  return (
    <>
      {data.map((day) => {
        return (
          <S.Grid key={day.title}>
            <S.Column1>
              <S.RotateP weight="bold" color="neutralMain">
                {day.title}
              </S.RotateP>
            </S.Column1>

            <S.Column2>
              <S.Cell disabled>
                <T.P weight="bold" color="neutralMain">
                  {day.meals[0].title}
                </T.P>
                <S.RightSec>
                  <T.P weight="bold" color="neutralMain">
                    ✔️
                  </T.P>
                </S.RightSec>
              </S.Cell>
              {day.meals[0].recipes.map((recipy) => (
                <S.Cell
                  key={recipy.id}
                  onClick={() => handleClick(recipy)}
                  isSelected={userAnswers.find((ans) => ans === recipy.id)}
                  cursor={
                    type === exerciseTypes.SELECT_RECIPES ? 'pointer' : 'auto'
                  }
                >
                  <T.P size="small">{recipy.title}</T.P>
                  <S.RightSec />
                </S.Cell>
              ))}
            </S.Column2>

            <S.Column3>
              <S.Cell disabled>
                <T.P weight="bold" color="neutralMain">
                  {day.meals[1].title}
                </T.P>
                <S.RightSec>
                  <T.P weight="bold" color="neutralMain">
                    ✔️
                  </T.P>
                </S.RightSec>
              </S.Cell>
              {day.meals[1].recipes.map((recipy) => (
                <S.Cell
                  key={recipy.id}
                  onClick={() => handleClick(recipy)}
                  isSelected={userAnswers.find((ans) => ans === recipy.id)}
                  cursor={
                    type === exerciseTypes.SELECT_RECIPES ? 'pointer' : 'auto'
                  }
                >
                  <T.P size="small">{recipy.title}</T.P>
                  <S.RightSec />
                </S.Cell>
              ))}
            </S.Column3>
          </S.Grid>
        );
      })}
    </>
  );
};

export default MealsGrid;
