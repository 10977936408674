import theme from '../../../theme';

const Profile = ({ width, height, color, ...props }) => (
  <svg
    width={width || '30'}
    height={height || '40'}
    viewBox="0 0 30 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.35949 9.39977C6.35949 14.1715 10.2278 18.0398 14.9995 18.0398C19.7712 18.0398 23.6395 14.1715 23.6395 9.39977C23.6395 4.62803 19.7712 0.759766 14.9995 0.759766C10.2278 0.759766 6.35949 4.62803 6.35949 9.39977ZM29.3996 34.3599C29.3996 26.407 22.9525 19.9599 14.9996 19.9599C7.04671 19.9599 0.599609 26.407 0.599609 34.3599V38.1999C0.599609 38.7301 1.02942 39.1599 1.55961 39.1599H28.4396C28.9698 39.1599 29.3996 38.7301 29.3996 38.1999V34.3599Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Profile;
