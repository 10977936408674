import styled from '@emotion/styled';

export const Wrapper = styled.div`
  .upload-image-style .ant-upload-list-picture-card-container,
  .upload-image-style .ant-upload.ant-upload-select-picture-card {
    display: block;
    width: 350px;
    height: 350px;
    margin: 25px auto;
  }

  .upload-image-style .ant-upload-span .ant-upload-list-item-thumbnail {
    opacity: 1;
  }

  /* .upload-audio-style .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
  margin: 25px 0 auto;
} */

  .upload-audio-style,
  .upload-audio-style .ant-upload.ant-upload-select.ant-upload-select-picture {
    display: block;
    width: 100%;
  }

  .upload-audio-style .ant-upload.ant-upload-select .ant-upload {
    display: block;
    width: 100%;
    min-width: 200px;
    margin: 0px auto;
    height: 66px;
    border: 1px solid #d2d4d9;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    line-height: 66px;
  }
`;
