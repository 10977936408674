import { useReducer } from 'react';
import { string } from 'yup';

import { BasicInput } from '../../../../components/Inputs';
import { MediaKeyInput } from '../../../Components';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data || {
    questionText: '',
    questionAudioKey: '',
    textAudio: '',
    textAudioKey: '',
  },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const ListenAndRepeatComp = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const form = { ...prevState.form, ...data };
      onChange(form);
      return { form };
    });

  return (
    <div>
      <BasicInput
        value={state.form.textAudio}
        handleChange={(textAudio) => setFormData({ textAudio })}
        label="Top Question text"
        error={errors.textAudio}
        m={{ mt: 8 }}
      />

      <MediaKeyInput
        label="Top Question text Audio"
        value={state.form.textAudioKey}
        handleChange={(textAudioKey) => setFormData({ textAudioKey })}
        m={{ mt: 5 }}
        error={errors.textAudioKey}
      />

      <BasicInput
        value={state.form.questionText}
        handleChange={(questionText) => setFormData({ questionText })}
        label="Question text"
        m={{ mt: 8 }}
        error={errors.questionText}
      />

      <MediaKeyInput
        label="Question text Audio"
        value={state.form.questionAudioKey}
        handleChange={(questionAudioKey) => setFormData({ questionAudioKey })}
        m={{ mt: 5 }}
        error={errors.questionAudioKey}
      />
    </div>
  );
};

const validationSchema = {
  questionText: string().optional(),
  questionAudioKey: string().required('Question text audio is required'),
  textAudio: string().optional(),
  textAudioKey: string().optional(),
};

export { validationSchema };
export default ListenAndRepeatComp;
