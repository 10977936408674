import { Route, Routes } from 'react-router-dom';
import { Route as CustomRoute } from './../components';

import { navRoutes, userRoles } from '../constants';
import Home from './Home';
import { ViewMilestones } from './Milestones';
import { ViewExercises, ViewExercise } from './Exercises';
import { ViewStepsByExerciseId, CreateStep } from './Steps';
import Media from './Media';
import MediaUpdate from './MediaUpdate';
const { CMS } = navRoutes;
const { BASE } = CMS;

function ExercisesRouter() {
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <CustomRoute
            Component={Home}
            layout="CMS"
            isPrivate
            allowedRoles={[userRoles.CONTENT_EDITOR]}
          />
        }
      />

      <Route
        path={CMS.VIEW_MILESTONES.replace(BASE, '')}
        element={
          <CustomRoute
            Component={ViewMilestones}
            layout="CMS"
            isPrivate
            allowedRoles={[userRoles.CONTENT_EDITOR]}
          />
        }
      />

      <Route
        path={CMS.VIEW_EXERCISES.replace(BASE, '')}
        element={
          <CustomRoute
            Component={ViewExercises}
            layout="CMS"
            isPrivate
            allowedRoles={[userRoles.CONTENT_EDITOR]}
          />
        }
      />

      <Route
        path={CMS.CREATE_EXERCISES.replace(BASE, '')}
        element={
          <CustomRoute
            Component={ViewExercise}
            layout="CMS"
            createNew
            isPrivate
            allowedRoles={[userRoles.CONTENT_EDITOR]}
          />
        }
      />

      <Route
        path={CMS.VIEW_EXERCISE.replace(BASE, '')}
        element={
          <CustomRoute
            Component={ViewExercise}
            layout="CMS"
            isPrivate
            allowedRoles={[userRoles.CONTENT_EDITOR]}
          />
        }
      />

      <Route
        path={CMS.VIEW_EXERCISE_STEPS.replace(BASE, '')}
        element={
          <CustomRoute
            Component={ViewStepsByExerciseId}
            layout="CMS"
            isPrivate
            allowedRoles={[userRoles.CONTENT_EDITOR]}
          />
        }
      />

      <Route
        path={CMS.SINGLE_STEP.replace(BASE, '')}
        element={
          <CustomRoute
            Component={CreateStep}
            layout="CMS"
            isPrivate
            allowedRoles={[userRoles.CONTENT_EDITOR]}
          />
        }
      />

      <Route
        path={CMS.VIEW_STEP.replace(BASE, '')}
        element={
          <CustomRoute
            Component={CreateStep}
            layout="CMS"
            isPrivate
            allowedRoles={[userRoles.CONTENT_EDITOR]}
            createNew
          />
        }
      />

      <Route
        path={CMS.MEDIA.replace(BASE, '')}
        element={
          <CustomRoute
            Component={Media}
            layout="CMS"
            isPrivate
            allowedRoles={[userRoles.CONTENT_EDITOR]}
          />
        }
      />

      <Route
        path={CMS.MEDIA_UPDATE.replace(BASE, '')}
        element={
          <CustomRoute
            Component={MediaUpdate}
            layout="CMS"
            isPrivate
            allowedRoles={[userRoles.CONTENT_EDITOR]}
          />
        }
      />
    </Routes>
  );
}

export default ExercisesRouter;
