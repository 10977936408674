export default {
  HOME: 'Home',
  EDIT_CONTENT: 'Edit content',
  EDIT_DETAILS: 'Edit Details',
  ORGANISATIONS: 'Organisations',
  CUSTOMISE: 'Customise',
  CHANGES: 'Changes',
  LOG_OUT: 'Log out',
  LOG_IN: 'Log in',
};
