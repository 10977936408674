import theme from '../../../theme';

const Goals = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '25'}
    height={height || '25'}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <g id="iconspace_Goal_25px">
      <path
        id="path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.727 7.33365L19.8107 6.25H21.5C21.9142 6.25 22.25 5.91421 22.25 5.5C22.25 5.08579 21.9142 4.75 21.5 4.75H20.25V3.5C20.25 3.08579 19.9142 2.75 19.5 2.75C19.0858 2.75 18.75 3.08579 18.75 3.5V5.18934L17.6663 6.27299C16.0069 4.85573 13.8534 4 11.5 4C6.25329 4 2 8.25329 2 13.5C2 18.7467 6.25329 23 11.5 23C16.7467 23 21 18.7467 21 13.5C21 11.1466 20.1443 8.99312 18.727 7.33365ZM15.5353 8.40399C14.4268 7.52496 13.0247 7 11.5 7C7.91015 7 5 9.91015 5 13.5C5 17.0899 7.91015 20 11.5 20C15.0899 20 18 17.0899 18 13.5C18 11.9753 17.475 10.5732 16.596 9.46465L15.8833 10.1774C16.5841 11.1004 17 12.2516 17 13.5C17 16.5376 14.5376 19 11.5 19C8.46243 19 6 16.5376 6 13.5C6 10.4624 8.46243 8 11.5 8C12.7484 8 13.8996 8.4159 14.8226 9.1167L15.5353 8.40399ZM13.3874 10.552C12.8428 10.2026 12.1951 10 11.5 10C9.567 10 8 11.567 8 13.5C8 15.433 9.567 17 11.5 17C13.433 17 15 15.433 15 13.5C15 12.8049 14.7974 12.1572 14.448 11.6126L13.7171 12.3436C13.8978 12.6894 14 13.0828 14 13.5C14 14.8807 12.8807 16 11.5 16C10.1193 16 9 14.8807 9 13.5C9 12.1193 10.1193 11 11.5 11C11.9172 11 12.3106 11.1022 12.6564 11.2829L13.3874 10.552Z"
        fill={color || theme.colors.neutralSurface}
      />
    </g>
  </svg>
);

export default Goals;
