import styled from '@emotion/styled';
import setMargin from '../../../helpers/set-margin';

export const Answer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: -16px;
`;

export const Field = styled.div`
  ${setMargin};
  width: ${({ w }) => w || '100%'};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  .MuiOutlinedInput-root {
    width: 100%;
    height: 60px;
    box-shadow: ${({ error, theme }) =>
      error
        ? `inset 0px 0px 0px 2px ${theme.colors.error} `
        : `inset 0px 0px 0px 2px ${theme.colors.neutralMain} `};
    border-radius: ${({ theme }) => theme.borders.radiusLarge};
  }
  & .MuiInputBase-input {
    color: green;
  }

  & .MuiInputBase-input {
    opacity: ${({ value }) =>
      value === 'Invalid date' || value === null
        ? '0.75 !important'
        : '1  !important'};
    color: ${({ theme }) => theme.colors.neutralMain + '!important'};
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    width: 100% !important;
    min-height: 54px !important;

    border: none;
    padding-top: 0px !important ;
    padding-bottom: 0px !important ;
  }

  .MuiOutlinedInput-root:hover {
    box-shadow: ${({ error, theme }) =>
      error
        ? `inset 0px 0px 0px 2px ${theme.colors.error} !important`
        : `inset 0px 0px 0px 2px ${theme.colors.primaryMain} !important`};
    border-radius: ${({ theme }) => theme.borders.radiusLarge};
  }

  .MuiOutlinedInput-root:focus-within {
    box-shadow: inset 0px 0px 0px 2px rgba(5, 23, 48, 1) !important;
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${({ theme }) => theme.colors.primaryMain + '!important'};
    outline-offset: 0.2em;
  }
`;
