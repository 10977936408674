import styled from '@emotion/styled';
import setMargin from '../../../../../helpers/set-margin';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${({ theme }) => theme.spacings[2]};
  display: ${({ display }) => display};
`;

export const Row = styled.div`
  ${setMargin};
  width: 100%;
  min-width: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: ${({ direction }) => direction || 'row'};
  padding: ${({ theme }) => `${theme.spacings[5]} ${theme.spacings[5]}`};
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.neutralLight};
`;

export const Side = styled.div`
  min-width: 100px;
  max-width: 300px;
  margin-right: ${({ theme }) => theme.spacings[4]};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.neutral40};
`;
