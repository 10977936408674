const ArrowIncrease = ({ width, height, color, ...props }) => (
  <svg
    width={width || '26'}
    height={height || '28'}
    viewBox="0 0 26 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.584 2.41532L20.4475 2.13194C19.9331 2.00368 19.62 1.48266 19.7483 0.968216C19.8765 0.453772 20.3976 0.140712 20.912 0.268977L24.6379 1.19796C25.1524 1.32622 25.4654 1.84724 25.3372 2.36169L24.4082 6.08762C24.2799 6.60207 23.7589 6.91513 23.2445 6.78686C22.73 6.65859 22.417 6.13758 22.5452 5.62313L22.9877 3.84833L1.9241 14.8479C1.45413 15.0933 0.874191 14.9113 0.628769 14.4413C0.383348 13.9713 0.565381 13.3914 1.03535 13.146L21.584 2.41532ZM14.2795 13.3602H11.7195C10.6591 13.3602 9.79951 14.2199 9.79951 15.2802V25.5202C9.79951 26.5806 10.6591 27.4402 11.7195 27.4402H14.2795C15.3399 27.4402 16.1995 26.5806 16.1995 25.5202V15.2802C16.1995 14.2199 15.3399 13.3602 14.2795 13.3602ZM5.31951 17.2002H2.75951C1.69912 17.2002 0.839512 18.0599 0.839512 19.1202V25.5202C0.839512 26.5806 1.69912 27.4402 2.75951 27.4402H5.31951C6.3799 27.4402 7.23951 26.5806 7.23951 25.5202V19.1202C7.23951 18.0599 6.3799 17.2002 5.31951 17.2002ZM23.2395 9.52024H20.6795C19.6191 9.52024 18.7595 10.3799 18.7595 11.4402V25.5202C18.7595 26.5806 19.6191 27.4402 20.6795 27.4402H23.2395C24.2999 27.4402 25.1595 26.5806 25.1595 25.5202V11.4402C25.1595 10.3799 24.2999 9.52024 23.2395 9.52024Z"
      fill={color || 'white'}
    />
  </svg>
);
export default ArrowIncrease;
