import styled from '@emotion/styled';
import Image from '../../../../components/Image';

const setBubblePosition = (idx) => {
  switch (idx) {
    case 0:
      return {
        desktop: ['20%', '3%'],
        tablet: ['20%', '3%'],
        mobile: ['-10%', '1%'],
      };
    case 1:
      return {
        desktop: ['48%', '7%'],
        tablet: ['48%', '7%'],
        mobile: ['34%', '7%'],
      };
    case 2:
      return {
        desktop: ['75%', '1%'],
        tablet: ['75%', '1%'],
        mobile: ['76%', '0%'],
      };
    case 3:
      return {
        desktop: ['12%', '27%'],
        tablet: ['12%', '27%'],
        mobile: ['-20%', '27%'],
      };
    case 4:
      return {
        desktop: ['21%', '53%'],
        tablet: ['21%', '53%'],
        mobile: ['-20%', '53%'],
      };
    case 5:
      return {
        desktop: ['12%', '79%'],
        tablet: ['12%', '79%'],
        mobile: ['-12%', '79%'],
      };
    case 6:
      return {
        desktop: ['40%', '70%'],
        tablet: ['40%', '70%'],
        mobile: ['34%', '72%'],
      };
    case 7:
      return {
        desktop: ['75%', '76%'],
        tablet: ['75%', '76%'],
        mobile: ['76%', '79%'],
      };
    case 8:
      return {
        desktop: ['63%', '60%'],
        tablet: ['63%', '60%'],
        mobile: ['85%', '53%'],
      };
    case 9:
      return {
        desktop: ['70%', '32%'],
        tablet: ['70%', '32%'],
        mobile: ['85%', '27%'],
      };
    default:
      throw new Error(
        `SpotTheWord: No case for index passed to setBubblePosition detected (value given was ${idx})`
      );
  }
};

const set20BubblePosition = (idx) => {
  switch (idx) {
    case 0:
      return {
        desktop: ['15%', '0%'],
        tablet: ['15%', '0%'],
        mobile: ['-5%', '0%'],
      };
    case 1:
      return {
        desktop: ['36%', '-3%'],
        tablet: ['36%', '-3%'],
        mobile: ['36%', '-3%'],
      };
    case 2:
      return {
        desktop: ['55%', '-4%'],
        tablet: ['55%', '-4%'],
        mobile: ['70%', '-4%'],
      };
    case 3:
      return {
        desktop: ['70%', '7%'],
        tablet: ['70%', '7%'],
        mobile: ['97%', '7%'],
      };
    case 4:
      return {
        desktop: ['8%', '21%'],
        tablet: ['8%', '21%'],
        mobile: ['-5%', '21%'],
      };
    case 5:
      return {
        desktop: ['28%', '16%'],
        tablet: ['28%', '16%'],
        mobile: ['28%', '16%'],
      };
    case 6:
      return {
        desktop: ['50%', '14%'],
        tablet: ['50%', '14%'],
        mobile: ['70%', '14%'],
      };
    case 7:
      return {
        desktop: ['77%', '28%'],
        tablet: ['77%', '28%'],
        mobile: ['97%', '28%'],
      };
    case 8:
      return {
        desktop: ['23%', '36%'],
        tablet: ['23%', '36%'],
        mobile: ['7%', '36%'],
      };
    case 9:
      return {
        desktop: ['10%', '47%'],
        tablet: ['10%', '47%'],
        mobile: ['-15%', '47%'],
      };
    case 10:
      return {
        desktop: ['70%', '46%'],
        tablet: ['70%', '46%'],
        mobile: ['85%', '46%'],
      };
    case 11:
      return {
        desktop: ['16%', '65%'],
        tablet: ['16%', '65%'],
        mobile: ['-2%', '65%'],
      };
    case 12:
      return {
        desktop: ['43%', '69%'],
        tablet: ['43%', '69%'],
        mobile: ['30%', '69%'],
      };
    case 13:
      return {
        desktop: ['80%', '69%'],
        tablet: ['80%', '69%'],
        mobile: ['98%', '69%'],
      };
    case 14:
      return {
        desktop: ['60%', '68%'],
        tablet: ['60%', '68%'],
        mobile: ['68%', '68%'],
      };
    case 15:
      return {
        desktop: ['22%', '83%'],
        tablet: ['22%', '83%'],
        mobile: ['-5%', '83%'],
      };
    case 16:
      return {
        desktop: ['12%', '98%'],
        tablet: ['12%', '98%'],
        mobile: ['12%', '98%'],
      };
    case 17:
      return {
        desktop: ['38%', '87%'],
        tablet: ['38%', '87%'],
        mobile: ['38%', '87%'],
      };
    case 18:
      return {
        desktop: ['87%', '87%'],
        tablet: ['87%', '87%'],
        mobile: ['72%', '87%'],
      };
    case 19:
      return {
        desktop: ['55%', '100%'],
        tablet: ['55%', '100%'],
        mobile: ['60%', '100%'],
      };
    case 20:
      return {
        desktop: ['73%', '92%'],
        tablet: ['73%', '92%'],
        mobile: ['73%', '92%'],
      };
    default:
      throw new Error(
        `SpotTheWord: No case for index passed to setBubblePosition detected (value given was ${idx})`
      );
  }
};

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const BubblesWrapper = styled.div`
  width: 80%;
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 2rem;
  ${({ theme }) => theme.media.mobile} {
    width: 66%;
  }
`;

export const TitleNoteContainer = styled.div`
  text-align: center;
  color: white;
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
`;

export const StyledImage = styled(Image)`
  width: 100%;
`;

export const TitleWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Bubble = styled.button`
  color: ${({ theme, selected }) =>
    selected ? theme.colors.primaryMain : theme.colors.neutralMain};
  background: ${({ theme, selected }) =>
    selected ? theme.colors.neutralMain : theme.colors.neutralLight};
  box-shadow: ${({ theme, selected }) => !selected && theme.shadows.elevation1};
  cursor: pointer;
  height: ${({ isSmall }) => (isSmall ? '64px' : '94px')};
  width: ${({ isSmall }) => (isSmall ? '64px' : '94px')};
  border-radius: 50%;
  border: ${({ theme, selected }) =>
    selected ? theme.borders.section : 'none'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: absolute;
  left: ${({ idx, isSmall }) =>
    isSmall
      ? set20BubblePosition(idx).desktop[0]
      : setBubblePosition(idx).desktop[0]};
  top: ${({ idx, isSmall }) =>
    isSmall
      ? set20BubblePosition(idx).desktop[1]
      : setBubblePosition(idx).desktop[1]};
  ${({ theme }) => theme.media.tablet} {
    left: ${({ idx, isSmall }) =>
      isSmall
        ? set20BubblePosition(idx).tablet[0]
        : setBubblePosition(idx).tablet[0]};
    top: ${({ idx, isSmall }) =>
      isSmall
        ? set20BubblePosition(idx).tablet[1]
        : setBubblePosition(idx).tablet[1]};
  }
  ${({ theme }) => theme.media.mobile} {
    left: ${({ idx, isSmall }) =>
      isSmall
        ? set20BubblePosition(idx).mobile[0]
        : setBubblePosition(idx).mobile[0]};
    top: ${({ idx, isSmall }) =>
      isSmall
        ? set20BubblePosition(idx).mobile[1]
        : setBubblePosition(idx).mobile[1]};
  }

  :focus {
    outline: 2px solid black;
    outline-offset: -6px;
    border: 2px solid ${({ theme }) => theme.colors.primaryMain};
  }
  :hover {
    color: ${({ theme }) => theme.colors.primaryMain};
  }
`;

export const ButtonWrapper = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
`;

export const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
