import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Form = styled.form`
  max-width: 430px;
  flex: 1;
`;
export const Preview = styled.div`
  width: 100%;
  max-width: ${({ increaseWidth }) => (increaseWidth ? '700px' : '500px')};
  margin: 50px auto 0;
  border: 1px blue dashed;
  height: fit-content;
  min-height: 85vh;
  overflow: hidden;
  display: flex;
`;
