import theme from '../../../theme';

const TryAgain = ({ width, height, color, ...props }) => (
  <svg
    width={width || '48'}
    height={height || '48'}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.9134 36.9599L18.2971 38.3405C18.8601 38.9022 18.8611 39.814 18.2994 40.377C17.7377 40.94 16.8259 40.941 16.2629 40.3793L12.4229 36.5479C11.8599 35.9861 11.8589 35.0744 12.4206 34.5114L16.2606 30.6628C16.8224 30.0998 17.7341 30.0988 18.2971 30.6605C18.8601 31.2222 18.8611 32.134 18.2994 32.697L16.9196 34.0799H33.6C35.9859 34.0799 37.92 32.1458 37.92 29.7599V16.3199C37.92 15.5246 38.5647 14.8799 39.36 14.8799C40.1553 14.8799 40.8 15.5246 40.8 16.3199V29.7599C40.8 33.7363 37.5765 36.9599 33.6 36.9599H16.9134Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      d="M14.4002 13.9199C12.0143 13.9199 10.0802 15.854 10.0802 18.2399V31.6799C10.0802 32.4752 9.43549 33.1199 8.6402 33.1199C7.84491 33.1199 7.2002 32.4752 7.2002 31.6799V18.2399C7.2002 14.2634 10.4237 11.0399 14.4002 11.0399H31.0858L29.7 9.64863C29.1387 9.08518 29.1405 8.17342 29.7039 7.61217C30.2674 7.05091 31.1792 7.05269 31.7404 7.61614L35.5804 11.4711C36.1417 12.0346 36.1399 12.9464 35.5764 13.5076L31.7364 17.3326C31.173 17.8939 30.2612 17.8921 29.7 17.3286C29.1387 16.7652 29.1405 15.8534 29.704 15.2922L31.0816 13.9199H14.4002Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default TryAgain;
