import { Result } from 'antd';
import * as T from '../../components/Typography';
import { Button } from './../../components';
import { navRoutes } from './../../constants';

const PageNotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle={
        <T.P color="neutralMain" ta="center">
          Sorry, the page you visited does not exist.
        </T.P>
      }
      extra={
        <Button.BasicButton
          variant="secondary"
          to={navRoutes.GENERAL.LOGIN}
          style={{ maxWidth: '300px' }}
          mx="auto"
          my="0"
        >
          Back Home
        </Button.BasicButton>
      }
      style={{ paddingTop: '100px' }}
    />
  );
};

export default PageNotFound;
