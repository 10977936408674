import theme from '../../../theme';

const masterInTheMaking = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill={theme.colors.neutralMain} />
    <circle
      cx="32"
      cy="32"
      r="30"
      stroke="white"
      strokeOpacity="0.1"
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.68 37.76C40.0722 37.76 40.437 37.8776 40.7409 38.0795L42.862 29.5952C43.0003 29.0421 42.4007 28.5979 41.9118 28.8912L36.1044 32.3756L32.5735 25.3138C32.3379 24.8426 31.6657 24.842 31.4292 25.3127L27.8805 32.3755L22.0911 28.8917C21.6022 28.5975 21.0018 29.0417 21.1402 29.5952L23.261 38.0783C23.5645 37.8771 23.9286 37.76 24.32 37.76H39.68ZM23.68 39.68C23.68 39.3265 23.9665 39.04 24.32 39.04H39.68C40.0335 39.04 40.32 39.3265 40.32 39.68V43.52C40.32 43.8735 40.0335 44.16 39.68 44.16H24.32C23.9665 44.16 23.68 43.8735 23.68 43.52V39.68Z"
      fill={theme.colors.neutralSurface}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 26.24C30.2327 26.24 28.8 24.8073 28.8 23.04C28.8 21.2727 30.2327 19.84 32 19.84C33.7673 19.84 35.2 21.2727 35.2 23.04C35.2 24.8073 33.7673 26.24 32 26.24ZM21.12 30.08C19.7061 30.08 18.56 28.9339 18.56 27.52C18.56 26.1062 19.7061 24.96 21.12 24.96C22.5338 24.96 23.68 26.1062 23.68 27.52C23.68 28.9339 22.5338 30.08 21.12 30.08ZM40.32 27.52C40.32 28.9339 41.4661 30.08 42.88 30.08C44.2938 30.08 45.44 28.9339 45.44 27.52C45.44 26.1062 44.2938 24.96 42.88 24.96C41.4661 24.96 40.32 26.1062 40.32 27.52Z"
      fill={theme.colors.neutralSurface}
    />
  </svg>
);
export default masterInTheMaking;
