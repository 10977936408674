import * as T from '../../../../../components/Typography';
import Markdown from '../../../../../components/Markdown';
import { Col, Row } from '../../../../../components/Grid';

import * as S from './style';
import data from './data';

const Section = ({ word }) => {
  return (
    <>
      <S.Row bgColor={'neutralLight'} direction="column">
        <Row jc="center" mt="2" mb="2">
          <Col w={[4, 8, 8]} noWrap>
            <S.Side>
              <T.P weight="bold">Word (ph)</T.P>
            </S.Side>
            <S.Side>
              <T.P weight="bold">Definition</T.P>
            </S.Side>
          </Col>
        </Row>
        <S.Divider />
        <Row jc="center" mt="2" mb="2">
          <Col w={[4, 8, 8]} noWrap>
            <S.Side>
              <T.P weight="bold">{word.word}</T.P>
            </S.Side>
            <S.Side>
              <Markdown
                text={word.definition}
                customStyles={{
                  p: {
                    size: 'normal',
                    weight: 'regular',
                    mb: 2,
                    lh: '150%',
                  },
                }}
              />
            </S.Side>
          </Col>
        </Row>
      </S.Row>

      <S.Row bgColor={'white'} direction="column">
        <Row jc="center">
          <Col w={[4, 8, 8]} noWrap>
            <T.P weight="bold" mb="2">
              Example of words
            </T.P>
          </Col>
        </Row>
        <S.Divider />
        {word.examples.map((word, index) => (
          <Row jc="center" key={word.word + index} mt="2" mb="2">
            <Col w={[4, 8, 8]} noWrap>
              <S.Side>
                <Markdown
                  text={word.word}
                  customStyles={{
                    p: {
                      size: 'normal',
                      weight: 'regular',
                      mb: 2,
                      lh: '150%',
                      ta: 'left',
                    },
                  }}
                />
              </S.Side>
              <S.Side>
                <T.P>{word.meaning}</T.P>
              </S.Side>
            </Col>
          </Row>
        ))}
      </S.Row>
    </>
  );
};

const Phobia2 = () => {
  return data.map((word, index) => (
    <Section word={word} index={index} key={word.word + index} />
  ));
};

export default Phobia2;
