import { Row, Col } from '../../../../components/Grid';
import * as T from '../../../../components/Typography';
import { IconWithParagraph } from '../../../../components';
import { BasicButton } from '../../../../components/Button';
import { navRoutes } from '../../../../constants';

const Safeguarding = () => {
  return (
    <Row>
      <Col w={[4, 9, 9]} dir="column" ai="flex-start">
        <T.H2>Safeguarding</T.H2>
        <T.P mt="3">
          At Shannon Trust, we are committed to safeguarding, protecting
          children and vulnerable adults and keeping employees, volunteers and
          people involved with our service safe from harm. We comply with
          relevant and statutory guidance by taking all reasonable steps to
          ensure we do not knowingly engage someone who is barred from
          undertaking regulated activity with vulnerable adults or children.
        </T.P>
        <T.P mt="5" mb="3">
          Safeguarding is the responsibility of all employees, trustees and
          volunteers. This policy outlines our safeguarding principles and
          practices.
        </T.P>

        <T.H2 mt="5">What is safeguarding?</T.H2>
        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>
            Protecting the rights of adults to live in safety, free from abuse
            and neglect. Abuse can be emotional, physical or psychological.
          </T.P>
        </IconWithParagraph>

        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>
            Protecting children from maltreatment; preventing impairment of
            children’s health or development; ensuring that children grow up in
            circumstances consistent with the provision of safe and effective
            care; and taking action to enable all children to have the best
            outcomes.
          </T.P>
        </IconWithParagraph>

        <T.H2 mt="5">Keeping everyone safe</T.H2>

        <T.P mt="3">
          To ensure that we can work together in a the safest way, the following
          should be adhered to;
        </T.P>

        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>
            In order to maintain a professional relationship and respect
            personal boundaries, Coaches and Learners are strongly advised to
            withhold from sharing personal information with one another.
          </T.P>
        </IconWithParagraph>

        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>
            Should a Learner volunteer information that suggest they, or anyone
            else is experiencing harm, this should be reported to the Programme
            Manager at the earliest opportunity.
          </T.P>
        </IconWithParagraph>

        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>
            Should a Learner feel there is a safeguarding issue relating to
            their Shannon Trust Coach, they should report this to their
            Probation Practitioner at the earliest opportunity, who can then
            bring this to the attention of Shannon Trust.
          </T.P>
        </IconWithParagraph>
      </Col>
      <Col w={[4, 6, 6]}>
        <BasicButton mt="6" to={navRoutes.COACH.DASHBOARD}>
          <T.P color="white" weight="semi">
            Return to dashboard
          </T.P>
        </BasicButton>
      </Col>
    </Row>
  );
};

export default Safeguarding;
