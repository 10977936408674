import theme from '../../../theme';

const firstStep = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="32"
      cy="32"
      r="30"
      fill={theme.colors.tertiaryLight}
      stroke={theme.colors.tertiaryMain}
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.76 29.44C21.76 35.0954 26.3446 39.68 32 39.68C37.6554 39.68 42.24 35.0954 42.24 29.44C42.24 23.7846 37.6554 19.2 32 19.2C26.3446 19.2 21.76 23.7846 21.76 29.44ZM30.0871 33.2702H31.36V26.5963L30.8376 27.0857C30.5796 27.3273 30.1746 27.3141 29.9329 27.0561C29.6913 26.7982 29.7046 26.3932 29.9625 26.1515L31.5625 24.6528C31.9713 24.2699 32.64 24.5598 32.64 25.1199V33.2702H33.9242C34.2776 33.2702 34.5642 33.5567 34.5642 33.9102C34.5642 34.2636 34.2776 34.5502 33.9242 34.5502H30.0871C29.7336 34.5502 29.4471 34.2636 29.4471 33.9102C29.4471 33.5567 29.7336 33.2702 30.0871 33.2702ZM41.2157 36.3536L43.6852 40.6309C43.903 41.0082 43.6885 41.488 43.2622 41.5773L39.5121 42.3628L36.9568 45.2178C36.6663 45.5423 36.1435 45.4882 35.9257 45.1109L33.4751 40.8665C36.6338 40.4627 39.3927 38.7798 41.2157 36.3536ZM28.2708 45.0914L30.6981 40.8872C27.5277 40.5306 24.7467 38.8872 22.8897 36.4916L20.5112 40.6114C20.2934 40.9886 20.5079 41.4685 20.9342 41.5578L24.6843 42.3432L27.2396 45.1982C27.5301 45.5228 28.053 45.4686 28.2708 45.0914Z"
      fill={theme.colors.tertiaryMain}
    />
  </svg>
);
export default firstStep;
