import theme from '../../../theme';

const Cross = ({ width, height, color, ...props }) => (
  <svg
    width={width || '13'}
    height={height || '13'}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.4251 6.48979L0.96943 2.03009C0.676669 1.73706 0.676884 1.26219 0.96991 0.96943C1.26294 0.676669 1.73781 0.676884 2.03057 0.96991L6.48655 5.42992L10.9538 0.969276C11.2469 0.6766 11.7218 0.676953 12.0145 0.970064C12.3072 1.26318 12.3068 1.73805 12.0137 2.03072L7.54673 6.49106L12.0123 10.9607C12.3051 11.2537 12.3049 11.7286 12.0118 12.0214C11.7188 12.3141 11.2439 12.3139 10.9512 12.0209L6.48528 7.55093L2.02994 11.9997C1.73682 12.2923 1.26195 12.292 0.969276 11.9989C0.6766 11.7058 0.676953 11.2309 0.970064 10.9382L5.4251 6.48979Z"
      fill={color || theme.colors.neutralSurface}
    />
  </svg>
);

export default Cross;
