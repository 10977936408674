import theme from '../../../theme';

const endOfM2 = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill={theme.colors.tertiaryMain} />
    <circle
      cx="32"
      cy="32"
      r="30"
      stroke={theme.colors.neutralMain}
      strokeOpacity="0.1"
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.2 21.8C23.5373 21.8 23 22.3373 23 23V32C23 32.3314 23.2703 32.6 23.6038 32.6L34.486 32.6C35.4863 32.5905 36.2895 31.777 36.2799 30.7829L36.2456 27.1943L36.28 23.6C36.28 22.6059 35.4691 21.8 34.4688 21.8H24.2ZM41.8406 25.3999H37.4702L37.453 27.1828L37.4873 30.7714C37.5032 32.4281 36.1646 33.784 34.4956 33.7998L29.0374 33.7999V35.584C29.0374 35.9153 29.3077 36.184 29.6411 36.184H41.716C42.0135 36.184 42.3047 36.0988 42.5553 35.9387C43.2797 35.4759 43.4926 34.5147 43.0334 33.7892L41.2194 30.7942L43.155 27.7993C43.3161 27.5448 43.4 27.2554 43.4 26.9599C43.4 26.0998 42.7033 25.3999 41.8406 25.3999Z"
      fill={theme.colors.neutralSurface}
    />
    <path
      d="M24.8 42.5C24.8 42.9971 24.3971 43.4 23.9 43.4C23.403 43.4 23 42.9971 23 42.5V22.7C23 22.203 23.403 21.8 23.9 21.8C24.3971 21.8 24.8 22.203 24.8 22.7V42.5Z"
      fill={theme.colors.neutralSurface}
    />
  </svg>
);
export default endOfM2;
