import * as S from './style';
import Icon from '../Icon';

const Badge = ({ type, color, borderColor, iconColor, icon, ...props }) => {
  return (
    <S.Wrapper>
      <S.IconWrapper
        color={color}
        borderColor={borderColor}
        type={type}
        large
        {...props}
      >
        <Icon icon={icon} color={iconColor} width="100%" height="100%" />
      </S.IconWrapper>
    </S.Wrapper>
  );
};

export default Badge;
