import { Link } from 'react-router-dom';
import { Table, Tag, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import { PlayButton } from '../../components/Button';
import { navRoutes, mediaTypes } from './../../constants';
import { getMediaUrl } from './../../helpers';

export const TitleImageContainer = styled.div`
  width: 160px;
  height: 100px;
  overflow: hidden;
  border-radius: 16px;
  margin-right: 1rem;
`;

const tagColors = [
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'purple',
];

const columns = [
  {
    title: 'File / Key',
    dataIndex: 'id',
    key: 'id',
    width: '25%',
    render: (text, record) => {
      if (record.type === mediaTypes.IMAGE) {
        const mediaUrl = getMediaUrl(record.key, false);
        return (
          <>
            <TitleImageContainer>
              <img
                src={mediaUrl}
                alt={text}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </TitleImageContainer>
            <Typography.Paragraph style={{ marginTop: 20 }} copyable>
              {record.key}
            </Typography.Paragraph>
          </>
        );
      }

      const maleMediaUrl = getMediaUrl(record.key, true, 'MALE');
      const femaleMediaUrl = getMediaUrl(record.key, true, 'FEMALE');

      return (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <div>
              <PlayButton audioUrl={maleMediaUrl} showCrossIconOnError />
              <Typography.Paragraph style={{ marginTop: 10 }}>
                Male voice
              </Typography.Paragraph>
            </div>
            <div>
              <PlayButton audioUrl={femaleMediaUrl} showCrossIconOnError />
              <Typography.Paragraph style={{ marginTop: 10 }}>
                Female voice
              </Typography.Paragraph>
            </div>
          </div>
          <Typography.Paragraph style={{ marginTop: 20 }} copyable>
            {record.key}
          </Typography.Paragraph>
        </>
      );
    },
  },
  {
    title: 'description',
    dataIndex: 'description',
    key: 'description',
    render: (text, record) => {
      return <Typography.Paragraph copyable>{text}</Typography.Paragraph>;
    },
  },
  {
    title: 'tags',
    dataIndex: 'tags',
    key: 'tags',
    filters: [],
    onFilter: (value, record) => record.tags.map((t) => t.id).includes(value),
    filterMode: 'tree',
    filterSearch: true,
    render: (text, record) => {
      return (
        <>
          {record.tags?.map((t) => (
            <Tag
              key={t.id}
              color={tagColors[t.id % tagColors.length] || 'blue'}
            >
              {t.tag}
            </Tag>
          ))}
        </>
      );
    },
  },
  {
    key: 'tags',
    render: (text, record) => {
      return (
        <>
          <Link to={navRoutes.CMS.MEDIA_UPDATE.replace(':id', record.id)}>
            <EditOutlined />
          </Link>
        </>
      );
    },
  },
];

export default ({ tags, data, loading }) => {
  columns[2].filters = tags?.map((t) => ({
    text: <span>{t.tag}</span>,
    value: t.id,
  }));

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
      />
    </>
  );
};
