import theme from '../../../theme';

const Back = ({ width, height, color, ...props }) => (
  <svg
    width={width || '20'}
    height={height || '12'}
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.20609 7.19796L6.9919 9.98377C7.42097 10.4128 7.42097 11.1085 6.9919 11.5376C6.56283 11.9666 5.86716 11.9666 5.43809 11.5376L0.776678 6.87616C0.347607 6.44709 0.347607 5.75142 0.776678 5.32235L5.43809 0.660937C5.86716 0.231866 6.56283 0.231866 6.9919 0.660937C7.42097 1.09001 7.42097 1.78567 6.9919 2.21474L4.20609 5.00055H18.6454C19.2522 5.00055 19.7441 5.49246 19.7441 6.09925C19.7441 6.70605 19.2522 7.19796 18.6454 7.19796L4.20609 7.19796Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);
export default Back;
