import { BasicInput } from './../components/Inputs';
import * as T from './../components/Typography';
import { navRoutes } from './../constants';

const MediaKeyInput = ({ label, ...props }) => {
  return (
    <BasicInput
      label={
        <>
          {label} -{' '}
          <T.Link to={navRoutes.CMS.MEDIA} target="_blank">
            Pick / Upload new
          </T.Link>
        </>
      }
      {...props}
    />
  );
};

export { MediaKeyInput };
