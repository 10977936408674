import styled from '@emotion/styled';

export const Wrapper = styled.div`
  height: 210px;
  width: 100%;
  display: flex;
`;

export const ButtonWrapper = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  flex: ${({ flex }) => flex || 0};

  display: flex;
  align-items: end;
  flex: 1;
`;

export const TypeInput = styled.input`
  outline: 0;
  border-width: 0 0 2px;
  border-color: ${({ theme, isCorrect }) =>
    isCorrect ? theme.colors.quinaryMain : theme.colors.primaryMain};
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 150%;
  text-align: center;
  background: transparent;
  margin-left: ${({ theme }) => theme.spacings[2]};
  margin-top: ${({ theme }) => theme.spacings[4]};
  margin-bottom: ${({ theme }) => theme.spacings[4]};
  color: ${({ theme }) => theme.colors.neutralMain};
  transition: 0.3s;
  &:focus {
    border-color: ${({ theme, isCorrect }) =>
      isCorrect ? theme.colors.quinaryMain : theme.colors.neutralMain};
  }
`;
