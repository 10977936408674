import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const Container = styled.section`
  ${setMargin};
  background-image: ${({ image }) => `url(${image})`};
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] : theme.colors.neutralMain};
  width: ${({ width }) => width || '30vw'};
  max-width: ${({ staticBg }) => (staticBg ? '100%' : '469px')};
  min-height: ${({ minHeight }) => minHeight || '100vh'};
  background-size: ${({ staticBg }) => (staticBg ? 'cover' : 'auto')};
  background-repeat: repeat;
  background-position: bottom center;
  position: ${({ general }) => (general ? 'static' : 'relative')};
  z-index: ${({ general }) => (general ? '110' : '99')};
  background-attachment: ${({ staticBg }) => (staticBg ? 'inherit' : 'fixed')};
`;
