import { fields, createSchema, validate as _validate } from '..';

const {
  firstName,
  lastName,
  optionalEmail,
  requiredText,
  birthDate,
  sessionPreferences,
  email,
} = fields;

const schema = createSchema({
  nomisId: requiredText,
  delius: requiredText,
  firstName,
  lastName,
  dateOfBirth: birthDate,
  email: optionalEmail,
  probationOfficerName: requiredText,
  probationOfficerEmail: email,
  sessionPreferences: sessionPreferences,
  coachId: requiredText,
});

const validate = (data) => _validate(schema, data);

export default validate;
