import theme from '../../../theme';

const dropTheMic = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="32"
      cy="32"
      r="30"
      fill={theme.colors.primaryLight}
      stroke={theme.colors.secondaryMain}
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7079 25.92C26.0211 29.396 28.9425 32.12 32.5001 32.12C36.0576 32.12 38.979 29.396 39.2922 25.92H25.7079ZM25.7079 24.68C26.0211 21.204 28.9425 18.48 32.5001 18.48C36.0576 18.48 38.979 21.204 39.2922 24.68H25.7079ZM36.6966 32.1827L36.2514 37.08H28.8098L28.3681 32.2218C29.5763 32.9446 30.9897 33.36 32.5 33.36C34.0374 33.36 35.4742 32.9296 36.6966 32.1827ZM35.8331 41.6815L36.1387 38.32H28.9226L29.2281 41.6815C29.3733 43.2783 30.7121 44.5009 32.3154 44.5009H32.7459C34.3492 44.5009 35.688 43.2783 35.8331 41.6815Z"
      fill={theme.colors.secondaryMain}
    />
  </svg>
);
export default dropTheMic;
