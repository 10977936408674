const printSingleOrPluralStr = (val, singleOutput) => {
  if (typeof val !== 'number' || typeof singleOutput !== 'string') {
    // eslint-disable-next-line no-console
    console.error('printSingleOrPluralStr helper is missing args');
    return '';
  }
  return val === 1 ? singleOutput : `${singleOutput}s`;
};

export default printSingleOrPluralStr;
