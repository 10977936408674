import styled from '@emotion/styled';
import { Modal as AntModal } from 'antd';
import theme from '../../theme';

export const Modal = styled(AntModal)`
  max-width: 375px;
  position: relative !important;
  bottom: 0;
  margin-bottom: 0 !important;

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-title {
    display: none;
    color: ${({ theme }) => theme.colors.white};
    font-family: 'hero-new-hairline', sans-serif;
    font-weight: 500;
    padding-top: 5px;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-content {
    background-color: ${({ theme, bgColor }) =>
      theme.colors[bgColor] || theme.colors.primaryMain};
    border: none;
    box-shadow: none;
    bottom: 0;
    position: relative;
  }

  .ant-modal-body {
    box-shadow: none;
    z-index: 999;
    display: flex;
    flex-direction: column;
  }

  ${theme.media.mobile} {
    max-width: 100%;
    width: 100% !important;
    position: absolute !important;
    bottom: 0;
    & .ant-modal-content {
      position: absolute;
      width: 100%;
    }
  }
`;
