const data = [
  {
    id: 1,
    text: 'Add 2 cups of risotto rice to the pan. Stir it in with the fried onions for a short while until the rice grains are all shiny with oil.',
  },
  {
    id: 2,
    text: 'Finely slice one onion and fry it in light oil until soft. (Don’t put the heat too high.)',
  },
  {
    id: 3,
    text: 'Turn the heat up slightly and add (optional) dry white wine.',
  },
  {
    id: 4,
    text: 'Now add chicken stock a little at a time. Keep stirring until the stock has been absorbed. (You will need 4 cups of stock.)',
  },
  {
    id: 5,
    text: 'Add salt and pepper to taste and slices of parmesan cheese.',
  },
  {
    id: 6,
    text: 'Scatter with chopped chives or basil to finish or spice it up with other nice veg such as peas or peppers.',
  },
];

export default data;
