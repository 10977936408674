import theme from '../../../theme';

const ArrowLine = ({ width, height, color, ...props }) => (
  <svg
    width={width || '26'}
    height={height || '67'}
    viewBox="0 0 26 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="12.998"
      y1="2.81836"
      x2="12.998"
      y2="55.3375"
      stroke={theme.colors.neutralMain}
      strokeWidth="4.11915"
      strokeLinecap="round"
      strokeDasharray="12.36 12.36"
    />
    <path
      d="M21.24 51.8355L15.5056 57.6167L13.0056 59.674L10.5056 57.6167L4.77129 51.8355C3.7938 50.85 2.21089 50.8519 1.23575 51.8398C0.260619 52.8276 0.262521 54.4273 1.24 55.4128L11.24 65.4946C11.7051 65.9635 12.3354 66.2324 13.0056 66.2324C13.6759 66.2324 14.3062 65.9635 14.7713 65.4946L24.7713 55.4128C25.7488 54.4273 25.7507 52.8276 24.7755 51.8398C23.8004 50.8519 22.2175 50.85 21.24 51.8355Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default ArrowLine;
