import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const IconWrapper = styled.div`
  ${setMargin};
  width: ${({ large }) => (large ? '214px' : '64px')};
  height: ${({ large }) => (large ? '214px' : '64px')};
  min-width: ${({ large }) => (large ? '214px' : '64px')};
  min-height: ${({ large }) => (large ? '214px' : '64px')};
  margin-right: ${({ theme }) => theme.spacings[3]} !important;
`;

export const Wrapper = styled.div`
  ${setMargin};
  display: flex;
  align-items: center;
`;

export const LevelsWrapper = styled.ul`
  ${setMargin};
  list-style: none;

  li:before {
    content: '✓ ';
  }
`;
export const TextWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacings[3]};
  display: flex;
  flex-direction: column;
`;
