const Plus = ({ width, height, color, ...props }) => (
  <svg
    width={width || '25'}
    height={height || '25'}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9542 11.2033L13.957 4.89919C13.9572 4.48497 14.2932 4.14934 14.7074 4.14953C15.1216 4.14971 15.4572 4.48565 15.457 4.89987L15.4542 11.2044L21.7672 11.2091C22.1814 11.2094 22.5169 11.5455 22.5166 11.9597C22.5163 12.3739 22.1803 12.7094 21.7661 12.7091L15.4535 12.7044L15.4506 19.0226C15.4505 19.4368 15.1145 19.7724 14.7003 19.7722C14.2861 19.7721 13.9505 19.4361 13.9506 19.0219L13.9535 12.7033L7.65737 12.6986C7.24316 12.6983 6.90762 12.3623 6.90793 11.9481C6.90824 11.5339 7.24428 11.1983 7.65849 11.1986L13.9542 11.2033Z"
      fill={color}
    />
  </svg>
);

export default Plus;
