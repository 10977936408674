import React from 'react';

import * as S from './style';

const IconButton = ({ handleClick, children, ...props }) => {
  return (
    <S.StyledIconButton type="button" onClick={handleClick} {...props}>
      {children}
    </S.StyledIconButton>
  );
};

export default IconButton;
