import axios from 'axios';
import handleError from './format-error';

import { MEDIA_BASE } from '../constants';

const createOrUpdateMedia = async ({
  data: { id, isUpdate, type, description, tags, keys, keysToDelete },
  options,
} = {}) => {
  try {
    const { data } = await axios.post(`${MEDIA_BASE}/`, {
      id,
      isUpdate,
      type,
      description,
      tags,
      keys,
      keysToDelete,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getSignedUrl = async ({
  fileName,
  fileType,
  fileSize,
  fileCategory,
  voice,
  key,
  options,
} = {}) => {
  try {
    const { data } = await axios.get(`${MEDIA_BASE}/signed-url`, {
      params: {
        fileName,
        fileType,
        fileSize,
        fileCategory,
        voice,
        key,
      },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const uploadFileToS3 = async ({ signedUrl, file, options } = {}) => {
  try {
    const { data } = await axios.put(signedUrl, file);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export { getSignedUrl, uploadFileToS3, createOrUpdateMedia };
