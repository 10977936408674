import styled from '@emotion/styled';
import setMargin from '../../../helpers/set-margin';
import { commonStyle } from '../style';

export const Label = styled.label`
  ${setMargin};
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 8px;
`;

export const OptionalTag = styled.span`
  display: inline-block;
  font-weight: 400 !important;
  color: ${({ theme }) => theme.colors.neutralDark};
`;

export const PickerWrapper = styled.div`
  width: 100%;

  .ant-picker {
    width: 100%;
    border: ${({ theme, error }) => {
      return error ? `1px solid ${theme.colors.error}` : 'none';
    }};
    background-color: ${({ theme }) => theme.colors.neutralLight};
    padding: ${({ theme }) => theme.spacings[3]};
    border-radius: ${({ theme }) => theme.borders.radius};
    height: 60px;
    border-radius: 16px;
  }
  .ant-picker-input input {
    ${commonStyle};
  }
  .ant-picker-input > input::placeholder {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.neutral80};
    text-align: left;
  }
`;
