import { useFetch, formatQueryError } from '../utils/queries';
import { MEDIA_BASE } from '../constants';

function useGetMediaCms({ type, query, exactMatch }) {
  const context = useFetch(`${MEDIA_BASE}/?q=${query}`, { type, exactMatch });
  return { ...context, media: context.data, error: formatQueryError(context) };
}

function useGetMediaCmsById({ id, mediaKey, isNewSession }) {
  const url = mediaKey
    ? `${MEDIA_BASE}/mediaKey?key=${mediaKey}`
    : `${MEDIA_BASE}/${id}`;
  const context = useFetch(isNewSession ? null : url, undefined);
  return { ...context, media: context.data, error: formatQueryError(context) };
}

function useGetMediaTags() {
  const context = useFetch(`${MEDIA_BASE}/tags`, undefined);
  return { ...context, tags: context.data, error: formatQueryError(context) };
}

export { useGetMediaCms, useGetMediaTags, useGetMediaCmsById };
