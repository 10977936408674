import axios from 'axios';
import handleError from './format-error';

const MILESTONES_BASE = '/milestones';

const getMilestones = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${MILESTONES_BASE}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createMilestone = async ({ title, options } = {}) => {
  try {
    const { data } = await axios.post(`${MILESTONES_BASE}`, { title });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export { getMilestones, createMilestone };
