import * as S from './style';
import * as T from '../../../../../components/Typography';

const data = [
  {
    bold: 'Sentry dogs',
    light:
      'were used to patrol military camps. They worked with one guard and were trained to growl or bark when there were strangers about. Dobermans were perfect for this job and are still used as guard dogs today.',
  },
  {
    bold: 'Scout dogs',
    light:
      'worked with soldiers on foot. They could detect the enemy scent at 1,000 yards. They did not bark but would stiffen and indicate the direction of the enemy. They helped many patrols enter enemy territory undetected.',
  },
  {
    bold: 'Mercy dogs',
    light:
      'were trained to find injured and dying soldiers on the battlefield. They carried medical supplies so that men could help themselves. They stayed with soldiers in their dying moments.',
  },
  {
    bold: 'Messenger dogs',
    light:
      'could run faster than men and could cope with the rough terrain in the trenches. They were trained to carry important messages to and from headquarters. These dogs often got through when human runners would have failed.',
  },

  {
    bold: 'Ratters',
    light:
      'were all terriers. They were used to kill the rats that infested the trenches.',
  },
  {
    bold: 'Supply dogs',
    light:
      'were strong dogs that could pull a cart over rough terrain carrying ammunition and other supplies. Dogs were also used to lay down telegraph wires.',
  },
];

export default data;

export const Item = ({ bold, light }) => (
  <S.ListItem>
    <S.BulletPoint />
    <T.P fontFamily="garamond">
      <T.P
        weight="bold"
        mr="1"
        fontFamily="garamond"
        style={{ display: 'inline' }}
      >
        {bold}
      </T.P>
      {light}
    </T.P>
  </S.ListItem>
);

export const List = () =>
  data.map((item, index) => (
    <Item key={item.bold + index} bold={item.bold} light={item.light} />
  ));
