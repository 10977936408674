import * as T from '../../Typography';
import * as CS from '../style';
import * as S from './style';
import Icon from './../../Icon';

const Textarea = ({
  type = 'textarea',
  name = 'textarea',
  placeholder = 'Type here...',
  label,
  error,
  value,
  handleChange,
  helper,
  color = 'neutralMain',
  w, // width
  disabled,
  labelColor,
  optional,
  m, // margins
  rows,
  ...props
}) => {
  const onChange = (e) => {
    handleChange(e.target.value, e);
  };

  return (
    <CS.Field w={w} disabled={disabled} {...m}>
      {label && (
        <CS.Label htmlFor={label} mb={helper ? '1' : '2'}>
          <T.P color={labelColor || color} m="0" weight="bold">
            {label}
          </T.P>
          {optional && <CS.OptionalTag ml="1">(optional)</CS.OptionalTag>}
        </CS.Label>
      )}
      {helper && (
        <T.P isSmall color="neutralDark" mb="2">
          {helper}
        </T.P>
      )}
      <S.TextAreaWrapper>
        <S.RelativeDiv>
          <S.TextArea
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={handleChange && onChange}
            disabled={disabled}
            error={error}
            aria-disabled={disabled ? 'true' : 'false'}
            rows={rows || 5}
            {...props}
          />
          {error && (
            <S.AlertWrapper>
              <Icon width="28px" height="28px" icon="alert" color="error" />
            </S.AlertWrapper>
          )}
        </S.RelativeDiv>
        <S.ErrorWrapper>
          {error && (
            <T.P color="error" m="0" mt="2" mx={2}>
              {error}
            </T.P>
          )}
        </S.ErrorWrapper>
      </S.TextAreaWrapper>
    </CS.Field>
  );
};

export default Textarea;
