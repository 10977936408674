import { useNavigate } from 'react-router-dom';
import { Col, Row } from '../../../components/Grid';
import { COACH } from '../../../constants/nav-routes';
import { BasicButton } from '../../../components/Button';
import * as T from '../../../components/Typography';
import Video from '../../../components/Video';

const Onboarding1 = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(COACH.ONBOARDING_2);
  };
  return (
    <>
      <Row>
        <Col w={[4, 8, 8]}>
          <T.H2 color="neutralMain">
            Welcome, thank you for coming on board.
          </T.H2>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt="3">
          <T.P color="neutralMain">
            You can use this tool to keep track of your learners' progress
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt="3">
          <Video src="https://www.youtube.com/embed/5ffGBAWAPOI" />
        </Col>
      </Row>
      <Row mt="6" ai="end" style={{ flex: 1 }}>
        <Col w={[4, 8, 8]}>
          <BasicButton
            maxWidth="426px"
            variant="primary"
            handleClick={handleClick}
          >
            Continue
          </BasicButton>
        </Col>
      </Row>
    </>
  );
};

export default Onboarding1;
