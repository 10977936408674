export const list1 = [
  { key: 1, text: 'A fitness instructor leads people in exercise activities.' },
  {
    key: 2,
    text: 'You must be keen on health and fitness, and enjoy meeting people.',
  },
  { key: 3, text: 'You need to motivate and inspire people.' },
];

export const list2 = [
  {
    key: 4,
    text: 'doing fitness assessments and introduction sessions for new customers',
  },
  { key: 5, text: 'showing customers how to use equipment or weights' },
  { key: 6, text: 'giving advice about healthy eating and exercise' },
  {
    key: 7,
    text: 'leading group exercise sessions such as Zumba, kick boxing, swimming or spinning',
  },
  { key: 8, text: 'cleaning and maintaining equipment' },
];
