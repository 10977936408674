import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  width: 300px;
  margin: 0 auto;
`;

export const Option = styled.button`
  width: ${({ grid }) => (grid ? '48%' : '100%')};
  height: ${({ variant }) => (variant === 'small' ? '56px' : '80px')};
  padding: ${({ theme, imageUrl }) =>
    imageUrl ? 0 : `${theme.spacings[3]} ${theme.spacings[2]}`};
  overflow: hidden;
  background: none;
  border: none;
  border-radius: ${({ find }) => (find ? '16px' : '9px')};
  cursor: pointer;
  opacity: ${({ selected, imageUrl, option }) => {
    if (!selected || !imageUrl) return 1;
    if (selected !== option) return 0.5;
    return 1;
  }};
  background-color: ${({ theme, selected, imageUrl, option }) => {
    if (imageUrl) return 'none';
    if (selected === option) return theme.colors.neutralMain;
    return theme.colors.neutralLight;
  }};
  box-shadow: 0px 1px 3px -1px rgba(4, 13, 38, 0.1),
    0px 2px 5px -1px rgba(4, 13, 38, 0.11);
  margin-bottom: ${({ theme, mb }) => theme.spacings[mb || 0]};
  padding-left: ${({ leftPadding }) => (leftPadding ? '30px' : 0)};
`;

export const ButtonWrapper = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: auto;
`;

export const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 12px;
`;

export const ImageWrapper = styled.div`
  width: 300px;
  height: 184px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 16px;
`;

export const TextAudioWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  margin-bottom: ${({ theme, mb }) => theme.spacings[mb || 0]};
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'center'};
`;
