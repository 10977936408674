import * as S from './style';
import * as T from '../../Typography';

const BasicSelect = ({ options = [], handleChange, selected }) => {
  return (
    <S.Group>
      {options.map((item, index) => (
        <S.Button
          value={item.value}
          key={item.value}
          isActive={selected === item.value}
          onClick={() => handleChange(item.value)}
        >
          <S.Number isActive={selected === item.value}>
            <T.P
              size="med"
              weight="bold"
              color={selected === item.value ? 'neutralSurface' : 'neutralMain'}
              ta="center"
            >
              {index + 1}
            </T.P>
          </S.Number>
          <S.TextWrapper>
            <T.P
              color={selected === item.value ? 'neutralSurface' : 'neutralMain'}
              weight="normal"
            >
              {item.label}
            </T.P>
          </S.TextWrapper>
        </S.Button>
      ))}
    </S.Group>
  );
};

export default BasicSelect;
