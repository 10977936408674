import theme from '../../../theme';

const listenAndRepeat = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="32"
      cy="32"
      r="30"
      fill={theme.colors.secondaryLight}
      stroke={theme.colors.secondaryMain}
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.1781 24.76C34.1781 27.9412 36.757 30.52 39.9381 30.52C41.8046 30.52 43.4637 29.6323 44.5163 28.256C43.9746 28.8693 43.2789 29.24 42.4981 29.24C40.6631 29.24 39.2981 27.1925 39.2981 24.76C39.2981 22.3275 40.6631 20.28 42.4981 20.28C43.2788 20.28 43.9745 20.6506 44.5161 21.2638C43.4635 19.8877 41.8045 19 39.9381 19C36.757 19 34.1781 21.5788 34.1781 24.76ZM40.5781 40.12H34.1781V28.8088C35.4522 30.618 37.5571 31.8 39.9381 31.8C40.1539 31.8 40.3673 31.7903 40.5781 31.7713V40.12ZM40.5781 42.68V41.4H34.1781V42.68C34.1781 44.4473 35.6108 45.88 37.3781 45.88C39.1454 45.88 40.5781 44.4473 40.5781 42.68ZM20 28.256C21.0526 29.6323 22.7117 30.52 24.5781 30.52C27.7593 30.52 30.3381 27.9412 30.3381 24.76C30.3381 21.5789 27.7593 19 24.5781 19C22.7118 19 21.0527 19.8877 20.0001 21.2639C20.5418 20.6506 21.2374 20.28 22.0181 20.28C23.8531 20.28 25.2181 22.3275 25.2181 24.76C25.2181 27.1925 23.8531 29.24 22.0181 29.24C21.2373 29.24 20.5417 28.8693 20 28.256ZM30.338 40.12H23.938V31.7713C24.1488 31.7903 24.3623 31.8 24.578 31.8C26.959 31.8 29.064 30.618 30.338 28.8087V40.12ZM30.338 42.68V41.4H23.938V42.68C23.938 44.4473 25.3707 45.88 27.138 45.88C28.9053 45.88 30.338 44.4473 30.338 42.68ZM42.498 27.96C43.4908 27.96 44.418 26.5691 44.418 24.76C44.418 22.9509 43.4908 21.56 42.498 21.56C41.5053 21.56 40.578 22.9509 40.578 24.76C40.578 26.5691 41.5053 27.96 42.498 27.96ZM23.938 24.76C23.938 26.5691 23.0108 27.96 22.018 27.96C21.0253 27.96 20.098 26.5691 20.098 24.76C20.098 22.9509 21.0253 21.56 22.018 21.56C23.0108 21.56 23.938 22.9509 23.938 24.76Z"
      fill={theme.colors.secondaryMain}
    />
  </svg>
);
export default listenAndRepeat;
