import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import setMargin from '../../../helpers/set-margin';

export const Wrapper = styled.div`
  width: 100%;
  .css-y67eo5 .ant-checkbox-inner {
    width: 32px;
    height: 32px;
  }
`;

export const Field = styled.div`
  ${setMargin};
`;

export const Divider = styled.div`
  width: 6px;
  background: ${({ theme }) => theme.colors.neutralMain};
  height: ${({ largeDivider }) => (largeDivider ? '29px' : '20px')}} ;
  display: black;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div:first-child {
    border-radius: 16px !important;
  }
`;
export const ItemWrapperLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
