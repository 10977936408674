import styled from '@emotion/styled';
import { Row } from '../../../components/Grid';

export const Wrapper = styled.div`
  max-width: 600px;
`;

export const NextButtonWrapper = styled(Row)`
  flex: 1;
  max-width: 600px;
  align-items: flex-end;
`;
