import * as S from './style';
import * as T from '../../../../../components/Typography';
import RiseImage from '../../../../../components/assets/rise.jpeg';

import data from '../SimpleRisotto2/data';

const SimpleRisotto2 = () => {
  return (
    <>
      <S.Image src={RiseImage} alt="dog" />
      <S.ContentWrapper bgColor="neutralLight">
        <T.P mb="2" weight="bold">
          Method
        </T.P>
        {data.map((item, index) => (
          <S.ListItem key={item.id}>
            <S.NumberPoint>{index + 1}.</S.NumberPoint>
            <T.P> {item.text}</T.P>
          </S.ListItem>
        ))}
      </S.ContentWrapper>
    </>
  );
};

export default SimpleRisotto2;
