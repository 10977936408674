import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const Wrapper = styled.div`
  ${setMargin};
  display: flex;
  width: 100%;
  align-items: ${({ ai }) => ai || 'center'};
  justify-content: ${({ jc }) => jc || 'flex-start'};
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  color: ${({ color, theme }) =>
    theme.colors[color] || color || theme.colors.neutralMain};
  margin-top: ${({ theme, mt }) => mt || theme.spacings[3]};
`;

export const Button = styled.button`
  ${setMargin};
  background: none;
  outline: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'flex-start'};
`;
