import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 85vh;
`;

export const ContentWrapper = styled.div`
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacings[0]} ${theme.spacings[6]}`};
  ${({ theme }) => theme.media.tablet} {
    padding: ${({ theme }) => `${theme.spacings[0]} ${theme.spacings[4]}`};
  }
`;

export const ImageWrapper = styled.div`
  width: 300px;
  height: 184px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 16px;
  margin-top: 1rem;
`;

export const ButtonsWrapper = styled.div`
  margin-top: auto;
`;

export const ButtonWrapper = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
`;

export const TextAudioWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  margin-bottom: ${({ theme, mb }) => theme.spacings[mb || 0]};
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'center'};
`;

export const TitleImageWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  margin-bottom: ${({ theme, mb }) => theme.spacings[mb || 0]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleImageContainer = styled.div`
  width: 160px;
  height: 100px;
  overflow: hidden;
  border-radius: 16px;
  margin-right: 1rem;
`;

export const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
