import styled from '@emotion/styled';
import CheckIcon from '../../components/assets/Check.svg';

export const TipWrapper = styled.div`
  margin-left: 0px;
  margin-top: ${({ theme }) => theme.spacings[1]};
  margin-bottom: ${({ theme }) => theme.spacings[1]};
  width: 100%;
  padding: ${({ theme, p }) => p || theme.spacings[4]};
  background-color: ${({ theme }) => theme.colors.neutralLight};
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  div {
    display: inline;
  }
`;

export const Content = styled.div`
  div {
    color: ${({ theme }) => theme.colors.primaryMain};
  }
`;

export const CheckUL = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const CheckLI = styled.li`
  display: block;
  background-image: url(${CheckIcon});
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: 6px;
  padding-left: 29px;
  padding-bottom: 5px;
`;
