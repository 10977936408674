import * as S from './style';
import * as T from '../../../../../components/Typography';
import Markdown from '../../../../../components/Markdown';
import { Col, Row } from '../../../../../components/Grid';
import data from './data';

const Phobia1 = () => {
  return (
    <S.Wrapper>
      <Row jc="center" mt="2" mb="2">
        <Col w={[4, 8, 8]} noWrap>
          <S.Side>
            <T.P weight="bold">phobia</T.P>
          </S.Side>
          <S.Side>
            <T.P weight="bold">phobia</T.P>
          </S.Side>
        </Col>
      </Row>
      <S.Divider />
      {data.map((item) => (
        <Row jc="center" key={item.key} mt="2" mb="2">
          <Col w={[4, 8, 8]} noWrap>
            <S.Side>
              <T.P weight="bold">{item.word}</T.P>
            </S.Side>
            <S.Side>
              <Markdown
                text={item.definition}
                customStyles={{
                  p: {
                    size: 'normal',
                    weight: 'regular',
                    mb: 2,
                    lh: '150%',
                  },
                }}
              />
            </S.Side>
          </Col>
        </Row>
      ))}
    </S.Wrapper>
  );
};

export default Phobia1;
