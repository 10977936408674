import styled from '@emotion/styled';
import { Row, Col } from '../../components/Grid';

export const Form = styled.form`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacings[6]};
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ theme }) => theme.media.tablet} {
    padding-top: ${({ theme }) => theme.spacings[5]};
  }
  ${({ theme }) => theme.media.mobile} {
    padding-top: ${({ theme }) => theme.spacings[4]};
  }
`;

export const ButtonsWrapper = styled(Row)`
  flex: 0;
  align-items: flex-end;
  ${({ theme }) => theme.media.tablet} {
    flex: 1;
  }
`;

export const ButtonContainer = styled(Col)`
  align-items: flex-end;
`;
