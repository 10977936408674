import Tag from '../../../components/Tag';
import Tip from '../../../components/Tip';
import * as T from '../../../components/Typography';
import { BasicButton } from '../../../components/Button';
import Markdown from '../../../components/Markdown';

const PageRef = ({ exKey = '' }) => {
  const parts = exKey.split('_');
  const manualStr = parts[0]?.replace('M', 'Manual ');
  const pageStr = parts[1]?.replace('P', 'Page ');
  return manualStr?.concat(', ', pageStr) || '';
};

const Content = ({
  exKey = '',
  intro,
  previewExerciseLink,
  steps,
  onClose,
}) => {
  const Point = (props) => {
    return (
      <li>
        <T.P ml="2" {...props} />
      </li>
    );
  };

  return (
    <div>
      <T.H2 mb="3">Exercise notes</T.H2>
      {exKey && (
        <Tag color="neutralLight" mb="3">
          <T.P color="primaryMain">
            <PageRef exKey={exKey} />
          </T.P>
        </Tag>
      )}
      {intro && (
        <Markdown
          text={intro}
          customStyles={{
            p: {
              mt: 3,
              mb: 4,
            },
          }}
        />
      )}
      <BasicButton
        to={previewExerciseLink}
        linkState={{ canGoBack: true }}
        variant="primary"
        mb="6"
      >
        <T.P color="white" weight="semi">
          Preview exercise
        </T.P>
      </BasicButton>
      {steps ? (
        <>
          <T.P size="med" mb="3" weight="bold">
            Steps
          </T.P>
          <Markdown
            text={steps}
            coachNotesStyle={true}
            customRender={{
              li: Point,
              blockquote: (props) => (
                <Tip ShowBoldTipPrefix mb={4} mt={4} {...props} />
              ),
            }}
          />
        </>
      ) : null}

      <BasicButton handleClick={onClose} variant="primary">
        <T.P color="white" weight="semi">
          Close
        </T.P>
      </BasicButton>
    </div>
  );
};

export default Content;
