import * as S from './style';

const Option = ({ children, ...props }) => {
  // this is just to keep the element in the right position and the moving block can get the x/y values
  if (props.hide)
    return (
      <div {...props} aria-hidden="true" style={{ margin: '0 -5px' }}></div>
    );
  return (
    <S.Wrapper tabIndex="0" {...props}>
      {children}
    </S.Wrapper>
  );
};

export default Option;
