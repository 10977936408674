import theme from '../../../theme';
const attendedFiveSessions = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill={theme.colors.neutralMain} />
    <circle
      cx="32"
      cy="32"
      r="30"
      stroke="white"
      strokeOpacity="0.1"
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.5845 20.4151L39.448 20.1317C38.9335 20.0034 38.6205 19.4824 38.7487 18.968C38.877 18.4535 39.398 18.1405 39.9125 18.2687L43.6384 19.1977C44.1528 19.326 44.4659 19.847 44.3376 20.3614L43.4087 24.0874C43.2804 24.6018 42.7594 24.9149 42.2449 24.7866C41.7305 24.6584 41.4174 24.1373 41.5457 23.6229L41.9882 21.8481L20.9246 32.8476C20.4546 33.0931 19.8747 32.911 19.6292 32.4411C19.3838 31.9711 19.5659 31.3911 20.0358 31.1457L40.5845 20.4151ZM33.28 31.36H30.72C29.6596 31.36 28.8 32.2196 28.8 33.28V43.52C28.8 44.5804 29.6596 45.44 30.72 45.44H33.28C34.3404 45.44 35.2 44.5804 35.2 43.52V33.28C35.2 32.2196 34.3404 31.36 33.28 31.36ZM24.32 35.2H21.76C20.6996 35.2 19.84 36.0596 19.84 37.12V43.52C19.84 44.5804 20.6996 45.44 21.76 45.44H24.32C25.3804 45.44 26.24 44.5804 26.24 43.52V37.12C26.24 36.0596 25.3804 35.2 24.32 35.2ZM42.24 27.52H39.68C38.6196 27.52 37.76 28.3796 37.76 29.44V43.52C37.76 44.5804 38.6196 45.44 39.68 45.44H42.24C43.3004 45.44 44.16 44.5804 44.16 43.52V29.44C44.16 28.3796 43.3004 27.52 42.24 27.52Z"
      fill={theme.colors.neutralSurface}
    />
  </svg>
);
export default attendedFiveSessions;
