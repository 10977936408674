import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import InviteLink from './InviteLink';

import { FACILITATOR } from '../../../constants/nav-routes';

const CoachInviteLink = () => {
  return (
    <>
      <Row>
        <T.H1 color="neutralMain">Invite link</T.H1>
      </Row>
      <InviteLink />
      <Row mt="3">
        <Col w={[4, 7, 7]}>
          <BasicButton variant="primary" to={FACILITATOR.DASHBOARD}>
            <T.P color="white" weight="semi">
              Return to dashboard
            </T.P>
          </BasicButton>
        </Col>
      </Row>
    </>
  );
};

export default CoachInviteLink;
