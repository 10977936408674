import theme from '../../../theme';

const WithCoach = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.24 15.3597C10.24 19.6012 13.6785 23.0397 17.92 23.0397C22.1616 23.0397 25.6 19.6012 25.6 15.3597C25.6 11.1181 22.1616 7.67969 17.92 7.67969C13.6785 7.67969 10.24 11.1181 10.24 15.3597ZM8.96 40.9273H10.24V55.0397C10.24 55.7466 10.8131 56.3197 11.52 56.3197H24.32C25.0269 56.3197 25.6 55.7466 25.6 55.0397V40.9273H26.88C29.0008 40.9273 30.72 39.2081 30.72 37.0873V31.9835C30.72 27.044 26.7157 23.0397 21.7762 23.0397H14.0638C9.12426 23.0397 5.12 27.044 5.12 31.9835V37.0873C5.12 39.2081 6.83922 40.9273 8.96 40.9273Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.3999 15.3597C38.3999 19.6012 41.8384 23.0397 46.0799 23.0397C50.3215 23.0397 53.7599 19.6012 53.7599 15.3597C53.7599 11.1181 50.3215 7.67969 46.0799 7.67969C41.8384 7.67969 38.3999 11.1181 38.3999 15.3597ZM37.1199 40.9273H38.3999V55.0397C38.3999 55.7466 38.973 56.3197 39.6799 56.3197H52.4799C53.1868 56.3197 53.7599 55.7466 53.7599 55.0397V40.9273H55.0399C57.1607 40.9273 58.8799 39.2081 58.8799 37.0873V31.9835C58.8799 27.044 54.8756 23.0397 49.9361 23.0397H42.2237C37.2842 23.0397 33.2799 27.044 33.2799 31.9835V37.0873C33.2799 39.2081 34.9991 40.9273 37.1199 40.9273Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default WithCoach;
