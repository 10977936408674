import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const Wrapper = styled.div`
  ${setMargin};
  display: flex;
  width: 100%;
  align-items: ${({ ai }) => ai || 'center'};
  justify-content: ${({ jc }) => jc || 'flex-start'};
`;

export const Paragraph = styled.div`
  ${setMargin};
  display: block;
`;
