import theme from '../../../theme';
const ArrowDown = ({ width, height, color, ...props }) => (
  <svg
    width={width || '11'}
    height={height || '16'}
    viewBox="0 0 11 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.40666 12.8351L8.68226 10.5595C9.03275 10.209 9.601 10.209 9.95149 10.5595C10.302 10.91 10.302 11.4783 9.95149 11.8288L6.1438 15.6365C5.79331 15.9869 5.22505 15.9869 4.87457 15.6365L1.06687 11.8288C0.716385 11.4783 0.716385 10.91 1.06687 10.5595C1.41736 10.209 1.98562 10.209 2.3361 10.5595L4.6117 12.8351L4.6117 1.0403C4.6117 0.544638 5.01352 0.142822 5.50918 0.142822C6.00485 0.142822 6.40666 0.544638 6.40666 1.0403L6.40666 12.8351Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);
export default ArrowDown;
