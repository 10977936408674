import theme from '../../../theme';

const Milestone = ({ width, height, color, ...props }) => (
  <svg
    width={width || '48'}
    height={height || '48'}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5196 7.67969C10.4592 7.67969 9.59961 8.5393 9.59961 9.59969V23.9997C9.59961 24.5299 10.0321 24.9597 10.5656 24.9597L27.9772 24.9596C29.5777 24.9444 30.8627 23.6428 30.8475 22.0523L30.7926 16.3106L30.8476 10.5597C30.8476 8.96911 29.5502 7.67969 27.9497 7.67969H11.5196ZM39.7445 13.4394H32.752L32.7244 16.292L32.7793 22.0337C32.8047 24.6846 30.6629 26.854 27.9925 26.8792C27.9792 26.8793 25.7065 26.8793 19.2594 26.8794V29.7339C19.2594 30.2641 19.6919 30.6939 20.2254 30.6939H39.5451C40.0212 30.6939 40.4871 30.5577 40.8881 30.3015C42.0472 29.561 42.3878 28.023 41.653 26.8624L38.7506 22.0704L41.8475 17.2785C42.1053 16.8713 42.2395 16.4081 42.2395 15.9354C42.2395 14.5592 41.1248 13.4394 39.7445 13.4394Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      d="M12.4796 40.7997C12.4796 41.595 11.8349 42.2397 11.0396 42.2397C10.2443 42.2397 9.59961 41.595 9.59961 40.7997V9.11969C9.59961 8.3244 10.2443 7.67969 11.0396 7.67969C11.8349 7.67969 12.4796 8.3244 12.4796 9.11969V40.7997Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Milestone;
