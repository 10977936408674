import * as S from './style';
import * as T from '../../Typography';

const ColorSelect = ({ options = [], handleChange, selected }) => {
  return (
    <>
      <S.Group>
        {options.map((item, index) => (
          <S.Button
            value={item.value}
            key={item.value + index}
            isActive={selected === item.value}
            onClick={() => handleChange(item.value)}
          >
            <S.ColorWrapper bgColor={item.value} />
            <S.TextWrapper>
              <T.P
                color={
                  selected === item.value ? 'neutralSurface' : 'neutralMain'
                }
                weight="normal"
              >
                {item.label}
              </T.P>
            </S.TextWrapper>
          </S.Button>
        ))}
      </S.Group>
    </>
  );
};

export default ColorSelect;
