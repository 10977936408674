import theme from '../../../theme';

const Search = ({ width, height, color, ...props }) => (
  <svg
    width={width || '28'}
    height={height || '28'}
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.63249 5.63249C2.1225 9.14248 2.1225 14.8333 5.63249 18.3433C8.88105 21.5919 14.0452 21.857 17.598 19.0109L21.2765 22.6894C22.2184 23.6313 23.6313 22.2184 22.6894 21.2765L19.0109 17.598C21.857 14.0452 21.5919 8.88105 18.3433 5.63249C14.8333 2.1225 9.14248 2.1225 5.63249 5.63249ZM7.0454 7.0454C9.77506 4.31574 14.2007 4.31574 16.9304 7.0454C19.66 9.77506 19.66 14.2007 16.9304 16.9304C14.2007 19.66 9.77506 19.66 7.0454 16.9304C4.31574 14.2007 4.31574 9.77506 7.0454 7.0454Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Search;
