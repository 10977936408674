import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import IconWithBackground from '../../../components/IconWithBackground';
import { LEARNER, COACH } from '../../../constants/nav-routes';
import * as S from './style';

const Onboarding1 = () => {
  return (
    <>
      <S.Wrapper>
        <Row>
          <Col w={[4, 10, 9]}>
            <T.H2 color="neutralMain">Progress at your own pace </T.H2>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 10, 9]} mt="3">
            <T.P color="neutralMain">
              All exercises are available anytime in the app and there are no
              time limits - learn at a time that’s best for you. As you learn,
              you will reach milestones and collect badges.
            </T.P>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 10, 9]} mt="4" jc="center">
            <IconWithBackground
              height="235px"
              icon="milestone"
              iconProps={{
                width: '130px',
                height: '130px',
                color: 'neutralMain',
              }}
            />
          </Col>
        </Row>
      </S.Wrapper>
      <S.NextButtonWrapper mt="6">
        <Col w={[4, 10, 9]}>
          <BasicButton
            variant="primary"
            icon="next"
            iconProps={{ width: '24px', height: '24px' }}
            to={LEARNER.ONBOARDING_4}
          />
        </Col>
        <Col w={[4, 10, 9]} mt="3">
          <BasicButton variant="secondary" to={COACH.LEARNERS}>
            <T.P color="white" weight="semi">
              Exit onboarding
            </T.P>
          </BasicButton>
        </Col>
      </S.NextButtonWrapper>
    </>
  );
};

export default Onboarding1;
