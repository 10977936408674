import styled from '@emotion/styled';

export const Image = styled.img`
  width: 100%;
  max-width: 100%;
  height: 295px;
  object-fit: cover;
  object-position: 80% 60%;
`;

export const ContentWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacings[5]} ${theme.spacings[8]}`};
  ${({ theme }) => theme.media.mobile} {
    padding: ${({ theme }) => `${theme.spacings[5]}`};
  }
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.white};
`;

export const ListItem = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacings[2]};
  align-items: flex-start;
`;

export const BulletPoint = styled.span`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme.colors.neutralMain};
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px;
  margin-left: 12px;
  margin-right: 12px;
  flex-shrink: 0;
`;

export const NumberPoint = styled.span`
  display: inline-block;
  margin-top: 3px;
  margin-left: 12px;
  margin-right: 6px;
  flex-shrink: 0;
`;
