import { Table } from 'antd';
import { navRoutes } from '../../../constants';
import { convertSnakeCaseToSpaces } from './../../../helpers';
import * as T from '../../../components/Typography';
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (id, row) => {
      return (
        <T.Link
          to={navRoutes.EXERCISES.SINGLE_STEP.replace(
            ':exerciseId',
            row.exerciseId
          ).replace(':stepId', id)}
          external
        >
          {id}
        </T.Link>
      );
    },
  },
  {
    title: 'Order',
    dataIndex: 'order',
    key: 'order',
  },
  {
    title: 'type',
    dataIndex: 'type',
    key: 'type',
    render: (type) => convertSnakeCaseToSpaces(type),
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    render: (id, row) => {
      return (
        <T.Link
          to={navRoutes.CMS.SINGLE_STEP.replace(':id', row.exerciseId).replace(
            ':stepId',
            id
          )}
          external
        >
          Edit
        </T.Link>
      );
    },
  },
];

const TableComponent = ({ loading, data }) => (
  <Table columns={columns} dataSource={data} loading={loading} />
);

export default TableComponent;
