import theme from '../../../theme';

const wordSmith = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill="#823B7D" />
    <circle
      cx="32"
      cy="32"
      r="30"
      stroke={theme.colors.neutralMain}
      strokeOpacity="0.1"
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.48 24.6V21.4H34.92V24.6C34.92 25.6604 34.0604 26.52 33 26.52C31.9396 26.52 31.08 25.6604 31.08 24.6V21.4H28.52V24.6C28.52 25.6604 27.6604 26.52 26.6 26.52C25.5396 26.52 24.68 25.6604 24.68 24.6V21.4H22.76C21.6996 21.4 20.84 22.2596 20.84 23.32V41.24C20.84 43.7143 22.8457 45.72 25.32 45.72H40.68C43.1542 45.72 45.16 43.7143 45.16 41.24V23.32C45.16 22.2596 44.3004 21.4 43.24 21.4H41.32V24.6C41.32 25.6604 40.4604 26.52 39.4 26.52C38.3396 26.52 37.48 25.6604 37.48 24.6ZM39.4 35.48H26.6C26.2465 35.48 25.96 35.1935 25.96 34.84C25.96 34.4865 26.2465 34.2 26.6 34.2H39.4C39.7534 34.2 40.04 34.4865 40.04 34.84C40.04 35.1935 39.7534 35.48 39.4 35.48ZM26.6 30.36H39.4C39.7534 30.36 40.04 30.0735 40.04 29.72C40.04 29.3666 39.7534 29.08 39.4 29.08H26.6C26.2465 29.08 25.96 29.3666 25.96 29.72C25.96 30.0735 26.2465 30.36 26.6 30.36ZM36.84 40.6H26.6C26.2465 40.6 25.96 40.3134 25.96 39.96C25.96 39.6065 26.2465 39.32 26.6 39.32H36.84C37.1934 39.32 37.48 39.6065 37.48 39.96C37.48 40.3134 37.1934 40.6 36.84 40.6Z"
      fill={theme.colors.neutralSurface}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.96 19.48C25.96 19.1265 26.2465 18.84 26.6 18.84C26.9534 18.84 27.24 19.1265 27.24 19.48V24.6C27.24 24.9534 26.9534 25.24 26.6 25.24C26.2465 25.24 25.96 24.9534 25.96 24.6V19.48ZM32.36 19.48C32.36 19.1265 32.6466 18.84 33 18.84C33.3535 18.84 33.64 19.1265 33.64 19.48V24.6C33.64 24.9534 33.3535 25.24 33 25.24C32.6466 25.24 32.36 24.9534 32.36 24.6V19.48ZM39.4 18.84C39.0466 18.84 38.76 19.1265 38.76 19.48V24.6C38.76 24.9534 39.0466 25.24 39.4 25.24C39.7535 25.24 40.04 24.9534 40.04 24.6V19.48C40.04 19.1265 39.7535 18.84 39.4 18.84Z"
      fill={theme.colors.neutralSurface}
    />
  </svg>
);
export default wordSmith;
