import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Form = styled.form`
  max-width: 430px;
  flex: 1;
`;
export const Preview = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto 0;
  height: fit-content;
  min-height: 85vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const PreviewItem = styled.div`
  width: 100%;
  border: 1px blue dashed;
  overflow: hidden;
`;
