import Modal from './../../../components/Modal';
import * as T from './../../../components/Typography';
import { Row, Col } from './../../../components/Grid';
import { BasicButton } from './../../../components/Button';
import { navRoutes } from './../../../constants';
import { useNavigate } from 'react-router-dom';
const SuccessModal = ({
  isModalVisible,
  setIsModalVisible,
  exerciseId,
  stepId,
  mode,
  onConfirmDelete,
}) => {
  const navigate = useNavigate();

  if (mode === 'update')
    return (
      <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
        <Row>
          <Col w={[4, 12, 12]} jc="center">
            <T.P size="med" weight="bold" color="white">
              Step has been updated successfully!
            </T.P>
          </Col>
          <>
            <Col w={[4, 12, 12]} mt={4}>
              <BasicButton
                to={navRoutes.EXERCISES.SINGLE_STEP.replace(
                  ':exerciseId',
                  exerciseId
                ).replace(':stepId', stepId)}
                variant="secondary"
                target="_blank"
                external
              >
                View step
              </BasicButton>
            </Col>

            <Col w={[4, 12, 12]} mt={2}>
              <BasicButton
                variant="tertiary"
                onClick={() => {
                  setIsModalVisible(false);
                }}
              >
                Close
              </BasicButton>
            </Col>
          </>
        </Row>
      </Modal>
    );
  if (mode === 'delete')
    return (
      <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
        <Row>
          <Col w={[4, 12, 12]} jc="center">
            <T.P size="med" weight="bold" color="white">
              Step has been deleted successfully!
            </T.P>
          </Col>
          <>
            <Col w={[4, 12, 12]} mt={4}>
              <BasicButton
                to={navRoutes.CMS.VIEW_EXERCISE_STEPS.replace(
                  ':id',
                  exerciseId
                )}
                variant="secondary"
                target="_blank"
                external
              >
                View step
              </BasicButton>
            </Col>

            <Col w={[4, 12, 12]} mt={2}>
              <BasicButton
                variant="tertiary"
                onClick={() => {
                  setIsModalVisible(false);
                }}
              >
                Close
              </BasicButton>
            </Col>
          </>
        </Row>
      </Modal>
    );
  if (mode === 'confirmDelete')
    return (
      <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
        <Row>
          <Col w={[4, 12, 12]} jc="center">
            <T.P size="med" weight="bold" color="white">
              Are you sure you want to delete this step?
            </T.P>
          </Col>
          <>
            <Col w={[4, 12, 12]} mt={4}>
              <BasicButton
                onClick={() => {
                  setIsModalVisible(false);
                  onConfirmDelete();
                }}
                variant="secondary"
                target="_blank"
                external
              >
                Delete
              </BasicButton>
            </Col>

            <Col w={[4, 12, 12]} mt={2}>
              <BasicButton
                variant="tertiary"
                onClick={() => {
                  setIsModalVisible(false);
                }}
              >
                Close
              </BasicButton>
            </Col>
          </>
        </Row>
      </Modal>
    );

  return (
    <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
      <Row>
        <Col w={[4, 12, 12]} jc="center">
          <T.P size="med" weight="bold" color="white">
            Step has been created successfully!
          </T.P>
        </Col>
        <>
          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton
              to={navRoutes.EXERCISES.SINGLE_STEP.replace(
                ':exerciseId',
                exerciseId
              ).replace(':stepId', stepId)}
              variant="tertiary"
              target="_blank"
              external
            >
              View step
            </BasicButton>
          </Col>

          <Col w={[4, 12, 12]} mt={2}>
            <BasicButton
              variant="tertiary"
              onClick={() => {
                navigate(
                  navRoutes.CMS.SINGLE_STEP.replace(':id', exerciseId).replace(
                    ':stepId',
                    'new'
                  )
                );
                setIsModalVisible(false);
              }}
            >
              Create new one
            </BasicButton>
          </Col>
          <Col w={[4, 12, 12]} mt={2}>
            <BasicButton
              variant="tertiary"
              onClick={() => {
                navigate(
                  navRoutes.CMS.SINGLE_STEP.replace(':id', exerciseId).replace(
                    ':stepId',
                    stepId
                  ),
                  { replace: true }
                );

                setIsModalVisible(false);
              }}
            >
              Close
            </BasicButton>
          </Col>
        </>
      </Row>
    </Modal>
  );
};

export default SuccessModal;
