import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Learners } from '../../../api-calls';

import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';

import { GENERAL, LEARNER, COACH } from '../../../constants/nav-routes';
import { useAuth } from '../../../context/auth';
import * as S from './style';

const LearnerId = () => {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const [learnerUniqueId, setLearnerUniqueId] = useState('');
  const { userId } = useParams();

  useEffect(() => {
    const getLearner = async () => {
      const { data, error } = await Learners.getLearnerUniqueId({
        learnerUserId: userId,
      });
      if (error) {
        return navigate(GENERAL.NOT_FOUND);
      }
      setLearnerUniqueId(data.learnerUniqueId);
      setUser({ ...user, selectedLearnerId: data.id });
    };
    getLearner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  return (
    <>
      <S.Wrapper>
        <Row>
          <Col w={[4, 10, 9]}>
            <T.H2 color="neutralMain">
              This is your new Learner ID for when you want to log in. Please
              keep it safe.
            </T.H2>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 10, 9]} mt="6" jc="center">
            <S.Circle>
              <T.H1 color="white">{learnerUniqueId}</T.H1>
            </S.Circle>
          </Col>
        </Row>{' '}
      </S.Wrapper>
      <S.NextButtonWrapper mt="10">
        <Col w={[4, 10, 9]}>
          <BasicButton
            variant="primary"
            icon="next"
            iconProps={{ width: '24px', height: '24px' }}
            to={LEARNER.ONBOARDING_1}
          />
        </Col>
        <Col w={[4, 10, 9]} mt="3">
          <BasicButton variant="secondary" to={COACH.LEARNERS}>
            <T.P color="white" weight="semi">
              Exit onboarding
            </T.P>
          </BasicButton>
        </Col>
      </S.NextButtonWrapper>
    </>
  );
};

export default LearnerId;
