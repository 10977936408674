import * as S from './style';
import { useRef, useEffect } from 'react';
import { exerciseTypes } from './../../../../../constants';
import Typing from './Typing';
import MultipleChoice from './MultipleChoice';
import { useAuth } from '../../../../../context/auth';
import Reorder from './Reorder';
import ListenAndRepeat from './ListenAndRepeat';
import * as T from '../../../../../components/Typography';

const Switch = ({ type, ...props }) => {
  switch (type) {
    case exerciseTypes.TYPING:
      return <Typing {...props} />;

    case exerciseTypes.MULTIPLE_CHOICE:
      return <MultipleChoice {...props} />;

    case exerciseTypes.REORDER:
      return <Reorder {...props} />;

    case exerciseTypes.COMPREHENSION_LISTEN_AND_RESPOND:
      return <ListenAndRepeat {...props} />;
    default:
      return 'DEFAULT QUESTION';
  }
};

const Question = ({ exKey, type, isCheck, subTitle, ...props }) => {
  const { user } = useAuth();
  const ref = useRef();

  useEffect(() => {
    if (isCheck) ref.current.scrollIntoView({ behavior: 'smooth' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (type === exerciseTypes.SELECT_RECIPES) {
    return null;
  }

  return (
    <S.QuestionContainer
      ref={ref}
      bgColor={['M5_P164&166', 'M5_P174'].includes(exKey) && 'white'}
      overlayColor={user.overlayColor}
    >
      {' '}
      {isCheck && (
        <S.TitleWrapper>
          {' '}
          <T.H2 mt="4" mlM="6">
            {props?.data?.subTitle}
          </T.H2>
        </S.TitleWrapper>
      )}
      {type === exerciseTypes.REORDER ? (
        <S.QuestionWrapperFull>
          <Switch {...props} type={type} />
        </S.QuestionWrapperFull>
      ) : (
        <S.QuestionWrapper
          margin={exKey === 'M5_P182' ? '12px' : null}
          isCheck={isCheck}
        >
          <S.Rounded>
            <Switch {...props} type={type} />
          </S.Rounded>
        </S.QuestionWrapper>
      )}
    </S.QuestionContainer>
  );
};

export default Question;
