import styled from '@emotion/styled';

export const Wrapper = styled.article`
  display: flex;
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : 'transparent'};
`;

export const Content = styled.main`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: ${({ theme: { spacings } }) => spacings[8]};

  ${({ theme }) => theme.media.tablet} {
    padding-bottom: ${({ theme: { spacings } }) => spacings[7]};
  }

  ${({ theme }) => theme.media.mobile} {
    padding-bottom: ${({ theme: { spacings } }) => spacings[6]};
  }
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: ${({ theme, headerType }) =>
    `calc(100vh - ${
      headerType === 'progress'
        ? theme.constants.layout.header.progressHeight
        : theme.constants.layout.header.height
    } - ${theme.spacings[8]})`};

  ${({ theme }) => theme.media.tablet} {
    min-height: ${({ theme, headerType }) =>
      `calc(100vh - ${
        headerType === 'progress'
          ? theme.constants.layout.header.progressHeight
          : theme.constants.layout.header.height
      } - ${theme.spacings[7]})`};
  }

  ${({ theme }) => theme.media.mobile} {
    min-height: ${({ theme, headerType }) =>
      `calc(100vh - ${
        headerType === 'progress'
          ? theme.constants.layout.header.progressHeight
          : theme.constants.layout.header.height
      } - ${theme.spacings[6]})`};
  }
`;
