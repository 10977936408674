import styled from '@emotion/styled';

export const ButtonWrapper = styled.div`
  max-width: 300px;
  margin: 0 auto;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  margin-bottom: ${({ theme }) => theme.spacings[4]};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
`;
