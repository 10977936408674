import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  flex: 1;
  margin-top: ${({ theme }) => theme.spacings[2]};
  display: ${({ display }) => display};
`;

export const RotateWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: ${({ theme }) => theme.spacings[5]};
  flex-direction: column;
  flex: 1;
  margin-top: ${({ theme }) => theme.spacings[2]};
  display: ${({ display }) => display};
`;

export const Row = styled.div`
  width: 100%;
  min-width: fit-content;
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacings[5]};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  margin-top: ${({ theme }) => theme.spacings[5]};
  padding: ${({ theme }) => `${theme.spacings[6]} ${theme.spacings[1]}`};
  background: ${({ theme }) => theme.colors.neutralLight};
`;

export const ButtonWrapper = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  flex: ${({ flex }) => flex || 0};

  display: flex;
  align-items: end;
  flex: 1;
`;
