import { BasicButton } from '../../../components/Button';
import { useEffect, useState } from 'react';

import { Exercises } from '../../../api-calls';
import { navRoutes } from '../../../constants';
import Table from './Table';
import * as S from './style';

const ViewMilestones = () => {
  const [exercises, setExercises] = useState([]);

  const [loading, setLoading] = useState(true);

  const getExercises = async () => {
    const { data } = await Exercises.getExercises({ cms: true });

    if (data) {
      setExercises(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getExercises();
  }, []);

  return (
    <S.Wrapper>
      <Table data={exercises} loading={loading} />

      <div style={{ maxWidth: '300px' }}>
        <BasicButton
          to={navRoutes.CMS.CREATE_EXERCISES}
          mt={5}
          loading={loading}
        >
          Create new exercise
        </BasicButton>
      </div>
    </S.Wrapper>
  );
};

export default ViewMilestones;
