import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { GroupCheckbox } from '../../../../components/Inputs';
import { Col, Row } from '../../../../components/Grid';
import { BasicButton } from '../../../../components/Button';
import * as T from '../../../../components/Typography';
import Video from '../../../../components/Video';
import { navRoutes } from '../../../../constants';

import * as S from '../style';
import { useAuth } from '../../../../context/auth';

const checklist = [
  {
    label: (
      <T.P m="0" ml="1">
        Watch the <b>video</b> below on how to use the tool
      </T.P>
    ),
    checked: true,
    component: (
      <S.VideoCard>
        <Video
          width="100%"
          height="236px"
          src={navRoutes.EXTERNAL.HOW_TO_USE_VIDEO}
        />
      </S.VideoCard>
    ),
  },
  {
    label: (
      <T.P m="0" ml="1">
        Watch the <b>onboarding demo</b> to understand how to onboard a learner
        and familarise yourself with the app
      </T.P>
    ),
    checked: true,
    component: (
      <S.VideoCard>
        <Video
          width="100%"
          height="236px"
          src={navRoutes.EXTERNAL.ONBOARDING_LEARNERS_VIDEO}
        />
      </S.VideoCard>
    ),
  },
  {
    label: (
      <T.P m="0" ml="1">
        Complete your online training session (you will need your Shannon Trust
        Moodle account to do this).
        <T.Link
          underline
          color="neutralMain"
          external
          target="_blank"
          to={navRoutes.EXTERNAL.MOODLE}
        >
          Click here to book your slot if you would like a refresher
        </T.Link>
      </T.P>
    ),
    checked: true,
    font: 'regular',
  },
];

const TrainingChecklist = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.onboarded) {
      navigate(navRoutes.COACH.DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row>
        <Col w={[4, 8, 8]} dir="column" ai="flex-start">
          <T.H2 color="neutralMain">Training checklist</T.H2>

          <T.P mt="3" color="neutralMain">
            Here’s a quick refresher of the key essentials you need to coach
            learners...
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt="6" jc="center">
          <GroupCheckbox
            avoidClick
            state={checklist}
            checkedBgColor="neutralMain"
          />
        </Col>
      </Row>
      <Row mt="6" ai="end" style={{ flex: 1 }}>
        <Col w={[4, 8, 6]}>
          <BasicButton to={navRoutes.COACH.DASHBOARD}>
            <T.P color="white" weight="semi">
              Return to dashboard
            </T.P>
          </BasicButton>
        </Col>
      </Row>
    </>
  );
};
export default TrainingChecklist;
