import styled from '@emotion/styled';

export const Image = styled.img`
  width: 100% !important;
  max-width: 100% !important;
  height: 295px;
  object-fit: cover;
  object-position: 80% 30%;
`;
export const InnerImage = styled.img`
  width: 60% !important;
  max-width: 100% !important;
  ${({ theme }) => theme.media.mobile} {
    width: 100% !important;
  }
`;
export const InnerWrapper = styled.div`
  display: flex;

  justify-content: center;
  padding: ${({ theme }) => `${theme.spacings[3]} ${theme.spacings[7]}`};
  ${({ theme }) => theme.media.mobile} {
    padding: ${({ theme }) => `${theme.spacings[4]}`};
    padding-top: ${({ theme }) => `${theme.spacings[2]}`};
  }
  margin-bottom: 12px;
`;
export const ContentWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacings[5]} ${theme.spacings[8]}`};
  ${({ theme }) => theme.media.mobile} {
    padding: ${({ theme }) => `${theme.spacings[5]}`};
  }
`;
