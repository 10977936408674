import styled from '@emotion/styled';
import { setMargin } from '../../helpers';

export const TipWrapper = styled.div`
  ${setMargin}
  padding: ${({ theme, p }) => p || theme.spacings[1]};
  background-color: ${({ theme }) => theme.colors.neutralLight};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  div {
    display: inline;
  }
`;

export const Content = styled.div`
  div {
    color: ${({ theme }) => theme.colors.primaryMain};
  }
`;
