import { useNavigate } from 'react-router-dom';
import { Col, Row } from '../../../components/Grid';
import { COACH } from '../../../constants/nav-routes';
import { BasicButton } from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import * as T from '../../../components/Typography';

const text = {
  p1: `Your and our learners’ safety is very important to us. You have access to training materials on what the rules around confidentiality and disclosure are on your dashboard.`,
  p2: `You can get in touch with your Programme Manager at any time. See their contact details under **Manager details** at the bottom of your dashboard.`,
};

const Onboarding4 = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(COACH.ONBOARDING_5);
  };
  return (
    <>
      <Row>
        <Col w={[4, 8, 8]}>
          <T.H2 color="neutralMain">Safeguarding and boundaries</T.H2>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt="3">
          <Markdown
            text={text.p1}
            customStyles={{
              p: {
                color: 'neutralMain',
                size: 'regular',
              },
            }}
          />
        </Col>
        <Col w={[4, 8, 8]} mt="3">
          <Markdown
            text={text.p2}
            customStyles={{
              p: {
                color: 'neutralMain',
                size: 'regular',
              },
            }}
          />
        </Col>
      </Row>

      <Row mt="6" ai="end" style={{ flex: 1 }}>
        <Col w={[4, 8, 8]}>
          <BasicButton
            maxWidth="426px"
            variant="primary"
            handleClick={handleClick}
          >
            Continue
          </BasicButton>
        </Col>
      </Row>
    </>
  );
};

export default Onboarding4;
