import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import * as S from './style';
import { LEARNER, COACH } from '../../../constants/nav-routes';
import { BigButton } from '../../../components/Button';

const LearningByYourselfOrWithCoach = () => {
  return (
    <>
      <S.Wrapper>
        <Row>
          <Col w={[4, 9, 9]}>
            <T.H2 color="neutralMain">
              You can complete exercises by yourself or with your coach
            </T.H2>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <BigButton variant={'byYourSelf'} mb={5} mt={5} card />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <BigButton variant={'withCoach'} card />
          </Col>
        </Row>
      </S.Wrapper>
      <S.NextButtonWrapper mt="6">
        <Col w={[4, 9, 9]}>
          <BasicButton
            variant="primary"
            icon="next"
            iconProps={{ width: '24px', height: '24px' }}
            to={LEARNER.PROGRESS_REPORT_COMPLETED}
          />
        </Col>
        <Col w={[4, 9, 9]} mt="3">
          <BasicButton variant="secondary" to={COACH.LEARNERS}>
            <T.P color="white" weight="semi">
              Exit onboarding
            </T.P>
          </BasicButton>
        </Col>
      </S.NextButtonWrapper>
    </>
  );
};

export default LearningByYourselfOrWithCoach;
