import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import Image from '../../../components/Image';
import * as T from '../../../components/Typography';
import { LEARNER, COACH } from '../../../constants/nav-routes';
import * as S from './style';
import { useParams } from 'react-router-dom';

const SetupProcess = () => {
  const { id } = useParams();
  return (
    <>
      <S.Wrapper>
        <Row>
          <Col w={[4, 10, 9]}>
            <T.H2 color="neutralMain">Introduction to the setup process</T.H2>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 10, 9]} mt="4">
            <T.P color="neutralMain">
              We will now go through the onboarding/setup process. It will take
              you through each bit of the tool and how Turning Pages will work,
              as well as some initial questions for the learner to answer.
              Please make sure you read this introduction and all the following
              pages out to the learner so you can complete this together.
            </T.P>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 10, 9]} mt="4">
            <Image image="bookOnWindow2" />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 10, 9]} mt="4">
            <S.GeneralTip text="This final bit will take around 20 minutes" />
          </Col>
        </Row>
      </S.Wrapper>
      <S.NextButtonWrapper mt="6">
        <Col w={[4, 10, 9]}>
          <BasicButton
            variant="primary"
            icon="next"
            iconProps={{ width: '24px', height: '24px' }}
            to={LEARNER.LEARNER_ID.replace(':userId', id)}
          />
        </Col>
        <Col w={[4, 10, 9]} mt="3">
          <BasicButton variant="secondary" to={COACH.LEARNERS}>
            <T.P color="white" weight="semi">
              Exit onboarding
            </T.P>
          </BasicButton>
        </Col>
      </S.NextButtonWrapper>
    </>
  );
};

export default SetupProcess;
