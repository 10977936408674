import theme from '../../../theme';

const Smile = ({ width, height, color, ...props }) => (
  <svg
    width={width || '32'}
    height={height || '32'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.67957 13.4403V11.5264L3.32176 11.5276C2.82813 12.9268 2.55957 14.4322 2.55957 16.0003C2.55957 23.423 8.57686 29.4403 15.9996 29.4403C23.4223 29.4403 29.4396 23.423 29.4396 16.0003C29.4396 14.4295 29.1701 12.9217 28.6749 11.5205L24.3196 11.5217V13.4403C24.3196 15.2076 22.8869 16.6403 21.1196 16.6403C19.3523 16.6403 17.9196 15.2076 17.9196 13.4403V11.5235L14.0796 11.5246V13.4403C14.0796 15.2076 12.6469 16.6403 10.8796 16.6403C9.11226 16.6403 7.67957 15.2076 7.67957 13.4403ZM21.452 20.3963C18.5296 22.2196 13.5438 22.2088 10.5511 20.4012C10.2477 20.2179 10.1489 19.8211 10.3304 19.5148C10.5118 19.2085 10.9048 19.1088 11.2081 19.292C13.7945 20.8541 18.2675 20.8639 20.7792 19.2968C21.0799 19.1092 21.4743 19.2033 21.6601 19.5069C21.8459 19.8105 21.7527 20.2087 21.452 20.3963Z"
      fill={theme.colors.primaryMain}
    />
    <path
      d="M3.84961 10.2467C6.0052 5.70216 10.6354 2.55957 15.9996 2.55957C21.3612 2.55957 25.9894 5.69904 28.1464 10.2399L3.84961 10.2467H3.84961Z"
      fill={color || theme.colors.primaryMain}
    />
  </svg>
);

export default Smile;
