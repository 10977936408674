import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonWrapper = styled.div`
  flex: ${({ flex }) => flex};
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;
