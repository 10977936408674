import styled from '@emotion/styled';
import * as T from '../../../../components/Typography';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  max-width: 312px;
  margin: 0 auto;
  flex-direction: column;
  flex: 1;
`;

export const Grid = styled.div`
  padding: ${({ theme }) => theme.spacings[5]} 0;
  background: ${({ theme }) => theme.colors.neutralLight};
  position: relative;
  margin-top: ${({ theme }) => theme.spacings[5]};

  :before {
    position: absolute;
    background: ${({ theme }) => theme.colors.neutralLight};
    height: 100%;
    width: 200vw;
    content: '';
    left: -50vw;
    right: 0;
    top: 0;
    z-index: -1;
  }
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  align-items: flex-start;
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OptionsGridWrapper = styled.div`
  flex: 1;
  margin: 0 -8px;
  padding: ${({ theme }) => `${theme.spacings[5]} 0 ${theme.spacings[6]}`};
`;

export const OptionsFlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;
export const OptionRow = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacings[2]};
`;

export const SentenceWrapper = styled(T.P)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const SingleWord = styled.span`
  display: inline-block;
  line-height: 45px;
`;
