import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 60px 0 60px;

  ${({ theme }) => theme.media.mobile} {
    padding: ${({ theme }) => `${theme.spacings[0]} ${theme.spacings[4]}`};
  }
`;

export const ContentWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings[5]};
  display: flex;
  flex-direction: column;
`;

export const OuterWrapper = styled.div`
  width: 100%;
  padding: 0 24px 24px 24px;
  background-color: ${({ bg, theme }) => bg && theme.colors[bg]};
  ${({ theme }) => theme.media.mobile} {
    padding: 0 24px 24px 24px;
  }
`;

export const MdContentWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacings[5]};
  font-size: 1rem;
  align-self: center;
  cursor: pointer;
  user-select: none;
`;

export const Span = styled.span`
  color: ${({ theme, selected }) => selected && theme.colors.white};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.primaryMain : 'none'};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'regular')};
  padding: ${({ selected, isSmall }) => {
    if (!isSmall && !selected) return '0 !important';
    return isSmall && !selected ? '0 !important' : '0 0.15rem';
  }};
  margin: ${({ isSmall, selected }) => {
    if (!isSmall) return '0 !important';
    return isSmall && !selected ? '0 !important' : '0 -0.15rem';
  }};
  line-height: 30px;
  text-decoration: ${({ exampleStep }) => (exampleStep ? 'underline' : 'none')};
  display: ${({ isSmall }) => (isSmall ? 'inline' : 'inline-block')};
`;

export const TextAudioWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  margin-bottom: ${({ theme, mb }) => theme.spacings[mb || 0]};
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'center'};
`;

export const TableWrapper = styled.div`
  max-width: 480px;
  width: 100%;
  padding: 2rem 3.75rem 2rem 3.75rem;
  background-color: ${({ bg, theme }) => bg && theme.colors[bg]};
  align-self: center;

  ${({ theme }) => theme.media.mobile} {
    padding: 2rem 2rem 2rem 2rem;
  }
`;

export const Row = styled.div`
  display: flex;
  border-bottom: ${({ titleRow, theme }) =>
    titleRow && `1px solid ${theme.colors.neutral40}`};
  font-weight: ${({ titleRow }) => titleRow && 'bold'};
`;

export const Col = styled.div`
  padding: ${({ theme }) => theme.spacings[2]};
  margin-left: ${({ right }) => (right ? '-4.5rem' : '0.5rem')};
  flex: 50%;
  font-size: 1rem;
  font-weight: ${({ weight }) => (weight ? weight : 'regular')};
`;
