import * as S from './style';
import * as T from '../../../../../components/Typography';
import { Row, Col } from '../../../../../components/Grid';
import { list1, list2 } from './data';

const JobProfile = () => {
  return (
    <S.Wrapper>
      <Row mb="4">
        <Col w={[3, 4, 5]}>
          <T.H2 size="med">Job Profile Fitness Instructor</T.H2>
        </Col>
      </Row>
      <Row mb="4">
        <Col w={[4, 8, 8]} dir="column" ai="flex-start">
          {list1.map((item) => (
            <S.ListItem key={item.key}>
              <S.BulletPoint /> <T.P>{item.text}</T.P>
            </S.ListItem>
          ))}
        </Col>
      </Row>
      <Row mb="4">
        <Col w={[4, 8, 8]} dir="column" ai="flex-start">
          <T.P weight="bold">The work:</T.P>
          <T.P>
            You may work in gyms, fitness clubs, leisure centres, hotels or
            holiday resorts. You may work with groups or individuals.
          </T.P>
        </Col>
      </Row>
      <Row mb="4">
        <Col w={[4, 8, 8]} dir="column" ai="flex-start">
          <T.P>Your job may include:</T.P>
          {list2.map((item) => (
            <S.ListItem key={item.key}>
              <S.BulletPoint /> <T.P>{item.text}</T.P>
            </S.ListItem>
          ))}
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} dir="column" ai="flex-start">
          <T.P weight="bold">Qualifications:</T.P>
          <T.P>
            There are many opportunities in the fitness industry to get
            qualifications. You can train on the job or study at college.
          </T.P>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default JobProfile;
