import { BasicButton } from './../../../components/Button';
import { useEffect, useState } from 'react';

import { Milestones } from './../../../api-calls';
import { message } from 'antd';
import { BasicInput } from '../../../components/Inputs';
import * as T from '../../../components/Typography';
import Table from './Table';
import * as S from './style';

const ViewMilestones = () => {
  const [milestones, setMilestones] = useState([]);
  const [error, setError] = useState('');

  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const getMilestones = async () => {
    const { data } = await Milestones.getMilestones();

    if (data) {
      setMilestones(data);
    }
  };

  useEffect(() => {
    getMilestones();
  }, []);

  const createMilestone = async () => {
    setLoading(true);
    const { error } = await Milestones.createMilestone({ title });

    if (!error) {
      getMilestones();
      setError('');
      setTitle('');
      message.success('Milestone created successfully');
    } else {
      setError(error?.message);
    }

    setLoading(false);
  };

  return (
    <S.Wrapper>
      <Table data={milestones} loading={loading} />
      <div>
        <BasicInput
          value={title}
          handleChange={setTitle}
          m={{ mt: 5 }}
          label="Add new"
          placeholder="Enter milestone's title"
        />
        {
          <T.P color="error" mt={5}>
            {error}
          </T.P>
        }
        <BasicButton
          onClick={createMilestone}
          loading={loading}
          disabled={!title}
        >
          Create new one
        </BasicButton>
      </div>
    </S.Wrapper>
  );
};

export default ViewMilestones;
