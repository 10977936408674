import theme from '../../../theme';

const Like = ({ width, height, color, ...props }) => (
  <svg
    width={width || '48'}
    height={height || '48'}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.2402 17.1762C18.3247 14.1881 19.6258 10.7592 20.1478 6.87746C20.1975 6.50768 20.457 6.2002 20.8131 6.08901C24.4028 4.96837 26.4436 6.74915 26.4099 10.8097C26.4033 11.5979 26.3673 12.2985 26.2905 13.37C26.119 15.7634 26.1327 16.4165 26.3983 16.856C26.6537 17.2785 27.4826 17.4227 29.4431 17.0472C30.0617 16.9287 30.6823 16.7944 31.3697 16.6331C31.6731 16.5619 33.8587 16.024 34.4173 15.9023C35.483 15.6702 36.2477 15.5719 36.92 15.6135C38.7415 15.7262 39.7081 16.9093 39.8752 19.1759C40.1693 23.1676 39.2604 31.1854 38.3537 34.1387C37.4272 37.1566 35.8114 38.2957 31.9328 38.3645C29.4391 38.4088 25.6037 38.4088 20.4087 38.3644C19.0826 38.3333 17.8808 38.0742 16.8118 37.5899C17.1119 36.9631 17.2799 36.2611 17.2799 35.5198V20.1598C17.2799 19.0322 16.8911 17.9954 16.2402 17.1762Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      d="M10.5597 17.2803C8.96911 17.2803 7.67969 18.5697 7.67969 20.1603V35.5203C7.67969 37.1109 8.96911 38.4003 10.5597 38.4003H12.4797C14.0703 38.4003 15.3597 37.1109 15.3597 35.5203V20.1603C15.3597 18.5697 14.0703 17.2803 12.4797 17.2803H10.5597Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Like;
