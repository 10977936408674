const UserWithArrow = ({ width, height, color, ...props }) => (
  <svg
    width={width || '46'}
    height={height || '46'}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.19969 13.8C9.19969 18.3729 12.9068 22.08 17.4797 22.08C22.0526 22.08 25.7597 18.3729 25.7597 13.8C25.7597 9.2271 22.0526 5.52002 17.4797 5.52002C12.9068 5.52002 9.19969 9.2271 9.19969 13.8ZM31.2797 37.72C31.2797 30.0985 25.1012 23.92 17.4797 23.92C9.85816 23.92 3.67969 30.0985 3.67969 37.72V41.4C3.67969 41.9081 4.09159 42.32 4.59969 42.32H30.3597C30.8678 42.32 31.2797 41.9081 31.2797 41.4V37.72Z"
      fill={color}
    />
    <path
      d="M34.9071 18.7588C34.3999 18.2558 34.3999 17.4403 34.9071 16.9373C35.4143 16.4343 36.2367 16.4343 36.7439 16.9373L41.9392 22.0893C42.1809 22.329 42.3196 22.6542 42.3196 23.0001C42.3196 23.3459 42.1809 23.6711 41.9392 23.9108L36.7439 29.0628C36.2367 29.5658 35.4143 29.5658 34.9071 29.0628C34.3999 28.5598 34.3999 27.7443 34.9071 27.2413L37.8851 24.2881H28.8984C28.1811 24.2881 27.5996 23.7114 27.5996 23.0001C27.5996 22.2887 28.1811 21.7121 28.8984 21.7121H37.8851L34.9071 18.7588Z"
      fill={color}
    />
  </svg>
);

export default UserWithArrow;
