import didburyNews from '../../../../../components/assets/DidburyNews.png';
import opticians from '../../../../../components/assets/opticians.png';
import paperContent from '../../../../../components/assets/PaperContent.png';
import * as S from './style';
import * as T from '../../../../../components/Typography';

const ProgressCheck15 = ({ type, data, handleNext, disabled, ...props }) => {
  return (
    <>
      <S.ContentWrapper>
        <T.H3 size="med" color="neutralMain" mb={4} fontFamily="garamond">
          Didbury Daily News
        </T.H3>
        <T.P fontFamily="garamond">Friday January 9th 2015</T.P>
        <T.P fontFamily="garamond">www.didburynews.com</T.P>
        <T.P mb="4" fontFamily="garamond">
          Price 50p
        </T.P>
        <T.P weight="bold" mr="1" fontFamily="garamond">
          {' '}
          Brave by name and brave by natures...
        </T.P>
        <T.P fontFamily="garamond">
          When Tom Devlin fell in the canal on his way to school last Tuesday he
          thought he would drown. He cannot swim and the filthy water of the
          Dorret Canal soon filled his lungs but luckily for him a hero was just
          around the corner. Arshan Nadeem arrived just in the nick of time to
          save Tom: ‘I didn’t have time to think,’ said Arshan, ‘I was on my way
          to work when I saw this lad struggling in the water, I jumped in
          straight away. I knew I had to help.’ Arshan was praised by the fire
          service fire service for his prompt action. Fire Officer Ted Mills
          said that jumping into the cold, filthy water was a very brave act.
          Arshan’s mother told us: ‘The name Arshan means brave and that’s just
          what he is!’
        </T.P>
      </S.ContentWrapper>
      <S.InnerWrapper>
        <S.InnerImage src={opticians} alt="opticians" />
      </S.InnerWrapper>
      <S.Image src={didburyNews} alt="old people" />
      <S.ContentWrapper>
        <T.P weight="bold" mr="1" fontFamily="garamond">
          Platinum bride!
        </T.P>
        <T.P fontFamily="garamond" mb="7">
          Local couple Hetty Ford (92) and her devoted husband Albert, who is a
          spritely 95, celebrate seventy years of married bliss this week with a
          platinum anniversary party. Mr Ford said that when he married Hetty
          she was a platinum blonde but now she is a true platinum grey just
          like the real thing.
        </T.P>
        <T.P fontFamily="garamond">Anti-Fracking Protest</T.P>
        <T.P fontFamily="garamond">
          News of plans to start fracking for shale gas in Didbury Health has
          shocked locals residents who are planning a protest march to the Town
          Hall nest Thursday.
        </T.P>
        <T.P fontFamily="garamond" mb="4">
          more on page 2...
        </T.P>
        <S.InnerWrapper>
          <S.InnerImage src={paperContent} alt="paper content" />
        </S.InnerWrapper>
      </S.ContentWrapper>
    </>
  );
};

export default ProgressCheck15;
