import theme from '../../../theme';
const Book = ({ width, height, color, ...props }) => (
  <svg
    width={width || '48'}
    height={height || '48'}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2779 3.83984H37.4379C39.0285 3.83984 40.3179 5.12926 40.3179 6.71984V37.4398C40.3179 41.1512 37.3093 44.1598 33.5979 44.1598H17.2779V3.83984ZM15.3577 3.84067V44.1607H14.3977C10.6864 44.1607 7.67773 41.152 7.67773 37.4407V6.72067C7.67773 5.13009 8.96715 3.84067 10.5577 3.84067H15.3577ZM21.1158 10.5602C21.1158 10.03 21.5456 9.6002 22.0758 9.6002H33.5958C34.126 9.6002 34.5558 10.03 34.5558 10.5602V18.2402C34.5558 18.7704 34.126 19.2002 33.5958 19.2002H22.0758C21.5456 19.2002 21.1158 18.7704 21.1158 18.2402V10.5602Z"
      fill={color || 'neutralMain'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0391 17.2805V11.5205H32.6391V17.2805H23.0391Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);
export default Book;
