import styled from '@emotion/styled';
import { Button as AntdButton } from 'antd';
import { setMargin } from './../../../../helpers';
export const Button = styled(AntdButton)`
  ${setMargin}
  max-width: 150px;
  display: block;
  display: flex;
  align-items: center;
`;
