import styled from '@emotion/styled';

export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral40};
`;

export const Wrapper = styled.div`
  padding: 24px 38px 0 24px;
  ${({ theme }) => theme.media.tablet} {
    padding: ${({ theme }) => theme.spacings[4]};
  }
`;

export const Side = styled.div`
  min-width: 100px;
  max-width: 300px;
  margin-right: ${({ theme }) => theme.spacings[4]};
`;
