import theme from '../../../theme';

const CurvedDashedLineBackground = ({ width, height, color, ...props }) => (
  <svg
    width={width || '453'}
    height={height || '47'}
    viewBox="0 0 453 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 16.9999C11.905 9.38385 23.202 6.74718 34.9872 3.0092C37.6039 2.17923 42.0693 2 44.7938 2C47.8893 2 50.9025 2.47482 53.9496 3.0101C61.0968 4.26561 67.3092 6.8102 74.1025 9.27269C82.8863 12.4567 97.8133 22.707 97.8133 22.707C106.597 28.8361 115.85 33.1601 126.052 36.5453C130.472 38.0118 135.11 39.4897 139.711 40.1816C143.701 40.7814 147.498 41.9998 151.579 41.9998H164.766C168.753 41.9998 172.527 41.5384 176.459 40.8887C180.983 40.1413 185.678 40.0777 189.994 38.3634C193.111 37.1256 195.947 35.4794 198.976 34.0705C201.124 33.0712 203.444 32.3799 205.42 31.0907C208.934 28.7976 212.607 27.037 216.068 24.4999M221 20.0001C230.905 12.3841 242.202 9.7474 253.987 6.00943C256.604 5.17946 261.069 5.00023 263.794 5.00023C266.889 5.00023 269.903 5.47505 272.95 6.01032C280.097 7.26584 286.309 9.81043 293.103 12.2729C301.886 15.4569 309.184 20.3837 316.813 25.7072C325.597 31.8363 334.85 36.1603 345.052 39.5455C349.472 41.012 354.11 42.4899 358.711 43.1818C362.701 43.7817 366.498 45 370.579 45H383.766C387.753 45 391.527 44.5386 395.459 43.8889C399.983 43.1415 404.678 43.0779 408.994 41.3637C412.111 40.1258 414.947 38.4796 417.976 37.0708C420.124 36.0714 422.444 35.3801 424.42 34.091C427.934 31.7978 431.607 30.0373 435.068 27.5001C438.5 25.5 448 19 451 17"
      stroke={theme.colors.neutral40}
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="6 6"
    />
  </svg>
);
export default CurvedDashedLineBackground;
