import * as S from './style';
import { UseAudio } from 'Hooks';

const PlaySoundWithImage = ({ text, imageUrl, audioUrl }) => {
  const { handleAudioClick } = UseAudio({ audioUrl });

  return (
    <S.Option onClick={handleAudioClick} imageUrl={imageUrl}>
      <S.Text size="large" ta="center" color="neutralSurface">
        {text}
      </S.Text>
    </S.Option>
  );
};

export default PlaySoundWithImage;
