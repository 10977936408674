import * as S from './style';
import * as T from '../../Typography';

const ImageSelect = ({ options = [], handleChange, values = [] }) => {
  const handleSelect = (value) => {
    if (values.includes(value)) {
      return handleChange(values.filter((item) => item !== value));
    } else {
      return handleChange([...values, value]);
    }
  };
  return (
    <S.ButtonWrapper>
      {options.map((item, index) => (
        <S.Button
          value={item.value}
          key={item.value + index}
          isActive={values.includes(item.value)}
          onClick={() => handleSelect(item.value)}
        >
          <S.ImgContainer imageUrl={item.imageUrl} />
          <S.TextWrapper>
            <T.P
              weight="normal"
              color={
                values.includes(item.value) ? 'neutralLight' : 'neutralMain'
              }
            >
              {item.label}
            </T.P>
          </S.TextWrapper>
        </S.Button>
      ))}
    </S.ButtonWrapper>
  );
};

export default ImageSelect;
