import theme from '../theme';
import { audio } from './static-files';

const badges = {
  COMPLETE_FIRST_BOOK: {
    icon: 'completedFirstReadingBook',
    text: 'Completed first reading book',
    borderColor: theme.colors.neutralMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.neutralMain,
    audio: audio.youveJustWonABadgeForCompletingYourFirstBook,
    audioText:
      'You’ve just won a badge for completing your first book, keep going!',
  },
  ACHIEVED_PERSONAL_GOAL: {
    icon: 'achievedPersonalGoal',
    text: 'Achieved a personal goal',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youveJustWonABadgeForAchievingAPersonalGoal,
    audioText:
      'You’ve just won a badge for achieving a personal goal, great work!',
  },
  ATTEND_FIVE_SESSIONS: {
    icon: 'attendedFiveSessions',
    text: 'Attended 5 sessions in a row',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youveJustWonABadgeForAttending5SessionsInARow,
    audioText:
      'You’ve just won a badge for attending 5 sessions in a row, way to keep committed!',
  },
  END_OF_MANUAL_1: {
    icon: 'endOfM1',
    text: 'End of manual one',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youVeJustWonABadgeForCompletingManualOne,
    audioText:
      'You’ve just won a badge for completing manual one, amazing work!',
  },
  END_OF_MANUAL_2: {
    icon: 'endOfM2',
    text: 'End of manual two',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youveJustWonABadgeForCompletingManualTwo,
    audioText: 'You’ve just won a badge for completing manual two, great work!',
  },
  END_OF_MANUAL_3: {
    icon: 'endOfM3',
    text: 'End of manual three',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youveJustWonABadgeForCompletingManualThree,
    audioText: 'You’ve just won a badge for completing manual three, nice one!',
  },
  END_OF_MANUAL_4: {
    icon: 'endOfM4',
    text: 'End of manual four',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youveJustWonABadgeForCompletingManualFour,
    audioText:
      'You’ve just won a badge for completing manual four, you should be chuffed!',
  },
  END_OF_MANUAL_5: {
    icon: 'endOfM5',
    text: 'End of manual five',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youveJustWonABadgeForCompletingManualFive,
    audioText:
      'You’ve just won a badge for completing manual five and the whole programme, amazing work!',
  },
  MAKING_PROGRESS: {
    icon: 'makingProgress',
    text: 'Making progress',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youveJustWonABadgeForMakingProgress,
    audioText: 'You’ve just won a badge for making progress, keep going!',
  },
  FIRST_STEP: {
    icon: 'firstStep',
    text: 'Completed first exercise',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youveJustWonABadgeForCompletingYourFirstExercise,
    audioText:
      'You’ve just won a badge for completing your first exercise, great start!',
  },
  LISTEN_AND_REPEAT: {
    icon: 'listenAndRepeat',
    text: 'Completed first listen and repeat exercise',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio:
      audio.youveJustWonABadgeForCompletingYourFirstListenAndRepeatExercise,
    audioText:
      'You’ve just won a badge for completing your first listen and repeat exercise, great work!',
  },
  SPOILT_FOR_CHOICE: {
    icon: 'spoiltForChoice',
    text: 'Completed all multiple choice questions in Manual 1',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio:
      audio.youveJustWonABadgeForCompletingAllMultipleChoiceQuestionsInManual1,
    audioText:
      'You’ve just won a badge for completing all multiple choice questions in Manual 1, keep going!',
  },
  DROP_THE_MIC: {
    icon: 'dropTheMic',
    text: 'Completed first drag and drop exercise',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youveJustWonABadgeForCompletingYourFirstDragAndDropExercise,
    audioText:
      'You’ve just won a badge for completing your first drag and drop exercise, great work!',
  },
  WORD_COLLECTOR: {
    icon: 'wordCollector',
    text: 'Completed all text finder exercises',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youveJustWonABadgeForCompletingYourAllTextFinderExercises,
    audioText:
      'You’ve just won a badge for completing your all text finder exercises, great work!',
  },
  WORD_BUILDER: {
    icon: 'wordBuilder',
    text: 'Completed all word games',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youveJustWonABadgeForCompletingAllWordGames,
    audioText:
      'You’ve just won a badge for completing all word games, great work!',
  },
  STREAK_MASTER_7: {
    icon: 'streakMaster',
    text: 'Used the app for 7 days',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youreAStreakMasterYouveJustWonABadgeForUsingTheAppFor7Days,
    audioText:
      "You're a streak master! You've just won a badge for using the app for 7 days",
  },
  STREAK_MASTER_14: {
    icon: 'streakMaster',
    text: 'Used the app for 14 days',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youreAStreakMasterYouveJustWonABadgeForUsingTheAppFor14Days,
    audioText:
      "You're a streak master! You've just won a badge for using the app for 14 days",
  },
  STREAK_MASTER_30: {
    icon: 'streakMaster',
    text: 'Used the app for 30 days',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youreAStreakMasterYouveJustWonABadgeForUsingTheAppFor30Days,
    audioText:
      "You're a streak master! You've just won a badge for using the app for 30 days",
  },
  STREAK_PRODIGY: {
    icon: 'streakProdigy',
    text: 'Used the app for 100 days',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youreAStreakProdigyYouveJustWonABadgeForUsingTheAppFor100Days,
    audioText:
      "You're a streak prodigy! You've just won a badge for using the app for 100 days. Amazing work",
  },
  bullseye: {
    icon: 'bullseye',
    text: 'All answers correct in an exercise',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio:
      audio.bullseyeYouveJustWonABadgeForGettingAllAnswersCorrectInAnExercise,
    audioText:
      "Bullseye! You've just won a badge for getting all answers correct in an exercise",
  },
  WORD_SMITH_500: {
    icon: 'wordSmith',
    text: 'Learnt 500 words',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youreAWordsmithYouveJustWonABadgeForLearning500Words,
    audioText:
      "You're a wordsmith! You've just won a badge for learning 500 words",
  },
  WORD_SMITH_200: {
    icon: 'wordSmith',
    text: 'Learnt 200 words',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youreAWordsmithYouveJustWonABadgeForLearning200Words,
    audioText:
      "You're a wordsmith! You've just won a badge for learning 200 words",
  },
  WORD_SMITH_100: {
    icon: 'wordSmith',
    text: 'Learnt 100 words',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youreAWordsmithYouveJustWonABadgeForLearning100Words,
    audioText:
      "You're a wordsmith! You've just won a badge for learning 100 words",
  },
  WORD_SMITH_50: {
    icon: 'wordSmith',
    text: 'Learnt 50 words',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youreAWordsmithYouveJustWonABadgeForLearning50Words,
    audioText:
      "You're a wordsmith! You've just won a badge for learning 50 words",
  },
  WORD_SMITH_30: {
    icon: 'wordSmith',
    text: 'Learnt 30 words',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youreAWordsmithYouveJustWonABadgeForLearning30Words,
    audioText:
      "You're a wordsmith! You've just won a badge for learning 30 words",
  },
  WORD_SMITH_20: {
    icon: 'wordSmith',
    text: 'Learnt 20 words',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youreAWordsmithYouveJustWonABadgeForLearning20Words,
    audioText:
      "You're a wordsmith! You've just won a badge for learning 20 words",
  },
  WORD_SMITH_10: {
    icon: 'wordSmith',
    text: 'Learnt 10 words',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio: audio.youreAWordsmithYouveJustWonABadgeForLearning10Words,
    audioText:
      "You're a wordsmith! You've just won a badge for learning 10 words",
  },
  MASTER_IN_THE_MAKING: {
    icon: 'masterInTheMaking',
    text: 'Completed 10 exercises in one day',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
    audio:
      audio.youreAMasterInTheMakingYouveJustWonABadgeForCompleting10ExercisesInOneDay,
    audioText:
      "You're a master in the making! You've just won a badge for completing 10 exercises in one day",
  },
  // GROUPS
  WORD_SMITH: {
    icon: 'wordSmith',
    text: 'Wordsmith',
    borderColor: theme.colors.primaryMain,
    color: theme.colors.neutralLight,
    iconColor: theme.colors.primaryMain,
  },
};

const badgesGroups = {
  WORD_SMITH_500: 'WORD_SMITH',
  WORD_SMITH_200: 'WORD_SMITH',
  WORD_SMITH_100: 'WORD_SMITH',
  WORD_SMITH_50: 'WORD_SMITH',
  WORD_SMITH_30: 'WORD_SMITH',
  WORD_SMITH_20: 'WORD_SMITH',
  WORD_SMITH_10: 'WORD_SMITH',
};

export { badgesGroups };

export default badges;
