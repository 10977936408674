import styled from '@emotion/styled';
import Icon from '../../Icon';

export const Item = styled.button`
  position: relative;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 96px;
  border-radius: ${({ theme }) => theme.borders.imageRadius};
  margin-bottom: ${({ theme }) => theme.spacings[2]};
  padding: ${({ theme }) => theme.spacings[2]};
  background-color: ${({ theme }) => theme.colors.secondaryMain};
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  transition: opacity 0.3s;

  :focus {
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${({ theme }) => theme.colors.primaryMain + '!important'};
    outline-offset: 0.2em;
  }

  & > * {
    /* position: relative; */
    z-index: 2;
    color: white;
  }

  &:after {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(5, 23, 48, 0.7),
      rgba(5, 23, 48, 0.7)
    );
    border-radius: ${({ theme }) => theme.borders.imageRadius};
  }
`;

export const IconWrapper = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0;
`;
