import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const ldsSpinner = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Wrapper = styled.main`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme, overlayColor }) =>
    !overlayColor ? theme.colors.neutralLight : theme.colors[overlayColor]};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

export const LoadingContainer = styled.div`
  color: ${({ theme }) => theme.colors.neutralLight};
  display: inline-block;
  position: relative;
  width: 180px;
  height: 180px;
  & div {
    transform-origin: 90px 90px;
    animation: ${ldsSpinner} 1.2s linear infinite;
  }
  & div:after {
    content: ' ';
    display: block;
    position: absolute;
    border: 1px solid ${({ theme }) => theme.colors.primaryMain};
    top: 3px;
    left: 37px;
    width: 20px;
    height: 38px;
    border-radius: 20%;
    background: ${({ theme }) => theme.colors.neutralMain};
  }
  & div:nth-of-type(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  & div:nth-of-type(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  & div:nth-of-type(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  & div:nth-of-type(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  & div:nth-of-type(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  & div:nth-of-type(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  & div:nth-of-type(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  & div:nth-of-type(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  & div:nth-of-type(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  & div:nth-of-type(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  & div:nth-of-type(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  & div:nth-of-type(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
`;
