import theme from '../../../theme';

const achievedPersonalGoal = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="32"
      cy="32"
      r="30"
      fill={theme.colors.neutralSurface}
      stroke={theme.colors.primaryMain}
      strokeWidth="4"
    />
    <path
      d="M32.3599 37.7598C27.4114 37.7598 23.3999 33.7483 23.3999 28.7998C23.3999 23.8514 27.4114 19.8398 32.3599 19.8398C37.3084 19.8398 41.3199 23.8514 41.3199 28.7998C41.3199 33.7483 37.3084 37.7598 32.3599 37.7598Z"
      fill={color || theme.colors.primaryMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.2398 42.8804C43.6993 42.8804 44.009 42.4106 43.828 41.9883L40.7486 35.2002C39.0501 37.5919 36.3503 39.2227 33.2605 39.4816L36.2674 45.0866C36.4627 45.4773 36.9835 45.5618 37.2923 45.2529L39.6649 42.8804H43.2398ZM27.428 45.2526C27.7369 45.5614 28.2576 45.4769 28.453 45.0862L31.5286 39.4813C28.4387 39.2227 25.7388 37.5921 24.0402 35.2006L20.8923 41.9879C20.7113 42.4102 21.0211 42.88 21.4805 42.88H25.0554L27.428 45.2526Z"
      fill={theme.colors.primaryMain}
    />
  </svg>
);
export default achievedPersonalGoal;
