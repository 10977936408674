import theme from '../../../theme';

const Report = ({ width, height, color, ...props }) => (
  <svg
    width={width || '48'}
    height={height || '48'}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.8007 3.83984V14.3998C28.8007 15.9904 30.0901 17.2798 31.6807 17.2798H40.3207V37.4398C40.3207 41.1512 37.312 44.1598 33.6007 44.1598H14.4007C10.6893 44.1598 7.68066 41.1512 7.68066 37.4398V10.5598C7.68066 6.84849 10.6893 3.83984 14.4007 3.83984H28.8007ZM16.3204 15.3595H22.0804C22.6106 15.3595 23.0404 14.9297 23.0404 14.3995C23.0404 13.8693 22.6106 13.4395 22.0804 13.4395H16.3204C15.7902 13.4395 15.3604 13.8693 15.3604 14.3995C15.3604 14.9297 15.7902 15.3595 16.3204 15.3595ZM31.6804 24.9594H16.3204C15.7902 24.9594 15.3604 24.5296 15.3604 23.9994C15.3604 23.4692 15.7902 23.0394 16.3204 23.0394H31.6804C32.2106 23.0394 32.6404 23.4692 32.6404 23.9994C32.6404 24.5296 32.2106 24.9594 31.6804 24.9594ZM16.3204 34.5596H31.6804C32.2106 34.5596 32.6404 34.1298 32.6404 33.5996C32.6404 33.0694 32.2106 32.6396 31.6804 32.6396H16.3204C15.7902 32.6396 15.3604 33.0694 15.3604 33.5996C15.3604 34.1298 15.7902 34.5596 16.3204 34.5596ZM31.0739 4.87589C30.9659 4.74626 30.8476 4.62742 30.7207 4.52017V14.3996C30.7207 14.9298 31.1505 15.3596 31.6807 15.3596H39.7976C39.7522 15.295 39.704 15.2321 39.6532 15.171L31.0739 4.87589Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Report;
