import * as S from './style';
import * as T from '../../../../../components/Typography';
import { Row, Col } from '../../../../../components/Grid';
import data from './data';

const Getbettamol2 = () => {
  return (
    <S.Wrapper>
      <Row mb="5">
        <Col w={[4, 6, 6]}>
          <T.H2 size="med">Getbettamol... the path to fast pain relief</T.H2>
        </Col>
      </Row>
      <Row mb="4">
        <Col w={[4, 10, 10]}>
          <T.P weight="bold" mb="2">
            This medicine is NOT suitable for children under six years old
          </T.P>
          {data.firstList.map((item) => (
            <S.ListItem key={item.key}>
              <S.BulletPoint /> <T.P>{item.text}</T.P>
            </S.ListItem>
          ))}
        </Col>
      </Row>
      <Row mb="5" mbT="4">
        <Col w={[4, 10, 10]}>
          <T.P mb="2">
            You
            <T.P weight="bold" ml="2" style={{ display: 'inline' }}>
              MUST
            </T.P>{' '}
            contact your doctor or heal th provider:
          </T.P>
          {data.firstList.map((item) => (
            <S.ListItem key={item.key}>
              <S.BulletPoint /> <T.P>{item.text}</T.P>
            </S.ListItem>
          ))}
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default Getbettamol2;
