import theme from '../../../theme';

const Check = ({ width, height, color, ...props }) => (
  <svg
    width={width || '40'}
    height={height || '40'}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.2 20.0004C33.2 12.4893 27.1111 6.40039 19.6 6.40039C12.0889 6.40039 6 12.4893 6 20.0004C6 27.5115 12.0889 33.6004 19.6 33.6004C27.1111 33.6004 33.2 27.5115 33.2 20.0004ZM24.8955 14.5724C24.5488 14.2984 24.0457 14.3574 23.7718 14.7041L18.0026 22.0053L15.3597 19.4131C15.0443 19.1037 14.5378 19.1086 14.2284 19.424C13.919 19.7394 13.9239 20.2459 14.2393 20.5553L17.518 23.7712C17.8575 24.1042 18.4109 24.0692 18.7058 23.696L25.0272 15.696C25.3011 15.3494 25.2422 14.8463 24.8955 14.5724Z"
      fill={color || theme.colors.neutralSurface}
    />
  </svg>
);
export default Check;
