import { userRoles } from '../../constants';
import { fields, createSchema, validate as _validate } from '..';

const { email, loginPassword, learnerUniqueId } = fields;

const schema = createSchema({
  email,
  password: loginPassword,
});

const learnerSchema = createSchema({
  learnerUniqueId,
});

const validate = (data) => {
  if (data.role === userRoles.LEARNER) {
    return _validate(learnerSchema, data);
  }

  return _validate(schema, data);
};

export default validate;
