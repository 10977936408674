import React, { forwardRef } from 'react';

import * as T from '../../../../../../components/Typography';

import * as S from './style';

const Option = forwardRef(
  (
    { opt, handleDownClick, index, tempArr, cmsPreview, handleUpClick },
    ref
  ) => {
    return (
      <S.Option ref={ref}>
        <T.P ta={/\s/.test(opt.option) ? 'left' : 'center'}>{opt.option}</T.P>
        <S.ButtonsWrapper>
          <S.ArrowWrapper
            icon="arrowDown"
            variant="tertiary"
            iconMR="-6px"
            ml="3"
            mt={3}
            borderRadius="10px"
            handleClick={() => handleDownClick(index)}
            disabled={index === tempArr.length - 1 || cmsPreview}
          />

          <S.ArrowWrapper
            icon="arrowUp"
            variant="tertiary"
            iconMR="-6px"
            ml="3"
            mt={3}
            borderRadius="10px"
            handleClick={() => handleUpClick(index)}
            disabled={index === 0 || cmsPreview}
          />
        </S.ButtonsWrapper>
      </S.Option>
    );
  }
);

export default Option;
