import styled from '@emotion/styled';

export const Wrapper = styled.div`
  max-width: 635px;
`;

export const SideDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 16.5%;
  position: absolute;
  top: 92px;
`;
