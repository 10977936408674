import styled from '@emotion/styled';
import { BasicButton } from '../../../../../../components/Button';

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  display: flex;
  flex-direction: column;
  align-items: end;
  flex: 1;
  padding: 0;
  ${({ theme }) => theme.media.mobile} {
    padding: ${({ theme }) => `0 ${theme.spacings[6]}`};
  }
`;

export const Option = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralLight};
  padding: ${({ theme }) => theme.spacings[4]};
  margin-bottom: ${({ theme }) => theme.spacings[3]};
  box-shadow: 0px 1px 3px -1px rgba(4, 13, 38, 0.1),
    0px 2px 5px -1px rgba(4, 13, 38, 0.11);
  border-radius: ${({ theme }) => theme.borders.imageRadius};
`;

export const OptionsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings[5]};
  margin-bottom: ${({ theme }) => theme.spacings[5]};
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ArrowWrapper = styled(BasicButton)`
  width: 44px;
  height: 44px;
`;
