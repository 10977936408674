import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';
import Image from '../Image';

export const Wrapper = styled.div`
  ${setMargin};
  display: flex;
  width: ${({ width }) => width || '100%'};
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height || '250px'};
  border-radius: ${({ theme, borderRadius }) =>
    borderRadius || theme.borders.radiusXLarge};
  background-color: ${({ backgroundColor, theme, image }) =>
    !image ? theme.colors[backgroundColor] || theme.colors.neutralLight : ''};
`;
export const StyledImage = styled(Image)`
  position: absolute;
  z-index: -1;
`;
