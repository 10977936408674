import theme from '../../../theme';

const spoiltForChoice = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="32"
      cy="32"
      r="30"
      fill={theme.colors.quaternaryMain}
      fillOpacity="0.05"
      stroke={theme.colors.quaternaryMain}
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.2 18.56V25.6C35.2 26.6604 36.0596 27.52 37.12 27.52H42.88V40.96C42.88 43.4342 40.8742 45.44 38.4 45.44H25.6C23.1258 45.44 21.12 43.4342 21.12 40.96V23.04C21.12 20.5658 23.1258 18.56 25.6 18.56H35.2ZM28.6082 34.0904C28.3558 33.8429 27.9506 33.8468 27.7031 34.0992C27.4556 34.3515 27.4595 34.7567 27.7118 35.0042L30.3348 37.5769C30.5865 37.8238 30.9905 37.8206 31.2382 37.5697L36.2953 32.4497C36.5437 32.1983 36.5412 31.793 36.2897 31.5447C36.0383 31.2963 35.633 31.2988 35.3847 31.5503L30.7757 36.2165L28.6082 34.0904ZM36.7155 19.2508C36.6434 19.1644 36.5646 19.0852 36.48 19.0137V25.6C36.48 25.9534 36.7665 26.24 37.12 26.24H42.5313C42.501 26.1969 42.4689 26.1549 42.435 26.1142L36.7155 19.2508Z"
      fill={theme.colors.quaternaryMain}
    />
  </svg>
);
export default spoiltForChoice;
