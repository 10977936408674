import Markdown from '../../../../components/Markdown';
import * as S from './style';
import './style.css';

const containsOption = (options, currentOption) =>
  options.some(
    (option) => JSON.stringify(option) === JSON.stringify(currentOption)
  );

const TextFinderMdContent = ({
  text = '',
  matches = [],
  selectedOptions,
  setSelectedOptions,
  exampleStep,
}) => {
  const formattedMatches = matches?.map((e) => e.replace(/%/g, ''));

  let i = 0;
  const contentObjects = text.split('%').map((e) => {
    if (formattedMatches?.includes(e)) {
      const elem = { type: 'span', id: i, content: e };
      i++;
      return elem;
    } else {
      return { type: 'text', content: e };
    }
  });

  const createMarkdownContent = contentObjects
    ?.map((item) => {
      if (
        item.type === 'text' &&
        (item.content === ' ' || item.content === '\n')
      )
        return `<span  className="space-span"}>&nbsp;</span>`;
      return item.type === 'span'
        ? `<span id="${item.id}" className="word-span">${item.content}</span>`
        : item.content;
    })
    .join('');

  // create MD string with html element
  const handleToggleOptions = (selectedOption) => {
    setSelectedOptions((prevOptions) =>
      containsOption(prevOptions, selectedOption)
        ? prevOptions.filter(
            (option) =>
              JSON.stringify(option) !== JSON.stringify(selectedOption)
          )
        : [...prevOptions, selectedOption]
    );
  };

  // use this for custom span render in MD component
  const TextMatchSpan = (props) => {
    const selectedValue = { id: props.id, option: props.children[0] };
    const optionSelected = containsOption(selectedOptions, selectedValue);
    return (
      <S.Span
        {...props}
        role="button"
        onClick={() => handleToggleOptions(selectedValue)}
        selected={optionSelected}
        exampleStep={exampleStep}
        isSmall={props.children[0]?.length <= 2}
      />
    );
  };

  return (
    <Markdown
      text={createMarkdownContent}
      customRender={{
        span: TextMatchSpan,
      }}
      customStyles={{
        p: {
          position: 'relative',
          zIndex: 4,
          lh: '30px',
          padding: '0.15rem',
          size: 'regular',
        },
        li: {
          size: 'regular',
        },
      }}
    />
  );
};

export default TextFinderMdContent;
