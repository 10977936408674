import Icon from '../Icon';
import * as S from './style';

const IconWithParagraph = ({
  icon = 'tick',
  children,
  iconProps,
  jc,
  mt,
  ai,
  ...props
}) => {
  return (
    <S.Wrapper jc={jc} mt={mt} ai={ai} {...props}>
      {icon && <Icon icon={icon} {...iconProps} mr="3" mt="2" />}
      <S.Paragraph>{children}</S.Paragraph>
    </S.Wrapper>
  );
};

export default IconWithParagraph;
