import theme from '../../../theme';

const completedFirstReadingBook = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="32"
      cy="32"
      r="30"
      fill={theme.colors.neutralSurface}
      stroke={theme.colors.neutralMain}
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5199 18.5602H40.9599C42.0203 18.5602 42.8799 19.4198 42.8799 20.4802V40.9602C42.8799 43.4344 40.8742 45.4402 38.3999 45.4402H27.5199V18.5602ZM26.24 18.5606V45.4406H25.6C23.1258 45.4406 21.12 43.4349 21.12 40.9606V20.4806C21.12 19.4202 21.9796 18.5606 23.04 18.5606H26.24ZM30.0799 23.0403C30.0799 22.6868 30.3665 22.4003 30.7199 22.4003H38.3999C38.7534 22.4003 39.0399 22.6868 39.0399 23.0403V28.1603C39.0399 28.5138 38.7534 28.8003 38.3999 28.8003H30.7199C30.3665 28.8003 30.0799 28.5138 30.0799 28.1603V23.0403Z"
      fill={theme.colors.neutralMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.36 27.5203V23.6803H37.76V27.5203H31.36Z"
      fill={theme.colors.neutralMain}
    />
  </svg>
);
export default completedFirstReadingBook;
