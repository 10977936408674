import React from 'react';
import * as S from './style';

import Graphics from '../../Graphics';
import Header from '../Header';
import { useMediaQuery } from 'react-responsive';

import theme from '../../../theme';

const General = ({
  children,
  image,
  sideColor = 'neutralLight',
  headerProps = {},
  ...props
}) => {
  const isDesktop = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.desktop})`,
  });

  return (
    <S.Wrapper {...props}>
      {!isDesktop && <Graphics image={image} bgColor={sideColor} />}
      <S.Content>
        <Header {...headerProps} />
        <S.ContentBody headerType={headerProps.type}>{children}</S.ContentBody>
      </S.Content>
      {!isDesktop && <Graphics image={image} bgColor={sideColor} />}
    </S.Wrapper>
  );
};

export default General;
