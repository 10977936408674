import { LEARNING_SESSIONS_BASE } from '../constants';
import { usePost, formatQueryError } from '../utils/queries';

function useCreateLearningSession() {
  const context = usePost(`${LEARNING_SESSIONS_BASE}`, undefined);
  return {
    ...context,
    error: formatQueryError(context),
  };
}

export { useCreateLearningSession };
