import React, { useEffect, useRef } from 'react';

export const randomizeArray = (arr) => {
  const newArr = [...arr];
  for (let i = newArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
  }
  return newArr;
};

export const calculateBoundingBoxes = (children) => {
  const boundingBoxes = {};
  React.Children.forEach(children, (child) => {
    const domNode = child.ref.current;

    const nodeBoundingBox = domNode?.getBoundingClientRect() || {};
    nodeBoundingBox.offsetY = domNode?.offsetTop;
    boundingBoxes[child.key] = nodeBoundingBox;
  });
  return boundingBoxes;
};

export const usePrevious = (value) => {
  const prevChildrenRef = useRef();

  useEffect(() => {
    prevChildrenRef.current = value;
  }, [value]);

  return prevChildrenRef.current;
};
