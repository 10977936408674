import { fields, createSchema, validate as _validate } from '..';

const { descriptionOptional } = fields;

const schema = createSchema({
  note: descriptionOptional,
});

const validate = (data) => _validate(schema, data);

export default validate;
