import theme from '../../../theme';

const OpenedBook = ({ width, height, color, ...props }) => (
  <svg
    width={width || '32'}
    height={height || '32'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.8801 7.67969V22.3814C26.8801 23.4451 26.0204 24.3074 24.9601 24.3074C22.6117 24.3074 20.5049 24.7614 18.6182 25.6695C20.1005 25.7713 21.2912 25.977 22.5422 26.312C22.7741 26.3741 23.5017 26.5973 24.4006 26.8731C25.8055 27.3041 27.6288 27.8635 28.6323 28.1369C29.0393 28.2477 29.4401 27.9404 29.4401 27.5173V10.8255C29.4401 9.27249 28.3407 7.9771 26.8801 7.67969ZM5.12006 22.3816V7.67995C3.65942 7.97737 2.56006 9.27276 2.56006 10.8257V27.5176C2.56006 27.9406 2.96081 28.248 3.36778 28.1372C4.37132 27.8638 6.19467 27.3044 7.59956 26.8734C8.49841 26.5976 9.22597 26.3744 9.45793 26.3123C10.7122 25.9764 11.9059 25.7705 13.3936 25.6689C11.5191 24.7617 9.40856 24.3076 7.04006 24.3076C5.97967 24.3076 5.12006 23.4453 5.12006 22.3816Z"
      fill={color || theme.colors.neutralSurface}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3599 25.5985C12.9712 24.0668 10.2027 23.3004 7.0399 23.3004C6.68644 23.3004 6.3999 23.0099 6.3999 22.6517V4.48898C6.3999 4.13073 6.68644 3.84032 7.0399 3.84032C10.1602 3.84032 12.9372 4.53351 15.3599 5.92132V25.5985ZM16.6399 5.92738C19.0885 4.53548 21.8655 3.83984 24.9599 3.83984C25.3134 3.83984 25.5999 4.13026 25.5999 4.48851V22.6512C25.5999 23.0095 25.3134 23.2999 24.9599 23.2999C21.8211 23.2999 19.0524 24.0667 16.6399 25.5998V5.92738Z"
      fill={color || theme.colors.neutralSurface}
    />
  </svg>
);

export default OpenedBook;
