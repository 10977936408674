import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacings[4]} ${theme.spacings[4]} ${theme.spacings[2]} ${theme.spacings[4]}`};
`;

export const Link = styled.a`
  font-family: 'EB Garamond' !important;
  color: ${({ theme }) => theme.colors.primaryMain};
  text-decoration: underline !important;
`;

export const BulletPoint = styled.span`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme.colors.neutralMain};
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px;
  margin-left: 12px;
  margin-right: 12px;
  flex-shrink: 0;
`;

export const ListItem = styled.div`
  display: flex;
`;
