const CurvedDashedLine = ({ width, height, color, ...props }) => (
  <svg
    width={width || '43'}
    height={height || '188'}
    viewBox="0 0 43 188"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9929 2C20.9929 2 20.8766 3.99661 21.1139 8.51808"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M22.4438 21.2725C22.4438 21.2725 22.2868 23.9676 26.0657 30.2725"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M28.9021 34.9775C28.9021 34.9775 29.902 35.8135 34.4021 41.4775"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M38.9231 51.9248C38.9231 51.9248 39.4146 52.5372 39.6764 60.5197"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M39.84 66.3428C39.84 66.3428 39.8641 67.6899 37.9568 72.1899"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M34.8164 77.7422C34.8164 77.7422 34.3164 78.7422 31.8164 82.2422"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M28.8168 85.6943C28.8168 85.6943 28.3425 86.2399 26.3252 88.0394"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M22.8933 91.8613C22.8933 91.8613 20.8322 93.2354 18.6403 95.5449"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M14 101.5C14 101.5 11 106 10 113"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M8 122.5C8 122.5 6 130 9.07147 137.849"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M14 149C14 149 18.4993 155.5 20.567 162.953"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M21.8959 175.217C21.8959 175.217 22.1239 179.781 22.0149 185.331"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);
export default CurvedDashedLine;
