import theme from '../../../theme';

const SmallTick = ({ width, height, color, ...props }) => (
  <svg
    width={width || '18'}
    height={height || '13'}
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4697 0.46967C16.7626 0.176777 17.2375 0.176777 17.5304 0.46967C17.8232 0.762563 17.8232 1.23744 17.5304 1.53033L6.53036 12.5303C6.26013 12.8006 5.82993 12.8244 5.53151 12.5857L0.531506 8.58565C0.20806 8.32689 0.155619 7.85493 0.414376 7.53148C0.673133 7.20803 1.1451 7.15559 1.46855 7.41435L5.94437 10.995L16.4697 0.46967Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default SmallTick;
