import Icon from '../Icon';
import * as T from '../Typography';
import * as S from './style';

const IconWithBackgroundWithText = ({
  backgroundColor,
  icon = 'tick',
  height,
  width,
  iconProps,
  text,
  ...props
}) => {
  return (
    <S.Wrapper {...props}>
      <S.IconWrapper
        backgroundColor={backgroundColor}
        height={height}
        width={width}
        {...props}
      >
        {icon && <Icon icon={icon} {...iconProps} />}
      </S.IconWrapper>
      <T.P ml="4" weight="semi">
        {text}
      </T.P>
    </S.Wrapper>
  );
};
export default IconWithBackgroundWithText;
