import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import IconWithBackground from '../../../components/IconWithBackground';
import { LEARNER, COACH } from '../../../constants/nav-routes';
import * as S from './style';
const ReportCompleted = () => {
  return (
    <>
      <S.Wrapper>
        <Row>
          <Col w={[4, 9, 9]}>
            <T.H2 color="neutralMain">
              Click this question mark any time if you get stuck.
            </T.H2>

            <T.P color="neutral90" mt="3">
              This will let your coach know which exercises you need help with
            </T.P>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="4">
            <IconWithBackground
              height="257px"
              icon="questionMark"
              iconProps={{ width: '100', height: '100' }}
            />
          </Col>
        </Row>
      </S.Wrapper>
      <S.NextButtonWrapper mt="6">
        <Col w={[4, 9, 9]}>
          <BasicButton
            variant="primary"
            icon="next"
            iconProps={{ width: '24px', height: '24px' }}
            to={LEARNER.ONBOARDING_5}
          />
        </Col>
        <Col w={[4, 9, 9]} mt="3">
          <BasicButton variant="secondary" to={COACH.LEARNERS}>
            <T.P color="white" weight="semi">
              Exit onboarding
            </T.P>
          </BasicButton>
        </Col>
      </S.NextButtonWrapper>
    </>
  );
};

export default ReportCompleted;
