import Icon from '../Icon';
import * as S from './style';

const IconWithBackground = ({
  backgroundColor,
  icon = 'tick',
  height,
  width,
  iconProps,
  image,
  ...props
}) => {
  return (
    <S.Wrapper
      image={image}
      backgroundColor={backgroundColor}
      height={height}
      width={width}
      {...props}
    >
      {icon && <S.StyledImage image={image} />}
      {icon && <Icon icon={icon} {...iconProps} />}
    </S.Wrapper>
  );
};
export default IconWithBackground;
