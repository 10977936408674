import styled from '@emotion/styled';
import * as T from '../Typography';

export const Option = styled.button`
  position: relative;
  width: 146px;
  height: 96px;
  overflow: hidden;
  background: none;
  border: none;
  border-radius: 9px;
  cursor: pointer;
  background: linear-gradient(0deg, rgba(5, 23, 48, 0.5), rgba(5, 23, 48, 0.5)),
    url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-size: cover;
`;

export const Text = styled(T.H1)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;
