const Lens = ({ width, height, color, ...props }) => (
  <svg
    width={width || '23'}
    height={height || '23'}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.6118 16.4163L22.091 20.9056L22.0912 20.9058M17.6118 16.4163L21.0619 21.9348C21.1776 22.0505 21.3346 22.1155 21.4983 22.1155C21.6619 22.1155 21.8189 22.0505 21.9347 21.9348C22.0504 21.819 22.1154 21.6621 22.1154 21.4984C22.1154 21.3347 22.0504 21.1777 21.9347 21.062L22.0912 20.9058M17.6118 16.4163C19.1623 14.5649 19.9592 12.1943 19.8382 9.77739C19.7138 7.29156 18.6281 4.95139 16.8105 3.25108C14.9929 1.55078 12.5855 0.623455 10.0969 0.664946C7.60834 0.706437 5.23328 1.7135 3.47332 3.47345C1.71337 5.2334 0.706315 7.60846 0.664824 10.0971C0.623333 12.5857 1.55066 14.993 3.25096 16.8106C4.95126 18.6283 7.29144 19.7139 9.77727 19.8384C12.1942 19.9593 14.5648 19.1624 16.4162 17.6119L20.9055 22.0912L20.9056 22.0913M17.6118 16.4163L20.9056 22.0913M22.0912 20.9058C22.2483 21.063 22.3366 21.2761 22.3366 21.4984C22.3366 21.7207 22.2482 21.9339 22.091 22.0912C21.9338 22.2484 21.7206 22.3367 21.4983 22.3367C21.276 22.3367 21.0628 22.2484 20.9056 22.0913M22.0912 20.9058L20.9056 22.0913M2.3931 10.2766C2.39312 8.71739 2.85548 7.19325 3.7217 5.89685C4.58794 4.60043 5.81917 3.58999 7.25967 2.99332C8.70018 2.39664 10.2853 2.24052 11.8145 2.5447C13.3437 2.84889 14.7484 3.59971 15.851 4.70223C16.9535 5.80475 17.7043 7.20944 18.0085 8.73867C18.3127 10.2679 18.1565 11.853 17.5599 13.2935C16.9632 14.734 15.9527 15.9652 14.6563 16.8315C13.36 17.6977 11.8359 18.16 10.2768 18.1601C8.18674 18.1574 6.18305 17.3259 4.70514 15.848C3.22728 14.3702 2.39583 12.3666 2.3931 10.2766Z"
      fill={color || 'white'}
      stroke={color || 'white'}
      strokeWidth="0.442308"
    />
    <path
      d="M11.852 11.0455H9.332L10.578 7.74153L11.852 11.0455ZM9.85 5.24953L6.14 14.7695H7.96L8.8 12.5295H12.412L13.266 14.9095L15.086 14.6575L11.39 5.24953H9.85Z"
      fill={color || 'white'}
    />
  </svg>
);

export default Lens;
