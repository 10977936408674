import { BasicButton } from '../../../components/Button';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Steps } from '../../../api-calls';
import { navRoutes } from '../../../constants';
import Table from './Table';
import * as S from './style';

const ViewStepsByExerciseId = () => {
  const [steps, setSteps] = useState([]);
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSteps = async () => {
      const { data } = await Steps.getSteps({ exerciseId: id });

      if (data) {
        setSteps(data);
      }
      setLoading(false);
    };

    getSteps();
  }, [id]);

  return (
    <S.Wrapper>
      <Table data={steps} loading={loading} />

      <div>
        <BasicButton
          to={navRoutes.CMS.SINGLE_STEP.replace(':id', id).replace(
            ':stepId',
            'new'
          )}
          mt={5}
          loading={loading}
        >
          Add Step
        </BasicButton>
      </div>
    </S.Wrapper>
  );
};

export default ViewStepsByExerciseId;
