import styled from '@emotion/styled';
import setMargin from '../../../../helpers/set-margin';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentWrapper = styled.div`
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  margin: 0 auto;
  overflow: hidden;
`;
export const Img = styled.img`
  border-radius: 16px;
  margin-top: 1rem;
  width: 300px;
  min-height: 184px;
  max-height: 300px;
  object-fit: cover;
`;

export const TitleImg = styled.img`
  border-radius: 16px;
  margin-top: 1rem;
  max-width: 300px;
  max-height: 300px;
  object-fit: cover;
`;

export const ButtonsWrapper = styled.div`
  margin-top: auto;
`;

export const ButtonWrapper = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
`;

export const TextAudioWrapper = styled.div`
  ${setMargin};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'center'};
`;

export const TitleImageWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  margin-bottom: ${({ theme, mb }) => theme.spacings[mb || 0]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleImageContainer = styled.div`
  width: 160px;
  height: 100px;
  overflow: hidden;
  border-radius: 16px;
  margin-right: 1rem;
`;

export const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const SentenceWrapper = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 300px;
  }
  align-self: center;
  margin-left: ${({ theme }) => theme.spacings[6]};
  margin-right: ${({ theme }) => theme.spacings[6]};
`;
