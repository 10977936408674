import theme from '../../../theme';

const makingProgress = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill={theme.colors.secondaryMain} />
    <circle
      cx="32"
      cy="32"
      r="30"
      stroke={theme.colors.neutralMain}
      strokeOpacity="0.1"
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.68 29.4401V27.5261L19.3222 27.5273C18.8286 28.9265 18.56 30.4319 18.56 32.0001C18.56 39.4228 24.5773 45.4401 32 45.4401C39.4227 45.4401 45.44 39.4228 45.44 32.0001C45.44 30.4293 45.1705 28.9215 44.6753 27.5203L40.32 27.5215V29.4401C40.32 31.2074 38.8873 32.6401 37.12 32.6401C35.3527 32.6401 33.92 31.2074 33.92 29.4401V27.5233L30.08 27.5243V29.4401C30.08 31.2074 28.6473 32.6401 26.88 32.6401C25.1127 32.6401 23.68 31.2074 23.68 29.4401ZM37.4525 36.3961C34.53 38.2194 29.5443 38.2085 26.5515 36.4009C26.2482 36.2177 26.1494 35.8209 26.3308 35.5146C26.5122 35.2083 26.9052 35.1085 27.2086 35.2917C29.7949 36.8539 34.2679 36.8637 36.7796 35.2966C37.0803 35.109 37.4747 35.203 37.6605 35.5066C37.8463 35.8103 37.7532 36.2085 37.4525 36.3961Z"
      fill={theme.colors.neutralSurface}
    />
    <path
      d="M19.85 26.2472C22.0056 21.7027 26.6358 18.5601 32 18.5601C37.3616 18.5601 41.9898 21.6995 44.1468 26.2404L19.85 26.2472H19.85Z"
      fill={theme.colors.neutralSurface}
    />
  </svg>
);
export default makingProgress;
