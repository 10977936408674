import styled from '@emotion/styled';

export const CardsWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacings[1]};
`;

export const CardWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacings[2]};
  margin-bottom: ${({ theme }) => theme.spacings[2]};
`;
