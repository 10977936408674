import theme from '../../../theme';

const QuestionMark = ({ width, height, color, ...props }) => (
  <svg
    width={width || '44'}
    height={height || '44'}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.1666 8C14.3455 8 8 14.3455 8 22.1666C8 29.9878 14.3455 36.3333 22.1666 36.3333C29.9878 36.3333 36.3333 29.9878 36.3333 22.1666C36.3333 14.3455 29.9878 8 22.1666 8ZM21.7239 30.4305C21.4321 30.4305 21.1467 30.3439 20.9041 30.1818C20.6614 30.0196 20.4723 29.7892 20.3606 29.5195C20.2489 29.2499 20.2196 28.9532 20.2766 28.6669C20.3335 28.3807 20.4741 28.1177 20.6804 27.9113C20.8868 27.7049 21.1498 27.5644 21.436 27.5075C21.7223 27.4505 22.019 27.4797 22.2886 27.5914C22.5583 27.7031 22.7888 27.8923 22.9509 28.1349C23.1131 28.3776 23.1996 28.6629 23.1996 28.9548C23.1996 29.3462 23.0441 29.7215 22.7674 29.9983C22.4906 30.275 22.1153 30.4305 21.7239 30.4305V30.4305ZM24.1913 22.9045C22.9952 23.7072 22.8307 24.4429 22.8307 25.118C22.8307 25.392 22.7219 25.6547 22.5281 25.8484C22.3344 26.0422 22.0717 26.151 21.7977 26.151C21.5237 26.151 21.261 26.0422 21.0673 25.8484C20.8736 25.6547 20.7647 25.392 20.7647 25.118C20.7647 23.5014 21.5085 22.2161 23.0388 21.1882C24.4613 20.2335 25.2656 19.6284 25.2656 18.2981C25.2656 17.3935 24.7491 16.7066 23.6799 16.1982C23.4283 16.0787 22.8683 15.9621 22.1792 15.9702C21.3144 15.9813 20.643 16.1879 20.1257 16.604C19.1503 17.3891 19.0677 18.2435 19.0677 18.256C19.0611 18.3917 19.0279 18.5247 18.97 18.6476C18.912 18.7704 18.8305 18.8806 18.7299 18.9719C18.6294 19.0632 18.5118 19.1338 18.384 19.1797C18.2562 19.2255 18.1205 19.2458 17.9849 19.2392C17.8492 19.2327 17.7162 19.1995 17.5934 19.1415C17.4705 19.0836 17.3603 19.002 17.269 18.9015C17.1778 18.8009 17.1072 18.6834 17.0613 18.5555C17.0154 18.4277 16.9952 18.2921 17.0017 18.1564C17.0098 17.9771 17.1345 16.362 18.8294 14.9985C19.7081 14.2916 20.826 13.9242 22.1497 13.9079C23.0867 13.8969 23.967 14.0555 24.5639 14.3374C26.3502 15.1822 27.3315 16.5907 27.3315 18.2981C27.3315 20.7942 25.6633 21.915 24.1913 22.9045Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default QuestionMark;
