import { fields, createSchema, validate as _validate } from '..';

const { optionalEmail, arrayOfString, phoneNumber, shouldBeTrue } = fields;

const schema = createSchema({
  contactEmail: optionalEmail,
  preferredWaysSession: arrayOfString,
  preferredWaysContact: arrayOfString,
  contactNumber: phoneNumber,
  giveConsent: shouldBeTrue,
});

const validate = (data) => _validate(schema, data);

export default validate;
