const data = [
  {
    id: 1,
    word: 'phobia',
    definition:
      'An extreme fear or dislike of something such as arachno**phobia** the fear of spiders.',
  },
  {
    id: 2,
    word: 'phone',
    definition:
      'Short for tele**phone.** A system for transmitting the human voice over a distance using wires or radio.',
  },
  {
    id: 3,
    word: 'phonics',
    definition:
      'A method of learning to read by matching sounds to letters or letter combinations.',
  },
  {
    id: 4,
    word: 'photograph',
    definition:
      'A picture made with a camera. The image can be stored on paper, using chemicals or electronically, using a digital process.',
  },
];

export default data;
