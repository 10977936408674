import styled from '@emotion/styled';

export const Wrapper = styled.article`
  min-height: 100vh;
  width: 100%;
  display: flex;
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : 'transparent'};
  flex-direction: column;
`;

export const InnerWrapper = styled.article`
  width: 100%;
  display: flex;
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : 'transparent'};
  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
  }
  flex: 1;
`;

export const Content = styled.main`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ContentBody = styled.div`
  max-width: 1200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;

  padding-top: ${({ theme: { constants } }) =>
    constants.layout.side.top.desktop};

  padding-left: ${({ theme: { constants } }) =>
    constants.layout.side.leftPadding.desktop};
  padding-right: ${({ theme: { constants } }) =>
    constants.layout.side.leftPadding.desktop};

  padding-bottom: ${({ theme: { spacings } }) => spacings[8]};

  ${({ theme }) => theme.media.tablet} {
    align-items: stretch;
    min-height: ${({ theme, headerType }) =>
      `calc(70vh - ${
        headerType === 'progress'
          ? theme.constants.layout.header.progressHeight
          : theme.constants.layout.header.height
      })`};

    padding-top: ${({ theme: { constants } }) =>
      constants.layout.side.top.tablet};

    padding-left: ${({ theme: { constants } }) =>
      constants.layout.side.leftPadding.tablet};
    padding-right: ${({ theme: { constants } }) =>
      constants.layout.side.leftPadding.tablet};

    padding-bottom: ${({ theme: { spacings } }) => spacings[7]};
  }
  ${({ theme }) => theme.media.mobile} {
    padding-bottom: ${({ theme: { spacings } }) => spacings[6]};
    padding-top: ${({ theme: { constants } }) =>
      constants.layout.side.top.mobile};

    padding-left: ${({ theme: { constants } }) =>
      constants.layout.side.leftPadding.mobile};
    padding-right: ${({ theme: { constants } }) =>
      constants.layout.side.leftPadding.mobile};
  }
`;
