import styled from '@emotion/styled';
import { Col, Row } from '../../../components/Grid';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonsContainer = styled(Row)`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-content: flex-start;
  }
`;

export const ButtonWrapper = styled(Col)`
  align-self: center;
`;
