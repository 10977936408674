const Logout = ({ width, height, color, ...props }) => (
  <svg
    width={width || '25'}
    height={height || '25'}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="iconspace_Sign Out_25px">
      <path
        id="Path"
        d="M17.7162 13.2498H10.5C10.0858 13.2498 9.75 12.914 9.75 12.4998C9.75 12.0856 10.0858 11.7498 10.5 11.7498H18.0771L17.1661 10.8266C16.8752 10.5317 16.8784 10.0569 17.1732 9.76595C17.4681 9.47502 17.9429 9.47818 18.2339 9.77302L20.2339 11.7999C20.5234 12.0933 20.5218 12.5655 20.2303 12.857L18.2303 14.857C17.9374 15.1499 17.4626 15.1499 17.1697 14.857C16.8768 14.5641 16.8768 14.0892 17.1697 13.7963L17.7162 13.2498Z"
        fill={color || '#2E2E2E'}
      />
      <path
        id="Path_2"
        d="M15.75 18.1V16.5C15.75 15.5 17.25 15.5 17.25 16.5V18.1C17.25 19.8533 15.6739 21.25 13.75 21.25H8.25C6.32609 21.25 4.75 19.8533 4.75 18.1V6.9C4.75 5.14675 6.32609 3.75 8.25 3.75H13.75C15.6739 3.75 17.25 5.14675 17.25 6.9V8.5C17.25 8.91421 16.9142 9.25 16.5 9.25C16.0858 9.25 15.75 8.91421 15.75 8.5V6.9C15.75 6.00486 14.8652 5.25 13.75 5.25H8.25C7.13483 5.25 6.25 6.00486 6.25 6.9V18.1C6.25 18.9951 7.13483 19.75 8.25 19.75H13.75C14.8652 19.75 15.75 18.9951 15.75 18.1Z"
        fill={color || '#2E2E2E'}
      />
    </g>
  </svg>
);

export default Logout;
