import { useReducer } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, array, object } from 'yup';
import MDEditor from '../../../../components/MDEditor';
import theme from '../../../../theme';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? {
        ...data,
        options: data?.options || [
          [
            { pre: '', answer: '' },
            { pre: '', answer: '' },
          ],
        ],
      }
    : {
        options: [
          [
            { pre: '', answer: '' },
            { pre: '', answer: '' },
          ],
        ],
      },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});
const CombineWords = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const form = {
        ...prevState.form,
        ...data,
      };
      onChange({
        ...form,
      });
      return { form };
    });

  const addSection = () => {
    setFormData({
      options: [
        ...state.form.options,
        [
          { pre: '', answer: '' },
          { pre: '', answer: '' },
        ],
      ],
    });
  };
  const removeCorrectOptions = (i) => {
    setFormData({
      options: state.form.options.filter((e, _i) => i !== _i),
    });
  };

  const onPreChange = (value, i, arrI) => {
    setFormData({
      options: state.form.options.map((e, _i) => {
        if (_i !== i) return e;
        return e.map((sec, secI) => {
          if (secI !== arrI) return sec;
          return { ...sec, pre: value };
        });
      }),
    });
  };

  const onAnswerChange = (value, i, arrI) => {
    setFormData({
      options: state.form.options.map((e, _i) => {
        if (_i !== i) return e;
        return e.map((sec, secI) => {
          if (secI !== arrI) return sec;
          return { ...sec, answer: value };
        });
      }),
    });
  };

  return (
    <div>
      <T.P mb={2} mt={8} size="large" weight="bold">
        Sections
      </T.P>

      {errors?.options && typeof errors.options === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.options}
        </T.P>
      )}
      {state.form.options.map((section, i) => {
        return (
          <div
            style={{
              background: i % 2 ? 'white' : theme.colors.neutralLight,
              border: `1px solid ${theme.colors.neutral50}`,
              marginTop: '40px',
            }}
          >
            <T.P mb={2} mt={5} weight="bold" size="med">
              Section {i + 1}
            </T.P>
            <>
              <MDEditor
                label="Prefix text first part"
                value={section[0].pre}
                onChange={(value) => {
                  onPreChange(value, i, 0);
                }}
                height={80}
                m={{ mb: 2, mt: 5 }}
                error={
                  !section[0]?.pre && errors?.options && errors?.options[i]?.pre
                }
                helper="e.g 'un + **pack** ='"
              />
              <BasicInput
                label="Answer for the first part"
                value={section[0].answer}
                handleChange={(value) => {
                  onAnswerChange(value, i, 0);
                }}
                m={{ mb: 2, mt: 5 }}
                error={
                  !section[0]?.answer &&
                  errors?.options &&
                  errors?.options[i]?.answer
                }
                helper="e.g 'unpack'"
              />

              <MDEditor
                label="Prefix text second part"
                value={section[1].pre}
                onChange={(value) => {
                  onPreChange(value, i, 1);
                }}
                height={80}
                m={{ mb: 2, mt: 7 }}
                error={
                  !section[1]?.pre && errors?.options && errors?.options[i]?.pre
                }
                helper="e.g '+ **ed** ='"
              />
              <BasicInput
                label="Answer for the second part"
                value={section[1].answer}
                handleChange={(value) => {
                  onAnswerChange(value, i, 1);
                }}
                m={{ mb: 2, mt: 5 }}
                error={
                  !section[1]?.answer &&
                  errors?.options &&
                  errors?.options[i]?.answer
                }
                helper="e.g 'unpacked'"
              />
            </>

            {state.form.options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeCorrectOptions(i)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </div>
        );
      })}

      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addSection}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add section
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      array()
        .of(
          object()
            .shape({
              pre: string().required('required field'),
              answer: string().required('required field'),
            })
            .required('required field')
        )
        .length(2)
    )
    .required('You must provide sections')
    .min(1, 'You must provide sections'),
};
export { validationSchema };

export default CombineWords;
