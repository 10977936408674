import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 24px 38px 0 24px;
  ${({ theme }) => theme.media.tablet} {
    padding: ${({ theme }) => theme.spacings[4]};
  }
`;

export const Link = styled.a`
  font-family: 'EB Garamond' !important;
  color: ${({ theme }) => theme.colors.primaryMain};
  text-decoration: underline !important;
`;
