import { useEffect, useRef } from 'react';
import * as S from './style';

const EmptySpace = ({ isActive, children, disabled, preFilled, ...props }) => {
  const elem = useRef();

  useEffect(() => {
    if (isActive) {
      elem.current.focus({ preventScroll: true });
    }
  }, [isActive]);

  const Component = children?.length > 0 ? S.Wrapper : S.DivWrapper;

  return (
    <Component
      ref={elem}
      size="default"
      tabIndex={disabled ? '-1' : '0'}
      autoFocus={isActive}
      isActive={isActive}
      preFilled={preFilled}
      {...props}
    >
      {children}
    </Component>
  );
};

export default EmptySpace;
