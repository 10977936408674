import React from 'react';
import PropTypes from 'prop-types';
import { compiler } from 'markdown-to-jsx';
import * as T from '../Typography';
import * as S from './style';

const StrongUnderlined = (props) => (
  <S.StrongUnderlinedWrapper>
    <S.StrongUnderlined {...props} />
  </S.StrongUnderlinedWrapper>
);

const Markdown = ({
  text,
  customStyles = {
    h1: {},
    h2: {},
    h3: {},
    p: {},
    a: {},
    pre: {},
    span: {},
    li: {},
    ul: {},
  },
  customRender = {},
  showStrongUnderlined,
  coachNotesStyle = false,
}) => {
  const overrides = {
    h1: (props) => <T.H1 {...customStyles.h1} {...props} />,
    h2: (props) => <T.H2 {...customStyles.h2} {...props} />,
    h3: (props) => <T.H3 {...customStyles.h3} {...props} />,
    p: (props) =>
      customRender?.p ? (
        customRender.p({ ...props })
      ) : (
        <T.P {...customStyles.p} {...props} />
      ),
    a: (props) => <T.Link {...customStyles.a} {...props} />,
    pre: (props) => <T.Pre {...customStyles.pre} {...props} />,
    ol: (props) => <S.Ol>{props.children}</S.Ol>,
    li: (props) =>
      customRender?.li ? (
        customRender.li({ ...props })
      ) : (
        <T.Li {...customStyles.li} {...props} />
      ),
    blockquote: (props) => {
      return customRender?.blockquote ? (
        customRender.blockquote({
          ...props,
        })
      ) : (
        <blockquote {...customStyles.li} {...props} />
      );
    },
    span: (props) =>
      customRender?.span ? (
        customRender.span({ ...props })
      ) : (
        <span {...props} />
      ),
    strong: showStrongUnderlined
      ? (props) => StrongUnderlined(props)
      : undefined,
    ul: (props) => (
      <S.Ul coachNotesStyle={coachNotesStyle} {...customStyles.ul} {...props} />
    ),
  };

  if (typeof text !== 'string') {
    // eslint-disable-next-line no-console
    console.error(
      `Markdown: invalid type passed as prop. Received ${typeof text} with value ${text}`
    );
    return null;
  }
  return compiler(text, {
    overrides,
    forceBlock: true,
    wrapper: React.Fragment,
  });
};

Markdown.propTypes = {
  text: PropTypes.string.isRequired,
  customStyles: PropTypes.object,
};

export default Markdown;
