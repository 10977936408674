import theme from '../../../theme';

const Device = ({ width, height, color, ...props }) => (
  <svg
    width={width || '48'}
    height={height || '48'}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4802 9.59984H11.5202V8.63984C11.5202 6.00528 13.5191 3.83984 16.0248 3.83984H31.9756C34.4813 3.83984 36.4802 6.00528 36.4802 8.63984V9.59984ZM36.4795 36.4799V39.3599C36.4795 41.9944 34.4807 44.1599 31.9749 44.1599H16.0241C13.5184 44.1599 11.5195 41.9944 11.5195 39.3599V36.4799H36.4795ZM25.9197 40.3199H22.0797C21.5495 40.3199 21.1197 39.8901 21.1197 39.3599C21.1197 38.8297 21.5495 38.3999 22.0797 38.3999H25.9197C26.4498 38.3999 26.8797 38.8297 26.8797 39.3599C26.8797 39.8901 26.4498 40.3199 25.9197 40.3199Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5205 11.5205H36.4805V34.5605H11.5205V11.5205Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Device;
