import { Row, Col } from '../../../../components/Grid';
import * as T from '../../../../components/Typography';
import { IconWithParagraph } from '../../../../components';
import { BasicButton } from '../../../../components/Button';
import { navRoutes } from '../../../../constants';

const Qualities = () => {
  return (
    <Row>
      <Col w={[4, 9, 9]} dir="column" ai="flex-start">
        <T.H2>What personal qualities do I need?</T.H2>
        <T.P mt="3">A good coach will be...</T.P>

        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>Patient</T.P>
        </IconWithParagraph>

        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>Non-judgemental</T.P>
        </IconWithParagraph>

        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>Encouraging and motivating</T.P>
        </IconWithParagraph>

        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>Interested in learning and helping others</T.P>
        </IconWithParagraph>

        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>Respectful towards others</T.P>
        </IconWithParagraph>

        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>Organised and committed</T.P>
        </IconWithParagraph>
        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>
            Confident to ask someone for help and advice if you are unsure
          </T.P>
        </IconWithParagraph>
      </Col>
      <Col w={[4, 6, 6]}>
        <BasicButton mt="9" to={navRoutes.COACH.DASHBOARD}>
          <T.P color="white" weight="semi">
            Return to dashboard
          </T.P>
        </BasicButton>
      </Col>
    </Row>
  );
};
export default Qualities;
