import { Row, Col } from '../../../../components/Grid';
import * as T from '../../../../components/Typography';
import { IconWithParagraph } from '../../../../components';
import { BasicButton } from '../../../../components/Button';
import { navRoutes } from '../../../../constants';

const Advice = () => {
  return (
    <Row>
      <Col w={[4, 9, 9]} dir="column" ai="flex-start">
        <T.H2>Advice on how to be a good coach</T.H2>
        <T.P mt="3">
          Every coach is individual and you will find the right style for you
          and each of your learners. Below though we’ve provided some quick tips
          to give you the best start.
        </T.P>

        <T.H2 mt="6">Three quick tips</T.H2>
        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>Actively listen to others and show empathy.</T.P>
        </IconWithParagraph>

        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>Support adult learning in a non-patronising way.</T.P>
        </IconWithParagraph>

        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>Give positive feedback to learners.</T.P>
        </IconWithParagraph>

        <T.H2 mt="6">What do I need to know?</T.H2>
        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>
            Understand our learning materials and how they work. You can always
            see what exercise the learner is due to complete before your next
            session and read the coach notes for advice on what you need to do
            in each exercise.
          </T.P>
        </IconWithParagraph>

        <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
          <T.P>
            Understand some of the barriers to learning that people can face.
          </T.P>
        </IconWithParagraph>
      </Col>
      <Col w={[4, 6, 6]}>
        <BasicButton mt="9" to={navRoutes.COACH.DASHBOARD}>
          <T.P color="white" weight="semi">
            Return to dashboard
          </T.P>
        </BasicButton>
      </Col>
    </Row>
  );
};

export default Advice;
