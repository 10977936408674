import * as S from './style';
import * as T from '../../Typography';
import { Col, Row } from '../../Grid';

const ImageWithText = ({ options = [], value = [], setValue, single }) => {
  const setValues = (selectedItem) => {
    if (single) {
      if (value[0] === selectedItem) {
        return setValue([]);
      } else {
        return setValue([selectedItem]);
      }
    } else {
      if (value.includes(selectedItem)) {
        return setValue(value.filter((item) => item !== selectedItem));
      } else {
        return setValue([...value, selectedItem]);
      }
    }
  };
  return (
    <Row inner>
      {options.map((item, index) => (
        <Col
          w={[2, 4, 4]}
          key={item.value + index}
          style={{ paddingLeft: '2px' }}
        >
          <S.Item
            imageUrl={item.imageUrl}
            notSelected={value.includes(item.value)}
            key={item.value + index}
            onClick={() => setValues(item.value)}
          >
            <T.P color="white" ta="center">
              {item.label}
            </T.P>
            {value.includes(item.value) && (
              <S.IconWrapper
                icon="check"
                color="neutralLight"
                width="25px"
                height="25px"
              />
            )}
          </S.Item>
        </Col>
      ))}
    </Row>
  );
};

export default ImageWithText;
