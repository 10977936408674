import { BasicButton } from '../../components/Button';
import { Col, Row } from '../../components/Grid';

import * as T from '../../components/Typography';
import { navRoutes } from '../../constants';

const ThankYou = () => {
  return (
    <Row>
      <Col w={[4, 12, 12]}>
        <T.H2 weight="bold" color="neutralMain">
          Thank you for submitting the referral form
        </T.H2>
      </Col>
      <Col w={[4, 6, 6]}>
        <T.P mt={5} mb={6}>
          We will contact you within 48 hours.
        </T.P>
        <BasicButton
          to={navRoutes.EXTERNAL.REFER_A_LEARNER}
          external
          variant="primary"
        >
          Return home
        </BasicButton>
      </Col>
    </Row>
  );
};

export default ThankYou;
