import styled from '@emotion/styled';
import Icon from '../../../components/Icon';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  flex: ${({ flex }) => flex};
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
