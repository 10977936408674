import { useState, useEffect } from 'react';

import * as S from './style.js';
import MealsGrid from './MealsGrid';
import Details from './Details';
import SelectRecipesQuestion from '../Question/SelectRecipes';
import { exerciseTypes } from '../../../../../constants';

const Recipes = ({ type, ...props }) => {
  const {
    data: { correctOptions = [] },
  } = props;
  const [userAnswers, setUserAnswers] = useState([]);
  const [enableNext, setEnableNext] = useState(false);

  const handleSelect = (id) => {
    setUserAnswers((old) => {
      if (old.indexOf(id) > -1) {
        return old.filter((ans) => ans !== id);
      } else {
        return [...old, id];
      }
    });
  };

  useEffect(() => {
    if (
      userAnswers.length === correctOptions.length &&
      correctOptions.every((ans) => userAnswers.indexOf(ans.option) > -1)
    ) {
      setEnableNext(true);
    } else {
      setEnableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAnswers.length]);

  return (
    <>
      <S.Container>
        <Details />
        <MealsGrid
          handleSelect={handleSelect}
          userAnswers={userAnswers}
          type={type}
        />
      </S.Container>
      {type === exerciseTypes.SELECT_RECIPES && (
        <SelectRecipesQuestion {...props} enableNext={enableNext} />
      )}
    </>
  );
};

export default Recipes;
