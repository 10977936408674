import { useAuth } from '../../context/auth';
import * as SC from './StatsComponents';
import * as S from './style';

const statsComponents = {
  exercisesCompleted: SC.ExercisesCompleted,
  learningTime: SC.LearningTime,
  coachingOnTheApp: SC.CoachingOnTheApp,
  noOfLearners: SC.NoOfLearners,
  newWords: SC.NewWords,
  milestoneReached: SC.MilestoneReached,
  dateOfLastTaskCompletion: SC.DateOfLastTaskCompletion,
  dateOfLastSessionWithCoach: SC.DateOfLastSessionWithCoach,
};

const ProfileStats = ({ type, backgroundColor, ...props }) => {
  const { user } = useAuth();
  const Component = statsComponents[type];
  if (!type || !Component) {
    // eslint-disable-next-line no-console
    console.error(
      'type prop is required ',
      'should be one of: [',
      '`exercisesCompleted`',
      '`learningTime`',
      '`newWords`',
      '`milestoneReached`',
      '`dateOfLastTaskCompletion`',
      '`dateOfLastSessionWithCoach`',
      ']'
    );

    return null;
  }

  return (
    <S.Wrapper type={type} backgroundColor={backgroundColor} {...props}>
      <Component userRole={user.role} {...props} />
    </S.Wrapper>
  );
};

export default ProfileStats;
