import { DatePicker as AntdDatePicker } from 'antd';
import * as S from './style';
import * as T from '../../Typography';
import DatePicker from '../NewDatePicker';

export const OldDatePicker = (props) => {
  const {
    value,
    handleChange,
    label,
    helper,
    color,
    optional,
    error,
    placeholder,
  } = props;
  const decideColor = () => {
    if (error) return 'error';
    if (color) return color;
    return 'neutralMain';
  };
  return (
    <>
      {label && (
        <S.Label htmlFor={label} mb={helper ? '1' : '2'}>
          <T.H3 m="0">{label}</T.H3>
          {optional && <S.OptionalTag ml="1">(optional)</S.OptionalTag>}
        </S.Label>
      )}
      {helper && (
        <T.P weight="small" color={decideColor()} mb="2">
          {helper}
        </T.P>
      )}
      <S.PickerWrapper error={error} {...props}>
        <AntdDatePicker
          format={'DD-MM-YYYY'}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          suffixIcon={null}
        />
      </S.PickerWrapper>
      {error && (
        <T.P color="neutralMain" m="0" ml="3" mt="2">
          {error}
        </T.P>
      )}
    </>
  );
};

export default DatePicker;
