const data = [
  {
    word: 'phobia',
    definition:
      'An extreme fear or dislike of something such as arachno**phobia** the fear of spiders.',
    examples: [
      { word: 'insecto**phobia**', meaning: 'fear of insects' },
      { word: 'techno**phobia**', meaning: 'fear of technology' },
      { word: 'agora**phobia**', meaning: 'fear of open spaces' },
      { word: 'insecto**phobia**', meaning: 'fear of heights' },
    ],
  },
  {
    word: 'phone',
    definition:
      'Short for tele**phone.** A system for transmitting the human voice over a distance using wires or radio.',
    examples: [
      { word: 'tele**phone**', meaning: '' },
      { word: 'micro**phone**', meaning: '' },
      { word: 'head**phone**', meaning: '' },
    ],
  },
];

export default data;
