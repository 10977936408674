import ListenAndRepeat from './ListenAndRepeat';
import ListenAndRepeatMultiple from './ListenAndRepeatMultiple';
import DragAndDrop1 from './DragAndDrop1';
import DragAndDrop2 from './DragAndDrop2';
import DragAndDropOrderSounds from './DragAndDropOrderSounds';
import SpotTheWord from './SpotTheWord';
import WordBuild from './WordBuild';
import VowelBreak from './VowelBreak';
import ListenAndRepeatGrid from './ListenAndRepeatGrid';
import ListenAndRepeatGridHidden from './ListenAndRepeatGridHidden';
import MultipleChoice from './MultipleChoice';
import WordJoin1 from './WordJoin1';
import WordJoin2 from './WordJoin2';
import SentenceFill from './SentenceFill';
import Typing from './Typing';
import CombineWordsPre from './CombineWordsPre';
import CombineWords from './CombineWords';
import SelectRecipes from './SelectRecipes';
import MultipleChoiceComp from './MultipleChoiceComp';
import MultipleChoiceBubble from './MultipleChoiceBubble';
import TypeNewEndings from './TypeNewEndings';
import MultipleChoiceGrid from './MultipleChoiceGrid';
import TextFinder from './TextFinder';
import TextFinderComprehension from './TextFinderComprehension';
import ListenAndRepeatComp from './ListenAndRepeatComp';
import Reorder from './Reorder';

import { exerciseTypes, comprehensionsKeys } from '../../../../constants';

const Switch = {
  [exerciseTypes.LISTEN_AND_REPEAT]: ListenAndRepeat,
  [exerciseTypes.LISTEN_AND_REPEAT_MULTIPLE]: ListenAndRepeatMultiple,
  [exerciseTypes.DRAG_AND_DROP_1]: DragAndDrop1,
  [exerciseTypes.DRAG_AND_DROP_2]: DragAndDrop2,
  [exerciseTypes.DRAG_AND_DROP_ORDER_SOUNDS]: DragAndDropOrderSounds,
  [exerciseTypes.SPOT_THE_WORD]: SpotTheWord,
  [exerciseTypes.WORD_BUILD]: WordBuild,
  [exerciseTypes.VOWEL_BREAK]: VowelBreak,
  [exerciseTypes.LISTEN_AND_REPEAT_GRID]: ListenAndRepeatGrid,
  [exerciseTypes.LISTEN_AND_REPEAT_GRID_HIDDEN]: ListenAndRepeatGridHidden,
  [exerciseTypes.MULTIPLE_CHOICE]: MultipleChoice,
  [exerciseTypes.WORD_JOIN_1]: WordJoin1,
  [exerciseTypes.WORD_JOIN_2]: WordJoin2,
  [exerciseTypes.SENTENCE_FILL]: SentenceFill,
  [exerciseTypes.COMBINE_WORDS_PRE]: CombineWordsPre,
  [exerciseTypes.COMBINE_WORDS]: CombineWords,
  [exerciseTypes.MULTIPLE_CHOICE_BUBBLE]: MultipleChoiceBubble,
  [exerciseTypes.TYPE_NEW_ENDINGS]: TypeNewEndings,
  [exerciseTypes.MULTIPLE_CHOICE_GRID]: MultipleChoiceGrid,
  [exerciseTypes.TEXT_FINDER]: TextFinder,
  [exerciseTypes.COMPREHENSION_TEXT_FINDER]: TextFinderComprehension,
};

const ComprehensionSteps = {
  [exerciseTypes.MULTIPLE_CHOICE]: MultipleChoiceComp,
  [exerciseTypes.REORDER]: Reorder,
  [exerciseTypes.COMPREHENSION_LISTEN_AND_RESPOND]: ListenAndRepeatComp,
  [exerciseTypes.TYPING]: Typing,
  [exerciseTypes.SELECT_RECIPES]: SelectRecipes,
};
const StepForms = ({ type, exercise, ...props }) => {
  if (comprehensionsKeys.includes(exercise.key)) {
    const Component = ComprehensionSteps[type];

    return type ? <Component {...props} /> : null;
  }

  const Component = Switch[type];

  return type ? <Component {...props} /> : null;
};

export default StepForms;
