import styled from '@emotion/styled';
import setMargin from '../../../helpers/set-margin';

export const Field = styled.div`
  ${setMargin};
  width: ${({ w }) => w || '100%'};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  padding: ${({ plain, noPadding }) => {
    if (noPadding) {
      return 0;
    }
    return plain ? '0 20px 12px 0px' : '13px 16px';
  }};

  background-color: ${({ theme, plain }) =>
    plain ? '' : `${theme.colors.neutralLight} !important`};

  border-radius: ${({ theme }) => `${theme.borders.radius} !important`};

  .ant-checkbox {
    padding: 4px 0 4px !important;
  }
  .ant-checkbox-checked:after {
    border: none !important;
    outline: none !important;
  }

  .ant-checkbox-wrapper:focus-within {
    .ant-checkbox-inner {
      outline-style: ${({ theme, avoidClick }) =>
        avoidClick ? `none` : `solid`};
      outline-width: 3px;
      outline-color: ${({ theme, avoidClick }) =>
        avoidClick
          ? `transparent !important`
          : `${theme.colors.primaryMain} !important`};
      outline-offset: 1px;
    }
  }

  .ant-checkbox-wrapper:hover {
    .ant-checkbox-checked .ant-checkbox-inner {
      background: ${({ theme, avoidClick }) =>
        !avoidClick && `${theme.colors.secondaryMain} !important`};
      border-color: ${({ theme, avoidClick }) =>
        !avoidClick && `${theme.colors.primaryMain} !important`};
    }
  }
  .ant-checkbox-wrapper:hover {
    .ant-checkbox-inner {
      border-color: ${({ theme, avoidClick }) =>
        !avoidClick && `${theme.colors.primaryMain} !important`};
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ checkedBgColor, theme }) =>
      `${
        theme.colors[checkedBgColor] ||
        checkedBgColor ||
        theme.colors.primaryMain
      } !important`};
    border-color: ${({ theme }) => `${theme.colors.white} !important`};
    border-radius: ${({ theme }) => `${theme.borders.radius} !important`};
    border-width: 2px;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: ${({ theme }) => `${theme.colors.white} !important`};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme, color, avoidClick }) => {
      if (avoidClick) return;
      return color ? theme.colors[color] : theme.colors.black;
    }};
  }

  .ant-checkbox-inner {
    background: ${({ theme }) => theme.colors.white};

    width: 33px;
    height: 33px;
    background-color: ${({ theme, checkboxBgColor }) =>
      checkboxBgColor || `${theme.colors.neutralLight} !important`};
    border-color: ${({ theme, borderColor, error }) =>
      error
        ? `${theme.colors.error} !important`
        : `${
            theme.colors[borderColor] || theme.colors.neutralMain
          } !important`};
    border-radius: ${({ theme }) => `${theme.borders.radius} !important`};
    border-width: 2px;
  }

  .ant-checkbox-input {
    width: 16px;
    height: 16px;
  }

  .ant-checkbox {
    top: ${({ plain }) => (plain ? '0px' : '0')};
  }

  .ant-checkbox-inner::after {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8px;
    height: 14px;
    margin-top: -1px;
    border-width: 3px;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: start !important;
    width: fit-content;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    width: 10px;
    height: 18px;
    width: 10px;
    height: 18px;
  }

  .ant-checkbox + span {
    align-self: stretch;
    display: flex;
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const LabelWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
`;
