import * as S from './style';

const Tag = ({ color = 'neutralLight', ...props }) => {
  return (
    <S.Wrapper color={color} {...props}>
      {props.children}
    </S.Wrapper>
  );
};

export default Tag;
