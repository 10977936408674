export const list1 = [
  { key: 1, text: '1 medium onion' },
  { key: 2, text: 'Olive oil or butter (2 tablespoons)' },
  { key: 3, text: 'Risotto rice (2 cups)' },
  { key: 4, text: '1 glass dry white wine (optional)' },
  { key: 5, text: 'Chicken stock (4 cups)' },
  { key: 6, text: 'Parmesan cheese' },
  { key: 7, text: 'Salt and pepper' },
];

export const list2 = [
  {
    key: 1,
    text: 'Finely slice one onion and fry it in light oil until soft. (Don’t put the heat too high.)',
  },
  {
    key: 2,
    text: 'Add 2 cups of risotto rice to the pan. Stir it in with the fried onions for a short while until the rice grains are all shiny with oil.',
  },
  {
    key: 3,
    text: 'Turn the heat up slightly and add (optional) dry white wine.',
  },
  {
    key: 4,
    text: 'Now add chicken stock a little at a time. Keep stirring until the stock has been absorbed. (You will need 4 cups of stock.)',
  },
  {
    key: 5,
    text: 'Add salt and pepper to taste and slices of parmesan cheese.',
  },
  {
    key: 6,
    text: 'Scatter with chopped chives or basil to finish or spice it up with other nice veg such as peas or peppers.',
  },
];
