import * as S from './style.js';
import * as T from '../../../../../components/Typography';

const Details = () => {
  return (
    <S.DetailsWrapper>
      <S.HeadingWrapper>
        <T.P weight="bold">Menu</T.P>
        <T.P style={{ width: 240 }}>
          Week beginning
          <T.P weight="bold" ml="1" display="inline">
            AUGUST 9th
          </T.P>{' '}
          <T.P weight="bold">2015</T.P>
        </T.P>
      </S.HeadingWrapper>
      <S.DetailsGrid>
        <S.DetailsCol1>
          <T.P style={{ maxWidth: 300 }}>
            Tick your choices and put the completed menu in the post boxes in
            the dining room by:{' '}
            <T.P weight="bold" ml="1" display="inline">
              18.00 Hours FRIDAY
            </T.P>
          </T.P>
        </S.DetailsCol1>
        <S.DetailsCol2>
          <S.DetailsCell>
            <T.P weight="bold">Name:</T.P>
            <T.P weight="bold">Number:</T.P>
          </S.DetailsCell>
          <S.DetailsCell>
            <div>
              <T.P weight="bold">Key</T.P>
              <T.P>😌 Healthy eating</T.P>
              <T.P>🥘 Halal</T.P>
              <T.P>🥗 Vegetarian</T.P>
            </div>
          </S.DetailsCell>
        </S.DetailsCol2>
      </S.DetailsGrid>
    </S.DetailsWrapper>
  );
};

export default Details;
