import theme from '../../../theme';

const ArrowUp = ({ width, height, color, ...props }) => (
  <svg
    width={width || '11'}
    height={height || '16'}
    viewBox="0 0 11 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.59334 3.16487L2.31774 5.44047C1.96725 5.79095 1.399 5.79095 1.04851 5.44047C0.698023 5.08998 0.698023 4.52172 1.04851 4.17124L4.8562 0.363543C5.20669 0.0130539 5.77495 0.013054 6.12543 0.363543L9.93313 4.17124C10.2836 4.52172 10.2836 5.08998 9.93313 5.44047C9.58264 5.79095 9.01438 5.79095 8.6639 5.44047L6.3883 3.16487L6.3883 14.9597C6.3883 15.4554 5.98648 15.8572 5.49082 15.8572C4.99515 15.8572 4.59334 15.4554 4.59334 14.9597L4.59334 3.16487Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);
export default ArrowUp;
