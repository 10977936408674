import theme from '../../../theme';

const Lock = ({ width, height, color, ...props }) => (
  <svg
    width={width || '48'}
    height={height || '48'}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.7604 24.9597V16.7997C29.7604 13.3534 27.4466 10.5597 24.0004 10.5597C20.5541 10.5597 18.2404 13.3534 18.2404 16.7997V24.9597H15.3604V16.7997C15.3604 11.7629 18.9635 7.67969 24.0004 7.67969C29.0372 7.67969 32.6404 11.7629 32.6404 16.7997V24.9597H29.7604Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      d="M12.4795 21.1201H35.5195C36.0497 21.1201 36.4795 21.5499 36.4795 22.0801V39.3601C36.4795 39.8903 36.0497 40.3201 35.5195 40.3201H12.4795C11.9493 40.3201 11.5195 39.8903 11.5195 39.3601V22.0801C11.5195 21.5499 11.9493 21.1201 12.4795 21.1201Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);
export default Lock;
