import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';
import { Row } from '../../../components/Grid';

export const PlayButtonWrapper = styled.div`
  ${setMargin}
  display: flex;
  align-items: flex-start;
`;

export const NextButtonWrapper = styled(Row)`
  flex: 1;
  max-width: 600px;
  justify-content: center;
`;

export const Wrapper = styled.div`
  max-width: 600px;
`;
