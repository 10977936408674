const data = {
  firstList: [
    {
      id: 1,
      text: 'Do not take this medicine more than 4 times in a day (24 hours).',
    },
    {
      id: 2,
      text: 'Do not take this medicine more often than every 4 hours.',
    },
    {
      id: 3,
      text: 'Do not take more tablets than the label tells you.',
    },
    {
      id: 4,
      text: 'Do not take for more than 3 days unless instructed by your doctor.',
    },
  ],
  secondList: [
    {
      id: 5,
      text: 'If you do not get better in 3 days.',
    },
    {
      id: 6,
      text: 'If you take too much of this medicine. (This medicine can have a serious effect on your liver.)',
    },
    {
      id: 7,
      text: 'If you have any side-effects from taking this medicine.',
    },
  ],
};

export default data;
