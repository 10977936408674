import styled from '@emotion/styled';
import { Row } from '../../../components/Grid';
import Slider from '@mui/material/Slider';

export const ImageWrapper = styled.div`
  max-width: 100vw;
  margin: 0;
  ${({ theme }) => theme.media.mobile} {
    margin: -6% -6% 0px -4.5%;
  }
`;

export const ButtonWrapper = styled(Row)`
  flex: 1;
  align-items: flex-end;
`;

export const StyledSliderInput = styled(Slider)`
  margin-bottom: 42px;
  .MuiSlider-thumb {
    background-color: ${({ theme }) => theme.colors.white} !important;
    box-shadow: none !important;
    border: 5px solid ${({ theme }) => theme.colors.neutralMain} !important;

    width: 35px;
    height: 35px;
  }
  .MuiSlider-thumb:hover {
    border: 5px solid ${({ theme }) => theme.colors.primaryMain} !important;
  }
  .MuiSlider-thumb:focus {
    border: 5px solid ${({ theme }) => theme.colors.neutralMain} !important;
    background-color: ${({ theme }) => theme.colors.white} !important;
    box-shadow: none !important;
    outline-color: ${({ theme }) => theme.colors.primaryMain} !important;
    outline-style: solid !important;
    outline-width: 3px !important;
    outline-offset: 0.2em !important;
    width: 35px;
    height: 35px;
  }
  .MuiSlider-rail {
    background-color: ${({ theme }) => theme.colors.neutralMain80};
    height: 0.4rem;
    border-radius: 0.2rem;
    border-color: ${({ theme }) => theme.colors.neutralMain80};
  }
  .MuiSlider-track {
    background-color: ${({ theme }) => theme.colors.neutralMain80};
    height: 0.4rem;
    border-radius: 0.2rem;
    border-color: ${({ theme }) => theme.colors.neutralMain80};
  }

  .MuiSlider-mark {
    display: none;
  }
  .MuiSlider-markLabel {
    font-size: 1.0625rem;
    padding-top: 0.9rem;
  }
`;

export const Wrapper = styled.div`
  max-width: 600px;
`;

export const NextButtonWrapper = styled(Row)`
  flex: 1;
  max-width: 600px;
  align-content: flex-end;
`;

export const OuterWrapper = styled(Row)`
  max-width: 600px;
  width: 600px;
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;
