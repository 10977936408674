import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import { LEARNER, COACH } from '../../../constants/nav-routes';
import * as S from './style';

import IconWithBackgroundWithText from '../../../components/IconWithBackgroundWithText';
const Onboarding4 = () => {
  return (
    <>
      <S.Wrapper>
        <Row>
          <Col w={[4, 9, 9]}>
            <T.H2 color="neutralMain">
              These are some of the key icons, your coach will explain what they
              mean
            </T.H2>
          </Col>
        </Row>
        <Row mt="4">
          <Col w={[4, 4, 4]}>
            <IconWithBackgroundWithText icon="next" text="Next" />
          </Col>
        </Row>

        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="back" text="Back" />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="tick"
              text="Complete"
              iconProps={{ width: '16px', height: '16px' }}
            />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="questionMark"
              text="Save parking page (bookmark)"
              iconProps={{ width: '36px', height: '36px' }}
            />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="bookmarkSaved"
              text="Parking page (bookmark) saved"
              iconProps={{ width: '36px', height: '36px' }}
            />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="profileEye"
              text="Add coloured overlay"
              iconProps={{ width: '32px', height: '32px' }}
            />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="profile" text="Profile" />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="speaker" text="Speaker" />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="lock" text="Exercise locked" />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="byYourSelf"
              text="By yourself"
              iconProps={{ width: '48px', height: '48px' }}
            />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="withCoach"
              text="With your coach"
              iconProps={{ width: '48px', height: '48px' }}
            />
          </Col>
        </Row>

        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="note" text="Coach notes" />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="like" text="Success" />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="cup" text="Exercise complete" />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="tryAgain" text="Try again" />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="milestone"
              text="Milestone"
              iconProps={{ color: 'neutralMain' }}
            />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="book"
              text="Book"
              iconProps={{ color: 'neutralMain' }}
            />
          </Col>
        </Row>
      </S.Wrapper>

      <S.NextButtonWrapper mt="6">
        <Col w={[4, 9, 9]}>
          <BasicButton
            variant="primary"
            icon="next"
            iconProps={{ width: '24px', height: '24px' }}
            to={LEARNER.LEARNING_BY_YOURSELF_OR_WITH_COACH}
          />
        </Col>
        <Col w={[4, 9, 9]} mt="3">
          <BasicButton variant="secondary" to={COACH.LEARNERS}>
            <T.P color="white" weight="semi">
              Exit onboarding
            </T.P>
          </BasicButton>
        </Col>
      </S.NextButtonWrapper>
    </>
  );
};

export default Onboarding4;
