import styled from '@emotion/styled';

export const StrongUnderlinedWrapper = styled.div`
  display: inline;
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.neutralMain}`};
  top: -4px;
  position: relative;
`;
export const StrongUnderlined = styled.strong`
  position: relative;
  top: 4px;
`;

export const Ul = styled.ul`
  list-style: ${({ coachNotesStyle }) =>
    coachNotesStyle ? 'none' : 'inherit'};
  li {
    color: ${({ coachNotesStyle, theme }) =>
      coachNotesStyle ? theme.colors.neutralMain : 'inherit'};
    list-style: ${({ coachNotesStyle }) =>
      coachNotesStyle ? 'none' : 'inherit'};

    display: ${({ coachNotesStyle }) => coachNotesStyle && 'flex'};
    margin-top: ${({ theme }) => theme.spacings[2]} !important;
  }
  li:before {
    content: '✓';
    font-size: 1.25rem;
    display: flex;
    justify-content: flex-start;
    margin-top: 0px;
    margin-right: 10px;
    display: ${({ coachNotesStyle }) => (coachNotesStyle ? 'flex' : 'none')};
  }
`;

export const Ol = styled.ol`
  margin-left: ${({ theme }) => theme.spacings[4]} !important;
  li::marker {
    color: ${({ theme }) => theme.colors.neutralMain};
    font-size: 1rem !important;
    line-height: 24px !important;
    font-weight: 400 !important;
  }
  > li:before {
    content: '';
  }
  ul li:before {
    margin-top: 0px;
  }
`;
