import { useFetch, usePost, formatQueryError } from '../utils/queries';
import { PROGRESS_CHECKS_BASE } from '../constants';

function useGetStepsByMilestoneId({ milestoneId }) {
  const context = useFetch(
    `${PROGRESS_CHECKS_BASE}/${milestoneId}/steps`,
    undefined
  );
  return {
    ...context,
    steps: context.data,
    error: formatQueryError(context),
  };
}

function useCreateCompletedProgressCheck({ milestoneId }) {
  return usePost(`${PROGRESS_CHECKS_BASE}/${milestoneId}/completed`, undefined);
}

export { useGetStepsByMilestoneId, useCreateCompletedProgressCheck };
