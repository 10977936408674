import * as S from './style';
import Icon from '../Icon';
import { useAuth } from '../../context/auth';
const Loading = () => {
  const { user } = useAuth();

  return (
    <S.Wrapper overlayColor={user.overlayColor}>
      <S.LoadingContainer>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </S.LoadingContainer>
      <div
        style={{
          width: 50,
          height: 50,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Icon icon="openedBook" color="neutralMain" width="50" height="50" />
      </div>
    </S.Wrapper>
  );
};

export default Loading;
