const Calendar = ({ width, height, color, ...props }) => (
  <svg
    width={width || '41'}
    height={height || '40'}
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.6997 4.0002V7.2002C27.6997 7.64202 28.0579 8.0002 28.4997 8.0002C28.9415 8.0002 29.2997 7.64202 29.2997 7.2002V4.0002C29.2997 3.55837 28.9415 3.2002 28.4997 3.2002C28.0579 3.2002 27.6997 3.55837 27.6997 4.0002ZM26.0997 4.8002H14.8997V7.2002C14.8997 8.52568 13.8252 9.6002 12.4997 9.6002C11.1742 9.6002 10.0997 8.52568 10.0997 7.2002V4.8002H5.29971C4.41605 4.8002 3.69971 5.51654 3.69971 6.4002V11.2002H37.2997V6.4002C37.2997 5.51654 36.5834 4.8002 35.6997 4.8002H30.8997V7.2002C30.8997 8.52568 29.8252 9.6002 28.4997 9.6002C27.1742 9.6002 26.0997 8.52568 26.0997 7.2002V4.8002ZM37.3005 29.6004V12.8004H3.70049V29.6004C3.70049 32.6932 6.20769 35.2004 9.30049 35.2004H31.7005C34.7933 35.2004 37.3005 32.6932 37.3005 29.6004ZM10.9005 19.2004H17.3005C17.7423 19.2004 18.1005 18.8422 18.1005 18.4004C18.1005 17.9586 17.7423 17.6004 17.3005 17.6004H10.9005C10.4587 17.6004 10.1005 17.9586 10.1005 18.4004C10.1005 18.8422 10.4587 19.2004 10.9005 19.2004ZM23.7005 25.6004H10.9005C10.4587 25.6004 10.1005 25.2422 10.1005 24.8004C10.1005 24.3586 10.4587 24.0004 10.9005 24.0004H23.7005C24.1423 24.0004 24.5005 24.3586 24.5005 24.8004C24.5005 25.2422 24.1423 25.6004 23.7005 25.6004ZM11.6997 7.2002V4.0002C11.6997 3.55837 12.0579 3.2002 12.4997 3.2002C12.9415 3.2002 13.2997 3.55837 13.2997 4.0002V7.2002C13.2997 7.64202 12.9415 8.0002 12.4997 8.0002C12.0579 8.0002 11.6997 7.64202 11.6997 7.2002Z"
      fill={color || 'white'}
    />
  </svg>
);
export default Calendar;
