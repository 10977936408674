import { useState } from 'react';
import { Select as AntdSelect } from 'antd';
import { css } from '@emotion/react';

import * as T from '../../Typography';
import * as S from './style';
import * as CS from './../style';
import Icon from '../../Icon';

import Dropdown from '../NewDropDown';
const { Option: AntdOption, OptGroup } = AntdSelect;

export const OldDropdown = ({
  handleChange,
  label,
  color,
  placeholder = 'Select...',
  error,
  helper,
  w,
  disabled,
  options = [],
  selected,
  multi,
  m,
  search,
  addNew,
  optional,
  allowClear = true,
  key,
  optionFilterProp,
}) => {
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);

  const decideColor = () => {
    if (error) return 'error';
    return color;
  };
  // TODO: Add hover and clicked background colour states for dropdown
  const renderOptions = (a) => {
    return options.map((e) => {
      const { value: _value, label: _label } = e;
      return (
        <AntdOption
          className={css`
            ${S.OptionStyle}
          `}
          key={e.options ? _value + 'Group' : _value}
          value={_value}
          label={_label}
          aria-label="Dropdown menu"
          {...e}
        >
          {_label}
        </AntdOption>
      );
    });
  };
  const renderOptionsGroup = (a) => {
    return options.map((e) => {
      if (!e.options) {
        return null;
      }

      return (
        <OptGroup label={e.label}>
          {e.options.map((opt) => (
            <AntdOption value={opt.value} key={opt.value + e.label}>
              {opt.label}
            </AntdOption>
          ))}
        </OptGroup>
      );
    });
  };

  const handleSearch = (input, option) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        ? true
        : false;
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        ? true
        : false;
    }
  };

  const decideMode = () => {
    if (addNew) return 'tags';
    if (multi) return 'multiple';
    return;
  };

  return (
    <S.Field
      w={w}
      disabled={disabled}
      open={open}
      focus={focus}
      multi={multi}
      color={decideColor()}
      error={error}
      search={search}
      {...m}
    >
      {label && (
        <CS.Label htmlFor={label} mb={helper ? '1' : '2'}>
          <T.P weight="bold" color={decideColor()} m="0">
            {label}
          </T.P>
          {optional && <CS.OptionalTag ml="1">(optional)</CS.OptionalTag>}
        </CS.Label>
      )}
      {helper && (
        <T.P isSmall color="neutralDark" mb="2">
          {helper}
        </T.P>
      )}
      <S.Answer>
        <AntdSelect
          key={key}
          value={selected || undefined}
          aria-label="Select"
          onSelect={(val, option) =>
            multi ? undefined : handleChange(val, option)
          }
          onChange={(val, option) =>
            multi ? handleChange(val, option) : undefined
          }
          mode={decideMode()}
          placeholder={placeholder || 'Type here...'}
          showArrow
          allowClear={allowClear}
          onDropdownVisibleChange={(open) => setOpen(open)}
          onClear={() => handleChange()}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          filterOption={addNew || handleSearch}
          optionFilterProp={optionFilterProp}
          suffixIcon={
            search ? (
              <S.StyledIcon
                icon="search"
                color="black"
                style={{ width: 20, height: 22 }}
              />
            ) : (
              <Icon
                icon="chevronDown"
                color="neutralMain"
                width="14"
                height="8"
              />
            )
          }
          showSearch={addNew || search}
          aria-disabled={disabled ? 'true' : 'false'}
        >
          {options && options[0] && options[0]?.options
            ? renderOptionsGroup()
            : renderOptions()}
        </AntdSelect>
      </S.Answer>
      {error && (
        <T.P color="error" m="0" mt="1">
          {error}
        </T.P>
      )}
    </S.Field>
  );
};

export default Dropdown;
