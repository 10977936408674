import theme from '../../../theme';

const Exercises = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '26'}
    height={height || '25'}
    viewBox="0 0 26 25"
    fill="none"
    {...props}
  >
    <g id="iconspace_Book Checklist_25px">
      <path
        id="path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2H18C19.933 2 21.5 3.567 21.5 5.5V19.5C21.5 21.433 19.933 23 18 23H8C6.067 23 4.5 21.433 4.5 19.5V5.5C4.5 3.567 6.067 2 8 2ZM12.9994 8L16.9807 8.00494C17.2568 8.00529 17.481 7.78171 17.4813 7.50556C17.4817 7.22942 17.2581 7.00529 16.9819 7.00494L13.0006 7C12.7245 6.99966 12.5003 7.22324 12.5 7.49938C12.4997 7.77552 12.7232 7.99966 12.9994 8ZM16.9807 13.0049L12.9994 13C12.7232 12.9997 12.4997 12.7755 12.5 12.4994C12.5003 12.2232 12.7245 11.9997 13.0006 12L16.9819 12.0049C17.2581 12.0053 17.4817 12.2294 17.4813 12.5056C17.481 12.7817 17.2568 13.0053 16.9807 13.0049ZM12.9994 18L16.9807 18.0049C17.2568 18.0053 17.481 17.7817 17.4813 17.5056C17.4817 17.2294 17.2581 17.0053 16.9819 17.0049L13.0006 17C12.7245 16.9997 12.5003 17.2232 12.5 17.4994C12.4997 17.7755 12.7232 17.9997 12.9994 18ZM7.64645 7.85355C7.45118 7.65829 7.45118 7.34171 7.64645 7.14645C7.84171 6.95118 8.15829 6.95118 8.35355 7.14645L9 7.79289L10.6464 6.14645C10.8417 5.95118 11.1583 5.95118 11.3536 6.14645C11.5488 6.34171 11.5488 6.65829 11.3536 6.85355L9.35355 8.85355C9.15829 9.04882 8.84171 9.04882 8.64645 8.85355L7.64645 7.85355ZM7.64645 12.1464C7.45118 12.3417 7.45118 12.6583 7.64645 12.8536L8.64645 13.8536C8.84171 14.0488 9.15829 14.0488 9.35355 13.8536L11.3536 11.8536C11.5488 11.6583 11.5488 11.3417 11.3536 11.1464C11.1583 10.9512 10.8417 10.9512 10.6464 11.1464L9 12.7929L8.35355 12.1464C8.15829 11.9512 7.84171 11.9512 7.64645 12.1464ZM7.64645 17.8536C7.45118 17.6583 7.45118 17.3417 7.64645 17.1464C7.84171 16.9512 8.15829 16.9512 8.35355 17.1464L9 17.7929L10.6464 16.1464C10.8417 15.9512 11.1583 15.9512 11.3536 16.1464C11.5488 16.3417 11.5488 16.6583 11.3536 16.8536L9.35355 18.8536C9.15829 19.0488 8.84171 19.0488 8.64645 18.8536L7.64645 17.8536Z"
        fill={color || theme.colors.neutralSurface}
      />
    </g>
  </svg>
);

export default Exercises;
