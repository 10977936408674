import theme from '../../../theme';

const Cup = ({ width, height, color, ...props }) => (
  <svg
    width={width || '48'}
    height={height || '48'}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.8795 32.3059V36.4798H27.8395C30.4905 36.4798 32.6395 38.6289 32.6395 41.2798V44.1598H15.3595V41.2798C15.3595 38.6289 17.5086 36.4798 20.1595 36.4798H21.1195V32.3059C15.6155 31.0057 11.5195 26.0611 11.5195 20.1598V6.71984C11.5195 5.12926 12.809 3.83984 14.3995 3.83984H33.5995C35.1901 3.83984 36.4795 5.12926 36.4795 6.71984V20.1598C36.4795 26.0611 32.3836 31.0057 26.8795 32.3059Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.3873 5.75977V8.57974H41.281V12.8097C41.281 14.9641 40.1357 16.8797 38.3873 17.9694V21.1198C41.7679 19.8135 44.16 16.5852 44.16 12.8097V7.16975C44.16 6.39104 43.5155 5.75977 42.7205 5.75977H38.3873ZM6.71879 8.58085H9.6125V5.76087H5.27932C4.48432 5.76087 3.83984 6.39215 3.83984 7.17086V12.8108C3.83984 16.5863 6.2319 19.8146 9.6125 21.1209V17.9705C7.86415 16.8808 6.71879 14.9652 6.71879 12.8108V8.58085Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);
export default Cup;
