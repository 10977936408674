import { Table } from 'antd';
import * as T from '../../../components/Typography';
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (title, row) => {
      return <T.P>{title}</T.P>;
    },
  },
];

const TableComponent = ({ loading, data }) => (
  <Table columns={columns} dataSource={data} loading={loading} />
);

export default TableComponent;
