import styled from '@emotion/styled';
import CurvedDashedLineBackground from '../../components/assets/CurvedDashedLineBackground.svg';

export const Wrapper = styled.div`
  background-image: url(${CurvedDashedLineBackground});
  background-repeat: repeat-x;
  background-position: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  width: 63%;
  bottom: 0;
  height: 75px;
  background-color: ${({ theme }) => theme.colors.neutralLight};
  z-index: 100;

  ${({ theme }) => theme.media.tablet} {
    width: 100%;
  }
`;

export const BarOuter = styled.div`
  width: 80%;
  height: 12px;
  border-radius: 7px;
  outline: ${({ theme }) => `${theme.colors.primaryMain} 3px solid`};
`;

export const BarInner = styled.div`
  width: ${({ percentage }) => (percentage ? percentage + '%' : '0%')};
  height: ${({ percentage }) => (percentage === 0 ? '0px' : '12px')};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.primaryMain};
  transition: width 300ms ease-in-out;
`;
