import theme from '../../../theme';

const bullseye = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill={theme.colors.tertiaryMain} />
    <circle
      cx="32"
      cy="32"
      r="30"
      stroke={theme.colors.neutralMain}
      strokeOpacity="0.1"
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.08 30.72C22.08 36.1987 26.5214 40.64 32 40.64C37.4787 40.64 41.92 36.1987 41.92 30.72C41.92 25.2414 37.4787 20.8 32 20.8C26.5214 20.8 22.08 25.2414 22.08 30.72ZM40 30.72C40 35.1383 36.4183 38.72 32 38.72C27.5817 38.72 24 35.1383 24 30.72C24 26.3017 27.5817 22.72 32 22.72C36.4183 22.72 40 26.3017 40 30.72Z"
      fill={theme.colors.neutralSurface}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.48 30.72C28.48 32.664 30.056 34.24 32 34.24C33.9441 34.24 35.52 32.664 35.52 30.72C35.52 28.7759 33.9441 27.2 32 27.2C30.056 27.2 28.48 28.7759 28.48 30.72ZM33.6 30.72C33.6 31.6037 32.8837 32.32 32 32.32C31.1163 32.32 30.4 31.6037 30.4 30.72C30.4 29.8364 31.1163 29.12 32 29.12C32.8837 29.12 33.6 29.8364 33.6 30.72Z"
      fill={theme.colors.neutralSurface}
    />
    <path
      d="M32.96 21.76C32.96 22.2902 32.5302 22.72 32 22.72C31.4698 22.72 31.04 22.2902 31.04 21.76V19.2C31.04 18.6698 31.4698 18.24 32 18.24C32.5302 18.24 32.96 18.6698 32.96 19.2V21.76Z"
      fill={theme.colors.neutralSurface}
    />
    <path
      d="M32.96 42.24C32.96 42.7702 32.5302 43.2 32 43.2C31.4698 43.2 31.04 42.7702 31.04 42.24V39.68C31.04 39.1498 31.4698 38.72 32 38.72C32.5302 38.72 32.96 39.1498 32.96 39.68V42.24Z"
      fill={theme.colors.neutralSurface}
    />
    <path
      d="M23.04 29.76C23.5702 29.76 24 30.1898 24 30.72C24 31.2502 23.5702 31.68 23.04 31.68H20.48C19.9498 31.68 19.52 31.2502 19.52 30.72C19.52 30.1898 19.9498 29.76 20.48 29.76H23.04Z"
      fill={theme.colors.neutralSurface}
    />
    <path
      d="M43.52 29.76C44.0502 29.76 44.48 30.1898 44.48 30.72C44.48 31.2502 44.0502 31.68 43.52 31.68H40.96C40.4298 31.68 40 31.2502 40 30.72C40 30.1898 40.4298 29.76 40.96 29.76H43.52Z"
      fill={theme.colors.neutralSurface}
    />
  </svg>
);
export default bullseye;
