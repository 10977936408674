import theme from '../../../theme';

const wordBuilder = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill={theme.colors.secondaryMain} />
    <circle
      cx="32"
      cy="32"
      r="30"
      stroke={theme.colors.neutralMain}
      strokeOpacity="0.1"
      strokeWidth="4"
    />
    <path
      d="M27.5014 23.5654C26.7529 23.3021 26.2165 22.5917 26.2165 21.7566V20.478C26.2165 19.4187 27.0794 18.56 28.1439 18.56H35.8533C36.9178 18.56 37.7807 19.4187 37.7807 20.478V21.7566C37.7807 22.5917 37.2443 23.3021 36.4958 23.5654V27.338C39.3817 28.8546 41.3189 31.7313 41.6 34.9971C40.5359 35.4549 38.8664 35.1501 36.5364 33.961C34.1023 32.7188 32.4546 32.3919 30.7228 32.7782C30.1571 32.9044 29.595 33.0988 28.8431 33.4114C28.6024 33.5115 27.2035 34.1185 26.7458 34.3074C24.8672 35.083 23.4287 35.2821 22.4 34.9652C22.691 31.7126 24.6249 28.8497 27.5014 27.338V23.5654V23.5654Z"
      fill={theme.colors.neutralSurface}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.6 35.9837C41.5212 41.2089 37.2513 45.4204 31.9954 45.4204C26.841 45.4204 22.6348 41.3699 22.4 36.2852C23.7781 36.5546 25.4579 36.2631 27.4189 35.4514C27.8924 35.2554 29.2893 34.6477 29.5132 34.5544C30.1809 34.276 30.6585 34.1104 31.1074 34.01C32.3859 33.7241 33.6337 33.9723 35.7425 35.0513C37.4439 35.9218 39.3643 36.2384 41.5037 36.0012L41.5037 36.0012C41.5366 35.9975 41.5688 35.9917 41.6 35.9837ZM28.7934 40.3091C29.5007 40.3091 30.0742 39.737 30.0742 39.0313C30.0742 38.3255 29.5007 37.7534 28.7934 37.7534C28.086 37.7534 27.5126 38.3255 27.5126 39.0313C27.5126 39.737 28.086 40.3091 28.7934 40.3091Z"
      fill={theme.colors.neutralSurface}
    />
  </svg>
);
export default wordBuilder;
