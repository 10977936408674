import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  flex: ${({ flex }) => flex};
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
`;
