import { fields, createSchema, validate as _validate } from '..';

const { firstName, lastName, email, password, shouldBeTrue } = fields;

const schema = createSchema({
  firstName,
  lastName,
  email,
  password,
  giveConsent: shouldBeTrue,
  shareData: shouldBeTrue,
});

const validate = (data) => _validate(schema, data);

export default validate;
