import theme from '../../../theme';

const streakMaster = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill={theme.colors.secondaryMain} />
    <circle
      cx="32"
      cy="32"
      r="30"
      stroke={theme.colors.neutralMain}
      strokeOpacity="0.1"
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.76 19.2V21.76C38.76 22.1135 39.0465 22.4 39.4 22.4C39.7535 22.4 40.04 22.1135 40.04 21.76V19.2C40.04 18.8465 39.7535 18.56 39.4 18.56C39.0465 18.56 38.76 18.8465 38.76 19.2ZM37.48 19.84H28.52V21.76C28.52 22.8204 27.6604 23.68 26.6 23.68C25.5396 23.68 24.68 22.8204 24.68 21.76V19.84H20.84C20.1331 19.84 19.56 20.4131 19.56 21.12V24.96H46.44V21.12C46.44 20.4131 45.8669 19.84 45.16 19.84H41.32V21.76C41.32 22.8204 40.4604 23.68 39.4 23.68C38.3396 23.68 37.48 22.8204 37.48 21.76V19.84ZM46.44 39.68V26.24H19.56V39.68C19.56 42.1542 21.5658 44.16 24.04 44.16H41.96C44.4342 44.16 46.44 42.1542 46.44 39.68ZM25.7726 35.3874C25.5226 35.1375 25.1174 35.1375 24.8675 35.3874C24.6175 35.6374 24.6175 36.0426 24.8675 36.2925L26.1475 37.5725C26.3974 37.8225 26.8026 37.8225 27.0526 37.5725L29.6126 35.0125C29.8625 34.7626 29.8625 34.3574 29.6126 34.1074C29.3626 33.8575 28.9574 33.8575 28.7075 34.1074L26.6 36.2149L25.7726 35.3874ZM24.8675 30.2674C25.1174 30.0175 25.5226 30.0175 25.7726 30.2674L26.6 31.0949L28.7075 28.9874C28.9574 28.7375 29.3626 28.7375 29.6126 28.9874C29.8625 29.2374 29.8625 29.6426 29.6126 29.8925L27.0526 32.4525C26.8026 32.7025 26.3974 32.7025 26.1475 32.4525L24.8675 31.1725C24.6175 30.9226 24.6175 30.5174 24.8675 30.2674ZM33.0014 31.36L39.3974 31.3663C39.7519 31.3667 40.0396 31.0804 40.04 30.7269C40.0404 30.3735 39.7532 30.0867 39.3986 30.0863L33.0026 30.08C32.6481 30.0796 32.3604 30.3659 32.36 30.7193C32.3597 31.0728 32.6468 31.3596 33.0014 31.36ZM39.3974 36.4863L33.0014 36.48C32.6468 36.4796 32.3597 36.1928 32.36 35.8393C32.3604 35.4859 32.6481 35.1996 33.0026 35.2L39.3986 35.2063C39.7532 35.2067 40.0404 35.4935 40.04 35.8469C40.0396 36.2004 39.7519 36.4867 39.3974 36.4863ZM25.96 21.76V19.2C25.96 18.8465 26.2465 18.56 26.6 18.56C26.9535 18.56 27.24 18.8465 27.24 19.2V21.76C27.24 22.1135 26.9535 22.4 26.6 22.4C26.2465 22.4 25.96 22.1135 25.96 21.76Z"
      fill={theme.colors.neutralSurface}
    />
  </svg>
);
export default streakMaster;
