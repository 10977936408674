import theme from '../../../theme';

const DashedLine = ({ width, height, color, ...props }) => (
  <svg
    width={width || '6'}
    height={height || '58'}
    viewBox="0 0 6 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="2.99799"
      y1="2.81836"
      x2="2.99799"
      y2="55.3375"
      stroke={color || theme.colors.neutralMain}
      strokeWidth="4.11915"
      strokeLinecap="round"
      strokeDasharray="12.36 12.36"
    />
  </svg>
);
export default DashedLine;
