import Modal from '../../../components/Modal';
import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import * as S from './style';

const RejectModal = ({
  visible,
  setIsModalVisible,
  handleReject,
  loading,
  error,
}) => {
  return (
    <Modal visible={visible} setIsModalVisible={setIsModalVisible}>
      <Row>
        <Col w={[4, 12, 12]} jc="center" mt={3}>
          {!error ? (
            <S.P size="med" color="white" weight="bold">
              Are you sure you want to reject this user? All personal data
              related to this user will also be deleted once you confirm.
            </S.P>
          ) : (
            <S.P size="med" color="error" weight="bold">
              There was an error rejecting this referral. Please try again
              later.
            </S.P>
          )}
        </Col>
        <Col w={[4, 12, 12]} mt={4}>
          <BasicButton
            variant="secondary"
            loading={loading}
            disabled={error || loading}
            handleClick={handleReject}
          >
            <T.P color="white" weight="bold">
              Yes, I'm sure
            </T.P>
          </BasicButton>
          <BasicButton
            mt={3}
            to={'#'}
            variant="tertiary"
            loading={loading}
            handleClick={() => setIsModalVisible(false)}
          >
            No, go back
          </BasicButton>
        </Col>
      </Row>
    </Modal>
  );
};

export default RejectModal;
