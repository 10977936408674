const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const findDistanceBetween = ({ x1, x2, y1, y2 }) => {
  const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  return distance;
};

const showMovingBlock = async ({
  OptionElId,
  answerElId,
  movingElId = 'moving-div',
  isBeingSelected,
  done,
}) => {
  const startTime = Date.now();

  let startEl = document.getElementById(OptionElId);
  let endEl = document.getElementById(answerElId);
  let hiddenMovingElement = document.getElementById(movingElId);

  while (!endEl || !startEl) {
    await sleep(0);
  }

  if (isBeingSelected) [startEl, endEl] = [endEl, startEl];
  const [{ x: startX, y: startY }, { x: endX, y: endY }] = [startEl, endEl].map(
    (e) => e.getBoundingClientRect()
  );
  const [dx, dy] = [endX - startX, endY - startY];

  const distance = findDistanceBetween({
    x1: startX,
    x2: endX,
    y1: startY,
    y2: endY,
  });

  const duration = Math.max(distance * 1.2, 300);
  const movingBlock = hiddenMovingElement.cloneNode(true);
  movingBlock.textContent = startEl.textContent;
  movingBlock.style.position = 'absolute';
  movingBlock.style.left = startX + 'px';
  movingBlock.style.top = startY + 'px';
  document.body.appendChild(movingBlock);

  (function moveBlock() {
    const now = Date.now();
    if (now >= startTime + duration) {
      endEl.style.visibility = 'initial';
      done && done();
      return movingBlock.parentNode.removeChild(movingBlock);
    }
    const percentage = (now - startTime) / duration;
    const x = startX + dx * percentage;
    const y = startY + dy * percentage;
    movingBlock.style.left = x + window.scrollX + 'px';
    movingBlock.style.top = y + window.scrollY + 'px';
    requestAnimationFrame(moveBlock);
  })();
};

export default showMovingBlock;
