import axios from 'axios';
import handleError from './format-error';

const STEPS_BASE = '/steps';

const getSteps = async ({ exerciseId, options } = {}) => {
  try {
    const { data } = await axios.get(`${STEPS_BASE}`, {
      params: { exerciseId },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createStep = async ({ body, options } = {}) => {
  try {
    const { data } = await axios.post(`${STEPS_BASE}`, body);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateStep = async ({ id, body, options } = {}) => {
  try {
    const { data } = await axios.patch(`${STEPS_BASE}/${id}`, body);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};
const deleteStep = async ({ id, options } = {}) => {
  try {
    const { data } = await axios.delete(`${STEPS_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getStep = async ({ id, options } = {}) => {
  try {
    const { data } = await axios.get(`${STEPS_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export { getSteps, createStep, getStep, updateStep, deleteStep };
