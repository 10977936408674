import * as S from './style';
import theme from '../../../theme';

const defaultMarks = {
  0: {
    label: '0',
    style: {
      color: theme.colors.neutralMain,
    },
  },
  10: {
    label: '1',
    style: {
      color: theme.colors.neutralMain,
    },
  },
  20: {
    label: '2',
    style: {
      color: theme.colors.neutralMain,
    },
  },
  30: {
    label: '3',
    style: {
      color: theme.colors.neutralMain,
    },
  },
  40: {
    label: '4',
    style: {
      color: theme.colors.neutralMain,
    },
  },
  50: {
    label: '5',
    style: {
      color: theme.colors.neutralMain,
    },
  },
  60: {
    label: '6',
    style: {
      color: theme.colors.neutralMain,
    },
  },
  70: {
    label: '7',
    style: {
      color: theme.colors.neutralMain,
    },
  },
  80: {
    label: '8',
    style: {
      color: theme.colors.neutralMain,
    },
  },
  90: {
    label: '9',
    style: {
      color: theme.colors.neutralMain,
    },
  },
  100: {
    label: '10',
    style: {
      color: theme.colors.neutralMain,
    },
  },
};

const SliderInput = ({ value, setValue, marks }) => {
  return (
    <S.Container aria-label="Slider">
      <S.StyledSliderInput
        value={value}
        marks={marks || defaultMarks}
        onChange={setValue}
        step={10}
        tooltipVisible={false}
        trackStyle={{
          background: theme.colors.neutralMain,
        }}
        handleStyle={{ borderColor: theme.colors.neutralMain }}
        role="slider"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={10}
      />
    </S.Container>
  );
};

export default SliderInput;
