import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const Wrapper = styled.div`
  ${setMargin};
  display: flex;
  /* width: '100%'; */
  align-items: center;
`;
export const IconWrapper = styled.div`
  display: flex;
  min-width: ${({ width }) => width || '70px'};
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height || '70px'};
  border-radius: ${({ theme, borderRadius }) =>
    borderRadius || theme.borders.radiusLarge};
  background-color: ${({ backgroundColor, theme }) =>
    theme.colors[backgroundColor] || theme.colors.neutralLight};
`;
