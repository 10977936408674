const Alert = ({ width, height, color, ...props }) => {
  return (
    <svg
      width={width || '28'}
      height={height || '28'}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 2.625C7.72789 2.625 2.625 7.72789 2.625 14C2.625 20.2721 7.72789 25.375 14 25.375C20.2721 25.375 25.375 20.2721 25.375 14C25.375 7.72789 20.2721 2.625 14 2.625ZM14 20.1201C13.7837 20.1201 13.5722 20.0559 13.3923 19.9357C13.2125 19.8156 13.0723 19.6447 12.9895 19.4449C12.9067 19.245 12.8851 19.0251 12.9273 18.8129C12.9695 18.6008 13.0736 18.4059 13.2266 18.2529C13.3796 18.1 13.5745 17.9958 13.7866 17.9536C13.9988 17.9114 14.2187 17.9331 14.4186 18.0158C14.6184 18.0986 14.7892 18.2388 14.9094 18.4187C15.0296 18.5985 15.0938 18.81 15.0938 19.0263C15.0938 19.3164 14.9785 19.5946 14.7734 19.7997C14.5683 20.0048 14.2901 20.1201 14 20.1201ZM15.1878 9.11969L14.8739 15.7916C14.8739 16.0236 14.7817 16.2462 14.6176 16.4103C14.4535 16.5744 14.231 16.6666 13.9989 16.6666C13.7668 16.6666 13.5443 16.5744 13.3802 16.4103C13.2161 16.2462 13.1239 16.0236 13.1239 15.7916L12.81 9.12297V9.12023C12.8031 8.95996 12.8287 8.79995 12.8853 8.64984C12.9419 8.49973 13.0283 8.36263 13.1393 8.24679C13.2503 8.13095 13.3835 8.03876 13.5311 7.97579C13.6786 7.91281 13.8374 7.88034 13.9978 7.88034C14.1582 7.88034 14.317 7.91281 14.4645 7.97579C14.6121 8.03876 14.7454 8.13095 14.8563 8.24679C14.9673 8.36263 15.0537 8.49973 15.1103 8.64984C15.1669 8.79995 15.1925 8.95996 15.1856 9.12023L15.1878 9.11969Z"
        fill={color || '#E05447'}
      />
    </svg>
  );
};
export default Alert;
