import styled from '@emotion/styled';
import { setMargin } from '../../helpers';

export const Wrapper = styled.div`
  ${setMargin};
  [data-name='codeBlock'],
  [data-name='edit'],
  [data-name='live'],
  [data-name='preview'],
  [data-name='fullscreen'],
  [data-name='checked-list'],
  [data-name='image'] {
    display: none;
  }
`;

export const Label = styled.label`
  ${setMargin};
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 8px;
`;

export const OptionalTag = styled.span`
  ${setMargin};
  display: inline-block;
  font-weight: 400 !important;
  color: ${({ theme }) => theme.colors.neutral80};
`;
