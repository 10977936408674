import { fields, createSchema, validate as _validate } from '..';

const { twoDigits } = fields;

const schema = createSchema({
  code: twoDigits,
});

const validate = (data) => _validate(schema, data);

export default validate;
