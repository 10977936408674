import { Spin } from 'antd';
import styled from '@emotion/styled';
import * as T from '../../Typography';
import setMargin from '../../../helpers/set-margin';

export const PlayText = styled(T.H2)`
  color: ${({ theme, loading, color }) =>
    loading ? theme.colors.neutral70 : theme.colors[color] || color};
  margin-left: ${({ theme }) => theme.spacings[3]} !important;
`;

export const PlayButton = styled.button`
  ${setMargin};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.neutralMain};
  background: ${({ theme }) => theme.colors.neutralLight};
  position: relative;
  border: none;
  border-radius: 16px;
  font-size: 1rem;
  font-weight: bold;
  padding: ${({ theme }) => `30px ${theme.spacings[5]}`};
  box-shadow: ${({ theme }) => theme.shadows.elevation1};
  cursor: ${({ disabled, loading }) =>
    disabled || loading ? 'not-allowed' : 'pointer'};

  transition: background-color 300ms linear, color 300ms linear;
  path {
    transition: fill 300ms linear;
  }
  :hover {
    color: ${({ theme }) => theme.colors.primaryMain};
    path {
      fill: ${({ theme }) => theme.colors.primaryMain};
    }
  }

  :active {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.9;
    background-color: ${({ theme }) => theme.colors.neutralMain};
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  :focus {
    box-shadow: inset 0px 0px 0px 2px rgba(5, 23, 48, 1);
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${({ theme }) => theme.colors.primaryMain};
    outline-offset: 2px;
  }
  :disabled {
    color: ${({ theme }) => theme.colors.neutralMain};
    background: ${({ theme }) => theme.colors.neutralLight};
    path {
      fill: ${({ theme }) => theme.colors.neutralMain};
    }
  }
`;

export const CenterLoading = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  span {
    line-height: 24px;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.quaternaryMain};
  }
`;

export const InnerText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;
  margin-left: 12px;
`;
