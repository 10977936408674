import theme from '../../../theme';

const ProfileEye = ({ width, height, color, ...props }) => (
  <svg
    width={width || '18'}
    height={height || '14'}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00009 0.759766C5.51303 0.759766 2.39847 3.14352 0.876652 6.81601C0.8279 6.93366 0.8279 7.06587 0.876652 7.18352C2.39847 10.856 5.51303 13.2398 9.00009 13.2398C12.4871 13.2398 15.6017 10.856 17.1235 7.18352C17.1723 7.06587 17.1723 6.93366 17.1235 6.81601C15.6017 3.14352 12.4871 0.759766 9.00009 0.759766ZM6.60004 6.99961C6.60004 8.32509 7.67455 9.39961 9.00004 9.39961C10.3255 9.39961 11.4 8.32509 11.4 6.99961C11.4 5.67413 10.3255 4.59961 9.00004 4.59961C7.67455 4.59961 6.60004 5.67413 6.60004 6.99961Z"
      fill={color || theme.colors.primaryMain}
    />
  </svg>
);

export default ProfileEye;
