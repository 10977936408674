import theme from '../../../theme';

const Next = ({ width, height, color, ...props }) => (
  <svg
    width={width || '20'}
    height={height || '12'}
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.9931 5.00126L13.2073 2.21545C12.7782 1.78638 12.7783 1.09072 13.2073 0.661647C13.6364 0.232576 14.3321 0.232576 14.7611 0.661647L19.4225 5.32306C19.8516 5.75213 19.8516 6.4478 19.4225 6.87687L14.7611 11.5383C14.3321 11.9674 13.6364 11.9674 13.2073 11.5383C12.7782 11.1092 12.7782 10.4135 13.2073 9.98448L15.9931 7.19867L1.55378 7.19867C0.946986 7.19867 0.455078 6.70676 0.455078 6.09996C0.455078 5.49317 0.946986 5.00126 1.55378 5.00126L15.9931 5.00126Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Next;
