import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '../../../components/Grid';
import { COACH, EXTERNAL } from '../../../constants/nav-routes';
import { BasicButton } from '../../../components/Button';
import * as T from '../../../components/Typography';
import Video from '../../../components/Video';

const Onboarding3 = () => {
  const navigate = useNavigate();
  const [videoWatched, setVideoWatched] = useState(false);

  const storeVideoWatchedInLocalStorage = () => {
    localStorage.setItem('video', JSON.stringify(true));
    setVideoWatched(true);
  };

  useEffect(() => {
    const _video = localStorage.getItem('video');
    setVideoWatched(_video);
  }, [videoWatched]);

  const handleClickWatched = () => {
    storeVideoWatchedInLocalStorage();
    navigate(COACH.ONBOARDING_4);
  };

  const handleClickedWatchLater = () => {
    navigate(COACH.ONBOARDING_4);
  };
  return (
    <>
      <Row>
        <Col w={[4, 8, 8]}>
          <T.H2 color="neutralMain">How the tool works</T.H2>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt="3">
          <T.P color="neutralMain">
            So you can get started quickly, here’s a demo of how the tool works
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt="4">
          <Video src={EXTERNAL.HOW_TO_USE_VIDEO} />
        </Col>
      </Row>
      <Row mt={6} ai="end" style={{ flex: 1 }}>
        <Col w={[4, 8, 8]}>
          <BasicButton
            maxWidth="426px"
            variant="primary"
            handleClick={handleClickWatched}
          >
            I’ve watched the video
          </BasicButton>
          <BasicButton
            variant="secondary"
            maxWidth="426px"
            handleClick={handleClickedWatchLater}
            mt={4}
          >
            I’ll watch this later
          </BasicButton>
        </Col>
      </Row>
    </>
  );
};

export default Onboarding3;
