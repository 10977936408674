import styled from '@emotion/styled';
import setMargin from '../../../helpers/set-margin';
import { Slider } from 'antd';

export const Container = styled.div`
  ${setMargin};
  width: 100%;
  .ant-slider-dot {
    display: none;
  }
`;

export const StyledSliderInput = styled(Slider)`
  .ant-slider-rail {
    background-color: ${({ theme }) => theme.colors.neutralMain80};
    height: 0.4rem;
    border-radius: 0.2rem;
  }

  .ant-slider-track {
    background-color: ${({ theme }) => theme.colors.neutralMain80};
    height: 0.4rem;
    border-radius: 0.2rem;
  }
  .ant-slider-handle {
    width: 1.6rem;
    height: 1.6rem;
    margin-top: -10px;
    border: 5px solid ${({ theme }) => theme.colors.neutralMain} !important;
  }
  .ant-slider-handle:hover {
    border: 5px solid ${({ theme }) => theme.colors.primaryMain} !important;
  }

  .ant-slider-handle:focus {
    border: 5px solid ${({ theme }) => theme.colors.neutralMain} !important;
    box-shadow: none !important;
    outline-color: ${({ theme }) => theme.colors.primaryMain} !important;
    outline-style: solid !important;
    outline-width: 3px !important;
    outline-offset: 0.2em !important;
  }
  .ant-slider-mark-text {
    font-size: 1.0625rem;
    padding-top: 0.9rem;
  }
`;
