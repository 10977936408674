const data = [
  {
    title: 'Monday',
    meals: [
      {
        title: 'Lunch',
        recipes: [
          {
            title: 'Cheese melt 🥘 🥗',
            id: 1,
          },
          {
            title: 'Ham and pickle salad 😌',
            id: 2,
          },
          {
            title: 'Vegetable burger 🥘 🥗 😌',
            id: 3,
          },
          {
            title: 'Sausage roll and beans',
            id: 4,
          },
        ],
      },
      {
        title: 'Dinner',
        recipes: [
          {
            title: 'Battered cod 🥘',
            id: 5,
          },
          {
            title: 'Spicy chicken 🥘 😌',
            id: 6,
          },
          {
            title: 'Lentil and mushroom bake 🥘 🥗 😌',
            id: 7,
          },
          {
            title: 'Cheese and onion flan 🥘 🥗',
            id: 8,
          },
        ],
      },
    ],
  },
  {
    title: 'Tuesday',
    meals: [
      {
        title: 'Lunch',
        recipes: [
          {
            title: 'Cajun tuna and peppers 🥘 😌',
            id: 9,
          },
          {
            title: 'Ham and onion sandwiches',
            id: 10,
          },
          {
            title: 'Vegetable soup and roll 🥘 🥗 😌',
            id: 11,
          },
          {
            title: 'Falafel and pitta bread 🥘 🥗 😌',
            id: 12,
          },
        ],
      },
      {
        title: 'Dinner',
        recipes: [
          {
            title: 'Fish fillet in parsley sauce 🥘 😌',
            id: 13,
          },
          {
            title: 'Stir fry vegetables 🥘 🥗 😌',
            id: 14,
          },
          {
            title: 'Beef casserole 😌',
            id: 15,
          },
          {
            title: 'Cheese and onion pasty 🥘 🥗',
            id: 16,
          },
        ],
      },
    ],
  },
];

export default data;
