import Icon from '../Icon';
import * as T from '../Typography';
import * as S from './style';

const ManualCard = ({ manualNumber }) => {
  return (
    <S.ManualWrapper manualNumber={manualNumber}>
      <Icon
        icon="milestone"
        color="neutralSurface"
        width="24.66"
        height="24.66"
        mb="2"
      />
      <T.P weight="bold" color="neutralSurface">
        Manual {manualNumber}
      </T.P>
    </S.ManualWrapper>
  );
};

export default ManualCard;
