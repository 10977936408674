import theme from '../../../theme';

const Note = ({ width, height, color, ...props }) => (
  <svg
    width={width || '44'}
    height={height || '44'}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.4756 15.5959C33.4756 13.1461 31.653 11.122 29.2898 10.8057C29.3182 11.0175 29.3328 11.2336 29.3328 11.4531C29.3328 14.1224 27.1689 16.2863 24.4996 16.2863H18.976C16.3066 16.2863 14.1427 14.1224 14.1427 11.4531C14.1427 11.2336 14.1574 11.0175 14.1857 10.8057C11.8226 11.122 10 13.1461 10 15.5959V32.1669C10 34.8362 12.1639 37.0001 14.8332 37.0001H28.6424C31.3117 37.0001 33.4756 34.8362 33.4756 32.1669V15.5959ZM27.2615 25.952H16.2142C15.8329 25.952 15.5238 25.6428 15.5238 25.2615C15.5238 24.8802 15.8329 24.571 16.2142 24.571H27.2615C27.6429 24.571 27.952 24.8802 27.952 25.2615C27.952 25.6428 27.6429 25.952 27.2615 25.952ZM16.2142 20.4284H27.2615C27.6429 20.4284 27.952 20.1193 27.952 19.738C27.952 19.3566 27.6429 19.0475 27.2615 19.0475H16.2142C15.8329 19.0475 15.5238 19.3566 15.5238 19.738C15.5238 20.1193 15.8329 20.4284 16.2142 20.4284ZM27.2615 31.476H16.2142C15.8329 31.476 15.5238 31.1668 15.5238 30.7855C15.5238 30.4042 15.8329 30.095 16.2142 30.095H27.2615C27.6429 30.095 27.952 30.4042 27.952 30.7855C27.952 31.1668 27.6429 31.476 27.2615 31.476Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9748 8H24.4984C26.4051 8 27.9507 9.54564 27.9507 11.4523C27.9507 13.3589 26.4051 14.9046 24.4984 14.9046H18.9748C17.0681 14.9046 15.5225 13.3589 15.5225 11.4523C15.5225 9.54564 17.0681 8 18.9748 8Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Note;
