import * as S from './style';

import * as T from '../Typography';
import Markdown from '../Markdown';

const TipWithBullets = ({
  title,
  ShowBoldTipPrefix,
  listItems = [],
  ...props
}) => {
  if (title || listItems.length)
    return (
      <S.TipWrapper {...props}>
        {title ? (
          <Markdown
            text={!ShowBoldTipPrefix ? `**Tip!** ${title}` : title}
            customStyles={{
              p: {
                mt: 3,
                ml: 0,
                mr: 2,
                mb: 2,
                color: 'primaryMain',
                weight: 'bold',
              },
            }}
          />
        ) : null}
        {listItems.length ? (
          <S.CheckUL>
            {listItems.map((listItem, index) => (
              <S.CheckLI key={index}>
                <Markdown
                  text={listItem}
                  customStyles={{
                    p: {
                      mt: 3,
                      ml: 0,
                      mr: 2,
                      mb: 2,
                      color: 'primaryMain',
                    },
                  }}
                />
              </S.CheckLI>
            ))}
          </S.CheckUL>
        ) : null}
      </S.TipWrapper>
    );
  return props?.children?.map((e) => (
    <S.TipWrapper {...props}>
      <T.P display="inline" color="primaryMain" weight="bold">
        {ShowBoldTipPrefix && 'Tip!'} <S.Content>{e}</S.Content>
      </T.P>
    </S.TipWrapper>
  ));
};

export default TipWithBullets;
