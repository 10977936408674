import styled from '@emotion/styled';
import setMargin from '../../../helpers/set-margin';

export const ButtonWrapper = styled.div`
  ${setMargin};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Number = styled.div`
  width: 52px;
  height: 52px;
  background: ${({ bg, theme }) => theme.colors[bg]};
  background-color: ${({ isActive, theme }) =>
    isActive
      ? `${theme.colors.neutralSurface5} `
      : theme.colors.neutralSurface};

  border-radius: ${({ theme }) => theme.borders.numberRadius};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImgContainer = styled.div`
  width: 72px;
  height: 72px;
  background: ${({ theme }) => theme.colors.neutralSurface};
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  border-radius: ${({ theme }) => theme.borders.numberRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
`;

export const ColorWrapper = styled.div`
  width: 52px;
  height: 52px;
  background: ${({ theme, bgColor }) => theme.colors[bgColor] || bgColor};
  border-radius: ${({ theme }) => theme.borders.numberRadius};
`;

export const Group = styled.div`
  ${setMargin};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Button = styled.button`
  border: none !important;
  width: 100%;
  padding: 4px;
  margin-bottom: 8px;
  background: ${({ theme }) => theme.colors.neutralLight};
  border-radius: ${({ theme }) => `${theme.borders.radiusLarge} !important`};
  height: fit-content;
  display: flex;
  align-items: center;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.neutralMain : theme.colors.neutralLight};
  cursor: pointer;

  :hover {
    .ant-typography {
      color: ${({ theme, isActive }) =>
        isActive ? theme.colors.white : theme.colors.primaryMain} !important;
    }
  }

  :focus-within {
    box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.colors.neutralMain} !important;
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${({ theme }) => theme.colors.primaryMain + '!important'};
    outline-offset: 0.2em;
  }
`;
