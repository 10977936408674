import theme from '../../../theme';
const Accolade = ({ width, height, color, ...props }) => (
  <svg
    width={width || '32'}
    height={height || '32'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.3604 21.7598C11.4119 21.7598 7.40039 17.7483 7.40039 12.7998C7.40039 7.85137 11.4119 3.83984 16.3604 3.83984C21.3089 3.83984 25.3204 7.85137 25.3204 12.7998C25.3204 17.7483 21.3089 21.7598 16.3604 21.7598Z"
      fill={color || theme.colors.primaryMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.2398 26.8804C27.6993 26.8804 28.009 26.4106 27.828 25.9883L24.7486 19.2002C23.0501 21.5919 20.3503 23.2227 17.2605 23.4816L20.2674 29.0866C20.4627 29.4773 20.9835 29.5618 21.2923 29.2529L23.6649 26.8804H27.2398ZM11.428 29.2526C11.7369 29.5614 12.2576 29.4769 12.453 29.0862L15.5286 23.4813C12.4387 23.2227 9.73884 21.5921 8.04016 19.2006L4.89228 25.9879C4.71129 26.4102 5.02107 26.88 5.48053 26.88H9.05544L11.428 29.2526Z"
      fill={color || theme.colors.primaryMain}
    />
  </svg>
);
export default Accolade;
