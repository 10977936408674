import Image from '../../../components/Image';
import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import { LEARNER, COACH } from '../../../constants/nav-routes';
import * as S from './style';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../theme';

const FirstPage = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.mobile})`,
  });
  return (
    <>
      <S.Wrapper>
        {isMobile ? (
          <S.ImageWrapper>
            <Image image="quest" width="100%" />
          </S.ImageWrapper>
        ) : (
          <Row>
            <Col w={[0, 7, 9]}>
              <S.ImageWrapper
                style={{
                  width: '100%',
                  borderRadius: '20px',
                  overflow: 'hidden',
                }}
              >
                <Image image="quest" width="100%" />
              </S.ImageWrapper>
            </Col>
          </Row>
        )}

        <Row>
          <Col w={[4, 7, 9]} mt="6">
            <T.H2 color="neutralMain">
              Now we are going to go through a few starter questions{' '}
            </T.H2>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 7, 9]} mt="4">
            <T.P color="neutral90">
              These questions are to see how you’re currently feeling in life
              and about reading
            </T.P>
          </Col>
        </Row>
      </S.Wrapper>
      <S.NextButtonWrapper mt="6">
        <Col w={[4, 7, 9]}>
          <BasicButton
            variant="primary"
            icon="next"
            iconProps={{ width: '24px', height: '24px' }}
            to={LEARNER.PROGRESS_REPORT_QUESTIONS.replace(':id', 1)}
          />
        </Col>
        <Col w={[4, 9, 9]} mt="3">
          <BasicButton variant="secondary" to={COACH.LEARNERS}>
            <T.P color="white" weight="semi">
              Exit onboarding
            </T.P>
          </BasicButton>
        </Col>
      </S.NextButtonWrapper>
    </>
  );
};

export default FirstPage;
