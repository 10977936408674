import * as S from './style';
import { imgMap } from '../Image';

const Graphics = ({ image, width, bgColor, height, ...props }) => {
  return (
    <S.Container
      image={image && imgMap[image]}
      width={width}
      bgColor={bgColor}
      height={height}
      {...props}
    />
  );
};

export default Graphics;
