import RiseImage from '../../../../../components/assets/rise.jpeg';
import * as S from './style';
import * as T from '../../../../../components/Typography';
import { list1, list2 } from './data';

const SimpleRisotto1 = () => {
  return (
    <>
      <S.Image src={RiseImage} alt="rise" />
      <S.ContentWrapper>
        <T.H3 size="med" color="neutralMain" mt={2} mb={4}>
          Simple Risotto
        </T.H3>
        <T.P mb="2" weight="bold">
          Ingredients:
        </T.P>
        {list1.map((item) => (
          <S.ListItem key={item.key}>
            <S.BulletPoint /> <T.P>{item.text}</T.P>
          </S.ListItem>
        ))}
      </S.ContentWrapper>
      <S.ContentWrapper bgColor="neutralLight">
        <T.P mb="2" weight="bold">
          Method
        </T.P>
        {list2.map((item, index) => (
          <S.ListItem key={item.key}>
            <S.NumberPoint>{index + 1}.</S.NumberPoint>
            <T.P>{item.text}</T.P>
          </S.ListItem>
        ))}
      </S.ContentWrapper>
    </>
  );
};

export default SimpleRisotto1;
