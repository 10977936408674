import theme from '../../../theme';

const ByYourSelf = ({ width, height, color, ...props }) => (
  <svg
    width={width || '30'}
    height={height || '52'}
    viewBox="0 0 30 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.04006 9.64066C6.04006 14.5891 10.0516 18.6007 15.0001 18.6007C19.9485 18.6007 23.9601 14.5891 23.9601 9.64066C23.9601 4.69219 19.9485 0.680664 15.0001 0.680664C10.0516 0.680664 6.04006 4.69219 6.04006 9.64066ZM4.76004 36.4885H6.04004V50.601C6.04004 51.3079 6.61312 51.881 7.32004 51.881H22.68C23.387 51.881 23.96 51.3079 23.96 50.601V36.4885H25.24C27.3608 36.4885 29.08 34.7693 29.08 32.6485V27.5448C29.08 22.6052 25.0758 18.601 20.1363 18.601H9.86383C4.92431 18.601 0.920044 22.6052 0.920044 27.5448V32.6485C0.920044 34.7693 2.63927 36.4885 4.76004 36.4885Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default ByYourSelf;
