import { BasicButton } from './../../components/Button';
import { navRoutes } from './../../constants';
import * as S from './style';
import { useAuth } from '../../context/auth';

const Home = () => {
  const { logout } = useAuth();

  return (
    <S.Wrapper>
      <BasicButton to={navRoutes.CMS.VIEW_MILESTONES} mt={'40px'}>
        View Milestones
      </BasicButton>
      <BasicButton to={navRoutes.CMS.VIEW_EXERCISES} mt={'40px'}>
        View Exercises
      </BasicButton>
      <BasicButton to={navRoutes.CMS.MEDIA} mt={'40px'}>
        View Media
      </BasicButton>
      <BasicButton handleClick={logout} mt={'40px'} variant="secondary">
        Log out
      </BasicButton>
    </S.Wrapper>
  );
};

export default Home;
