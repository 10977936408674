import theme from '../../../theme';

const BookmarkSaved = ({ width, height, color, ...props }) => (
  <svg
    width={width || '32'}
    height={height || '32'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2001 9.59981V2.55981H9.60012C7.12588 2.55981 5.12012 4.56558 5.12012 7.03981V24.9598C5.12012 27.4341 7.12588 29.4398 9.60012 29.4398H22.4001C24.8744 29.4398 26.8801 27.4341 26.8801 24.9598V11.5198H21.1201C20.0597 11.5198 19.2001 10.6602 19.2001 9.59981ZM20.7156 3.25086C20.6436 3.16444 20.5647 3.08522 20.4801 3.01371V9.60002C20.4801 9.95348 20.7667 10.24 21.1201 10.24H26.5314C26.5011 10.1969 26.469 10.155 26.4351 10.1143L20.7156 3.25086Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default BookmarkSaved;
