import Modal from './../../../components/Modal';
import * as T from './../../../components/Typography';
import { Row, Col } from './../../../components/Grid';
import { BasicButton } from './../../../components/Button';
import { navRoutes } from './../../../constants';
import { useNavigate } from 'react-router-dom';

const SuccessModal = ({
  isModalVisible,
  setIsModalVisible,
  exerciseId,
  isUpdate,
}) => {
  const navigate = useNavigate();

  return (
    <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
      <Row>
        <Col w={[4, 12, 12]} jc="center">
          <T.P size="med" weight="bold" color="white">
            {isUpdate
              ? 'Exercise has been updated successfully!'
              : 'Exercise has been created successfully!'}
          </T.P>
        </Col>
        <>
          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton
              to={navRoutes.EXERCISES.EXERCISE_EXPLAINER.replace(
                ':exerciseId',
                exerciseId
              )}
              variant="secondary"
              external
            >
              View exercise
            </BasicButton>
          </Col>
          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton
              to={navRoutes.CMS.SINGLE_STEP.replace(':id', exerciseId).replace(
                ':stepId',
                'new'
              )}
              variant="secondary"
            >
              start adding steps
            </BasicButton>
          </Col>
          <Col w={[4, 12, 12]} mt={2}>
            <BasicButton
              variant="tertiary"
              onClick={() => {
                navigate(navRoutes.CMS.CREATE_EXERCISES);
                setIsModalVisible(false);
              }}
            >
              Create new one
            </BasicButton>
          </Col>
        </>
      </Row>
    </Modal>
  );
};

export default SuccessModal;
