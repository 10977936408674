import dogImage from '../../../../../components/assets/dog.png';
import * as S from './style';
import * as T from '../../../../../components/Typography';

import { List } from './data';

const DogsOfWar = () => {
  return (
    <>
      <S.Image src={dogImage} alt="dog" />
      <S.ContentWrapper>
        <T.H3 size="med" color="neutralMain" mb={4} fontFamily="garamond">
          The Dogs of War
        </T.H3>
        <T.P mb="2" fontFamily="garamond">
          Dogs were used by all sides in the First World War for many different
          jobs:
        </T.P>
        <List />
        <T.P mt="5" fontFamily="garamond">
          About a million dogs died in the battlefields of the First World War.
        </T.P>
      </S.ContentWrapper>
    </>
  );
};

export default DogsOfWar;
