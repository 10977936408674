import styled from '@emotion/styled';
import { P } from '../../../components/Typography';
import Image from '../../../components/Image';

export const AudioBesideText = styled.div`
  display: flex;
  align-items: center;
  h2 {
    padding-left: 10px;
  }
  padding-bottom: 24px;
  padding-top: 22px;
`;

export const StyledImage = styled(Image)`
  border-radius: 12.7px;
`;

// export const WithBg = styled.div`
//   width: 100%;
//   background-color: ${({ theme }) => theme.colors.neutralLight};
//   border-radius: 20px;
//   padding-top: 42px;
//   margin-top: -42px;
//   padding-left: 15px;
//   z-index: -1;
// `;

export const Text = styled(P)`
  background-color: ${({ theme }) => theme.colors.neutralLight};
  padding: 12px 19px 16px;
  border-radius: 8px;
`;

export const GlassesImg = styled(Image)`
  border-radius: 20px;
`;

export const ImageWrapper = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 12.7685px;
`;
