import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';

function ScrollToTop() {
  let location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

export default ScrollToTop;
