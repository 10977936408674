import { Checkbox as AntdCheckbox } from 'antd';
import Icon from '../../Icon';
import * as T from '../../Typography';
import * as S from './style';

const Checkbox = ({
  checked,
  label,
  error,
  hideErrorMsg,
  handleChange,
  color = 'neutralMain',
  disabled,
  defaultChecked,
  component,
  value,
  w,
  m,
  plain,
  font,
  noCheckbox,
  checkboxBgColor,
  checkedBgColor,
  avoidClick,
  ...props
}) => {
  return (
    <S.Field
      w={w}
      disabled={disabled}
      color={color}
      error={error}
      checkboxBgColor={checkboxBgColor}
      checkedBgColor={checkedBgColor}
      avoidClick={avoidClick}
      {...props}
      {...m}
      plain={plain}
    >
      {noCheckbox ? (
        <S.Wrapper>
          <Icon mr={3} icon="tick" width="16px" height="16px" color="black" />
          {typeof label === 'string' ? (
            <T.P size={font ? font : 'med'} color={color} m="0" ml="1">
              {label}
            </T.P>
          ) : (
            label
          )}
        </S.Wrapper>
      ) : (
        <AntdCheckbox
          onChange={(e) => handleChange(e.target.checked)}
          checked={checked}
          disabled={disabled}
          defaultChecked={defaultChecked}
          aria-disabled={disabled ? 'true' : 'false'}
          value={value}
        >
          {typeof label === 'string' ? (
            <S.LabelWrapper>
              <T.P size={font ? font : 'med'} color={color} m="0" ml="1">
                {label}
              </T.P>
            </S.LabelWrapper>
          ) : (
            label
          )}
        </AntdCheckbox>
      )}
      {component}
      {error && !hideErrorMsg && (
        <T.P color="error" m="0" mt="1">
          {error}
        </T.P>
      )}
    </S.Field>
  );
};

export default Checkbox;
