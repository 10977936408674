import styled from '@emotion/styled';

export const QuestionContainer = styled.div`
  width: 100%;
  background-color: ${({ theme, bgColor, overlayColor }) => {
    if (overlayColor && overlayColor !== 'default') {
      return theme.colors[overlayColor];
    }
    return bgColor
      ? theme.colors[bgColor] || bgColor
      : theme.colors.neutralSurface;
  }};
`;
export const QuestionWrapper = styled.div`
  width: 100%;
  padding: 0 2vw;
  margin-top: ${({ isCheck }) => (isCheck ? '16px' : '-32px')};
`;
export const QuestionWrapperFull = styled.div`
  width: 100%;
`;
export const Rounded = styled.div`
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => theme.spacings[5]};
  background-color: ${({ theme }) => theme.colors.neutralSurface};
  max-width: 420px;
  margin: 0 auto;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.media.mobile} {
    justify-content: flex-start;
  }
`;
