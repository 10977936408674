import React, { createRef } from 'react';

import * as S from './style';

import Option from './Option';

import AnimateBox from './AnimateBox';

const Options = ({ tempArr, setTempArr, cmsPreview }) => {
  const handleClickUp = (id, index) => {
    const item = tempArr.find((e) => e.id === id);
    if (index !== 0) {
      let prevItem = tempArr.find((e, idx) => idx === index - 1);
      const rightPart = tempArr.slice(0, index - 1);
      const leftPart = tempArr.slice(index + 1);
      const newArr = [...rightPart, item, prevItem, ...leftPart];
      setTempArr(newArr);
    } else {
      // in case change the first up button from disable to enable
      const firstItem = tempArr[0];
      const lastItem = tempArr[tempArr.length - 1];
      const rest = tempArr.slice(1, tempArr.length - 1);
      const newArr = [lastItem, ...rest, firstItem];
      setTempArr(newArr);
    }
  };

  const handleClickDown = (id, index) => {
    const item = tempArr.find((e) => e.id === id);
    if (index !== tempArr.length - 1) {
      let nextItem = tempArr.find((e, idx) => idx === index + 1);
      const rightPart = tempArr.slice(0, index);
      const leftPart = tempArr.slice(index + 2);
      const newArr = [...rightPart, nextItem, item, ...leftPart];
      setTempArr(newArr);
    } else {
      // in case change the last down button from disable to enable
      const firstItem = tempArr[0];
      const lastItem = tempArr[tempArr.length - 1];
      const rest = tempArr.slice(1, tempArr.length - 1);
      const newArr = [lastItem, ...rest, firstItem];
      setTempArr(newArr);
    }
  };

  return (
    <S.OptionsWrapper>
      <AnimateBox>
        {tempArr.length > 0 &&
          tempArr?.map((opt, index) => (
            <Option
              key={opt.id}
              id={opt.id}
              ref={createRef()}
              handleDownClick={() => handleClickDown(opt.id, index)}
              handleUpClick={() => handleClickUp(opt.id, index)}
              opt={opt}
              index={index}
              tempArr={tempArr}
              cmsPreview={cmsPreview}
            />
          ))}
      </AnimateBox>
    </S.OptionsWrapper>
  );
};

export default Options;
