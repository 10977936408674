import theme from '../../../theme';

const wordCollector = ({ width, height, color, ...props }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill={theme.colors.primaryMain} />
    <circle
      cx="32"
      cy="32"
      r="30"
      stroke={theme.colors.neutralMain}
      strokeOpacity="0.1"
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.6 30.72C41.6 27.2589 39.4867 24.2915 36.48 23.0376C36.4787 20.5645 34.4734 18.56 32 18.56C29.5265 18.56 27.5213 20.5645 27.52 23.0376C24.5133 24.2915 22.4 27.2589 22.4 30.72V40.96C22.4 43.4342 24.4058 45.44 26.88 45.44H37.12C39.5942 45.44 41.6 43.4342 41.6 40.96V30.72ZM32 19.84C33.6236 19.84 34.9647 21.0492 35.1721 22.6161C34.5643 22.4747 33.9308 22.4 33.28 22.4H30.72C30.0691 22.4 29.4356 22.4747 28.8278 22.6161C29.0352 21.0492 30.3763 19.84 32 19.84ZM28.16 33.92C27.0996 33.92 26.24 34.7796 26.24 35.84V39.68C26.24 40.7404 27.0996 41.6 28.16 41.6H35.84C36.9004 41.6 37.76 40.7404 37.76 39.68V35.84C37.76 34.7796 36.9004 33.92 35.84 33.92H28.16ZM35.84 28.8H30.08V30.087C30.08 30.4405 29.7934 30.727 29.44 30.727C29.0865 30.727 28.8 30.4405 28.8 30.087V28.8H28.16C27.8065 28.8 27.52 28.5135 27.52 28.16C27.52 27.8065 27.8065 27.52 28.16 27.52H35.84C36.1934 27.52 36.48 27.8065 36.48 28.16C36.48 28.5135 36.1934 28.8 35.84 28.8Z"
      fill={theme.colors.neutralSurface}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4478 42.88H20.48C19.4196 42.88 18.56 42.0204 18.56 40.96V35.84C18.56 34.7796 19.4196 33.92 20.48 33.92H21.12V40.96C21.12 41.6332 21.2355 42.2794 21.4478 42.88ZM42.5521 42.88C42.7644 42.2795 42.8799 41.6332 42.8799 40.96V33.92H43.5199C44.5803 33.92 45.4399 34.7796 45.4399 35.84V40.96C45.4399 42.0204 44.5803 42.88 43.5199 42.88H42.5521ZM28.1599 35.2H35.8399C36.1934 35.2 36.4799 35.4865 36.4799 35.84V39.68C36.4799 40.0335 36.1934 40.32 35.8399 40.32H28.1599C27.8065 40.32 27.5199 40.0335 27.5199 39.68V35.84C27.5199 35.4865 27.8065 35.2 28.1599 35.2Z"
      fill={theme.colors.neutralSurface}
    />
  </svg>
);
export default wordCollector;
